define('whiteboard2-frontend/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    email: _emberData['default'].attr('string'),
    role: _emberData['default'].attr('string'),
    active: _emberData['default'].attr('boolean'),
    prepared: _emberData['default'].attr('number'),
    customer: _emberData['default'].belongsTo('customer'),
    user: _emberData['default'].belongsTo('user'),
    selected: false
  });
});