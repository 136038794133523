define('whiteboard2-frontend/conference/documentsharing/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        fileName: null,
        bookmark: null,
        selectedFile: null,
        pdfTitle: null,
        pdfLoaded: false,

        init: function init() {
            this._super();
            _whiteboard2FrontendApp['default'].docushare = this;
        },

        // handle new websocket messages an push it
        dispatchEvent: function dispatchEvent(kwargs) {
            console.log('docushare.dispatchEvent: ', kwargs);
            if (kwargs.msgCat === 'nf') {
                this.get('model').reload().then(function (newModel) {
                    var attachments = newModel.get('attachments');
                    console.log('attachments: ', attachments);
                });
            } else if (kwargs.msgCat === 'nb') {
                console.log('received.msg: documentsharing.nb');
                // new Bookmark is comming
                //this.get('model').reload().then(function(newModel){
                //    let attachments = newModel.get('attachments');
                //});
            }
        },

        actions: {
            notifyUser: function notifyUser(msg) {
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicPDFRoom, [], {
                    msgCat: msg
                });
            },

            showFile: function showFile(attachment) {
                var attachmentUrl = attachment.get('url');
                var controller = this;
                controller.set('selectedFile', attachment);
                controller.get('attachments').forEach(function (attach) {
                    attach.set('selected', false);
                });
                attachment.set('selected', true);
                var attachmentId = attachment.get('id');
                // check contenttype of file
                // in later versions we should set the
                // contenttype in sessionFile object
                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                $.ajax({
                    type: "GET",
                    url: _whiteboard2FrontendApp['default'].API_HOST + attachmentUrl + "?jwt=Bearer " + lsat,
                    success: function success(response, status, xhr) {
                        var ct = xhr.getResponseHeader("content-type") || "";
                        // check contenttype of response
                        // if type = application/pdf
                        if (ct.indexOf('application/pdf') > -1) {
                            controller.set('isPDF', true);
                            controller.transitionToRoute('conference.documentsharing.pdf', attachmentId);
                        }
                        if (ct.indexOf('image/') > -1) {
                            controller.set('isPDF', false);
                            controller.transitionToRoute('conference.documentsharing.images', attachmentId);
                        }
                    }
                });
            },

            selectFile: function selectFile(fileObj) {
                this.set('selectedFile', fileObj);
            },

            uploadPdfModal: function uploadPdfModal() {
                $('#modalUploadPdf').modal('show');
            },

            uploadPdfAction: function uploadPdfAction() {
                document.getElementById('pdfUploadButton').click();
            },

            uploadFile: function uploadFile() {
                var that = this;
                $('#file-upload-container').removeClass('hidden');
                var uploadContent;
                var fileInput = document.getElementById('input-file');
                var files = fileInput.files;
                if (files != null) {
                    uploadContent = files[0];
                }
                var data = new FormData();
                data.append('file', uploadContent);
                var fileName = this.get('fileName'); // chosenFilename for model create

                if (uploadContent) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        xhr: function xhr() {
                            var xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener("progress", function (evt) {
                                if (evt.lengthComputable) {
                                    var percentComplete = evt.loaded / evt.total * 100;
                                    $('#file_upload_progress').css('width', percentComplete + '%').attr('aria-valuenow', percentComplete);
                                }
                            }, false);
                            return xhr;
                        },
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: data,
                        contentType: false,
                        processData: false,
                        headers: { 'Authorization': 'Bearer ' + lsat },
                        success: function success(sessionFile) {
                            $('#file-upload-container').addClass('hidden');
                            var thisRoom = that.get('store').peekRecord('room', _whiteboard2FrontendApp['default'].chosenRoomId);
                            var newFileUploaded = that.get('store').createRecord('attachment', {
                                url: sessionFile.path,
                                name: fileName,
                                user: _whiteboard2FrontendApp['default'].myId,
                                date: new Date(),
                                room: thisRoom
                            });
                            thisRoom.get('attachments').pushObject(newFileUploaded);
                            newFileUploaded.save().then(function () {
                                that.send('notifyUser', 'nf');
                            });
                            $('#modalUploadPdf').modal('hide');
                        },
                        error: function error(_error) {
                            that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + _error);
                        }
                    });
                }
                this.set('fileName', '');
                //fileInput.files[0] = null;
            },

            deleteFile: function deleteFile(file) {
                this.set('file', file);
                $('#deleteFileModal').modal('show');
            },

            deleteFileReally: function deleteFileReally() {
                var file = this.get('file');
                file.destroyRecord();
                this.send('notifyUser', 'nf');
            }
        }
    });
});