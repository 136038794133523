define('whiteboard2-frontend/conference/checklist/template/edit/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            page: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicRTCRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        },

        model: function model(params) {
            return this.get('store').query('checklist-template', params);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('model', model);
            controller.set('meta', model.meta);
            var actionMode = controller.get('actionMode');
            if (actionMode === 2) {
                model.forEach(function (thisTemplate) {
                    thisTemplate.set('selected', false);
                });
                controller.set('selectedEntry', null);
                var savedNewTemplate = controller.get('selectedTemplate');
                savedNewTemplate.set('selected', true);
            } else {
                model.forEach(function (thisTemplate) {
                    thisTemplate.set('selected', false);
                });
                if (model) {
                    var selectedTemplate = model.get('firstObject');
                    if (selectedTemplate) {
                        selectedTemplate.set('selected', true);
                        controller.set('selectedTemplate', selectedTemplate);
                        var firstTemplateEntries = selectedTemplate.get('entries');
                        firstTemplateEntries.forEach(function (thisEntry) {
                            thisEntry.set('selected', false);
                        });
                        controller.set('selectedEntry', null);
                        if (firstTemplateEntries) {
                            var firstTemplateFirstEntry = firstTemplateEntries.get('firstObject');
                            if (firstTemplateFirstEntry) {
                                firstTemplateFirstEntry.set('selected', true);
                                controller.set('selectedEntry', firstTemplateFirstEntry);
                            }
                        }
                    } else {
                        controller.set('selectedEntry', null);
                    }
                }
            }
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});