define('whiteboard2-frontend/conference/helpscreen/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        /*
        softwareVersion:null,
        fingerprint:null,
        userAgent:null,
        browser:null,
        browserVersion:null,
        engine:null,
        OS:null,
        osVersion:null,
        CPU:null,
        isMobile:null,
        screenPrint:null,
        language:null,
         actions:{
            runAfterRender:function(){
                let softwareVersion = client.getSoftwareVersion();
                this.set('softwareVersion',softwareVersion);
                let fingerprint = client.getFingerprint();
                this.set('fingerprint',fingerprint);
                let userAgent = client.getUserAgent();
                this.set('userAgent',userAgent);
                let browser = client.getBrowser();
                this.set('browser',browser);
                let browserVersion = client.getBrowserVersion();
                this.set('browserVersion',browserVersion);
                let engine = client.getEngine();
                this.set('engine',engine);
                let OS = client.getOS();
                this.set('OS',OS);
                let osVersion = client.getOSVersion();
                this.set('osVersion',osVersion);
                let CPU = client.getCPU();
                this.set('CPU',CPU);
                let isMobile = client.isMobile();
                this.set('isMobile',isMobile);
                let screenPrint = client.getScreenPrint();
                this.set('screenPrint',screenPrint);
                let language = client.getLanguage();
                this.set('language',language);
            },
             showMyInfos:function(){
                $('#modalMyInfos').modal('show');
            }
        }
        */
    });
});