define('whiteboard2-frontend/conference/whiteboard/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {

    // enable Tooltips
    //
    $('[data-toggle="tooltip"]').tooltip({
        'placement': 'right'
    });

    exports['default'] = _ember['default'].Controller.extend({
        i18n: _ember['default'].inject.service(),
        conference: _ember['default'].inject.controller('conference'),
        queryParams: 'size',
        size: 30,

        init: function init() {
            this._super.apply(this, arguments);
            _whiteboard2FrontendApp['default'].whiteboard = this;
        },

        videoDevices: [],
        videoDeviceChosen: [],
        mediaOptions: {
            audio: false,
            video: false
        },
        localstream: null,
        activeTrack: null,
        imageCapture: null,
        selectedPic: null,
        torchState: false,
        hasTorch: false,

        objStage: null,
        imageOffScreenRenderLayer: null,
        canvasPos: null,
        selectedObj: null,
        selectedObjOld: null,
        labelText: null,
        labelColor: null,
        labelDialing: null,
        labelColorchoice: null,
        labelDirection: null,
        imageInfo: null,
        insertPos: null,
        freehandPaint: false,
        isImageLoading: false,
        canvasLocked: false,
        topZIndex: 0,
        scalingFactor: 1,

        arrowUp: true,
        arrowDown: false,
        arrowLeft: false,
        arrowRight: false,

        freehandColor: '#ffffff',
        freehandColorChosen: 1,

        dispatchEvent: function dispatchEvent(event) {
            switch (event.msgCat) {
                case 'newRect':
                    this.insertRectByWAMP(event);
                    break;
                case 'newLabel':
                    this.insertTextByWAMP(event);
                    break;
                case 'newImage':
                    this.insertImageByWAMP(event);
                    break;
                case 'objMoved':
                    this.moveObjByWAMP(event);
                    break;
                case 'rectAdjust':
                    this.rectAdjustByWAMP(event.id, event.x, event.y, event.width, event.height);
                    break;
                case 'deleteObj':
                    this.deleteObjByWAMP(event.id);
                    break;
                case 'deleteAll':
                    this.deleteAllByWAMP();
                    break;
                case 'bringObjToFront':
                    this.bringObjToFrontByWAMP(event);
                    break;
                case 'bringObjToBack':
                    this.bringObjToBackByWAMP(event);
                    break;
                case 'lockCanvas':
                    this.lockCanvasByWAMP();
                    break;
                case 'unlockCanvas':
                    this.unlockCanvasByWAMP();
                    break;
                case 'changeZ':
                    this.changeZIndexByWAMP(event);
                    break;
                case 'refreshModel':
                    this.refreshModelByWAMP();
                    break;
            }
        },

        getNewObjId: function getNewObjId() {
            return new Date().getTime();
        },

        localFileUpload: function localFileUpload() {
            var that = this;
            var uploadContent = "test";
            var input = document.getElementById('cameraCaptureInput');
            input.click();
            input.onchange = function () {
                var files = input.files;
                if (files != null) {
                    uploadContent = files[0];
                }
                var fd = new FormData();
                fd.append('file', uploadContent);
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;

                if (uploadContent) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var newPicture = that.store.createRecord('picture', {
                                    url: data.path,
                                    name: 'whup',
                                    visibleForOthers: false,
                                    room: roomId
                                });
                                newPicture.save().then(function () {
                                    that.send('refreshModel');
                                    that.getPicture(data.path, false);
                                });
                            } else {
                                // Handle errors here
                            }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                }
            };
            document.getElementById('makePictureButton').blur();
        },

        dataURItoBlob: function dataURItoBlob(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var bb = new Blob([ab], {
                "type": mimeString
            });
            return bb;
        },

        switchLight: function switchLight(mode) {
            var torchState = false;
            if (mode === "1") {
                torchState = true;
            } else if (mode === "2") {
                torchState = !this.get('torchState');
            }
            var activeTrack = this.get('activeTrack');
            if (activeTrack) {
                activeTrack.applyConstraints({
                    advanced: [{ torch: torchState }]
                });
                this.set('torchState', torchState);
            }
        },

        actions: {
            labelChoice: function labelChoice(choice) {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                if (choice < 5) {
                    document.getElementById("labelNumbers").style.display = "none";
                    document.getElementById("labelColors").style.display = "block";
                } else if (choice < 9) {
                    document.getElementById("labelColors").style.display = "none";
                    document.getElementById("labelDirection").style.display = "block";
                } else {
                    document.getElementById("labelDirection").style.display = "none";
                }
                switch (choice) {
                    case 1:
                        this.set('labelDialing', '1');
                        break;
                    case 2:
                        this.set('labelDialing', '2');
                        break;
                    case 3:
                        this.set('labelDialing', '3');
                        break;
                    case 4:
                        this.set('labelDialing', '4');
                        break;
                    case 5:
                        this.set('labelColorchoice', '#0000ff');
                        break;
                    case 6:
                        this.set('labelColorchoice', '#00ff00');
                        break;
                    case 7:
                        this.set('labelColorchoice', '#ffff00');
                        break;
                    case 8:
                        this.set('labelColorchoice', '#ff0000');
                        break;
                    case 9:
                        this.set('labelDirection', 'up');
                        break;
                    case 10:
                        this.set('labelDirection', 'down');
                        break;
                    case 11:
                        this.set('labelDirection', 'left');
                        break;
                    case 12:
                        this.set('labelDirection', 'right');
                        break;
                }
                var color = this.get('labelColorchoice');
                var number = this.get('labelDialing');
                var direction = this.get('labelDirection');
                if (number !== null && color !== null && direction !== null) {
                    var thisZIndex = this.get('topZIndex') + 1;
                    this.set('topZIndex', thisZIndex);
                    var id = this.getNewObjId();
                    var paramObj = {
                        msgCat: 'newLabel',
                        id: id,
                        labelText: number,
                        labelColor: color,
                        labelDirection: direction,
                        thisZIndex: thisZIndex
                    };
                    this.cLabelInsert(paramObj);
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], paramObj);
                    this.set('labelColorchoice', null);
                    this.set('labelDialing', null);
                    this.set('labelDirection', null);
                }
            },

            insertPreMarker: function insertPreMarker(number) {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                var thisZIndex = this.get('topZIndex') + 1;
                this.set('topZIndex', thisZIndex);
                var color = '#ff0000';
                var direction = 'right';
                var id = this.getNewObjId();
                var paramObj = {
                    msgCat: 'newLabel',
                    id: id,
                    labelText: number,
                    labelColor: color,
                    labelDirection: direction,
                    thisZIndex: thisZIndex
                };
                this.cLabelInsert(paramObj);
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], paramObj);
                document.getElementById('preMarker1Button').blur();
                document.getElementById('preMarker2Button').blur();
                document.getElementById('preMarker3Button').blur();
                document.getElementById('preMarker4Button').blur();
                this.set('labelDirection', null);
            },

            chooseArrowDirection: function chooseArrowDirection(direction) {
                this.set('arrowUp', false);
                this.set('arrowDown', false);
                this.set('arrowLeft', false);
                this.set('arrowRight', false);
                switch (direction) {
                    case 1:
                        this.set('arrowUp', true);
                        this.set('labelDirection', 'up');
                        break;
                    case 2:
                        this.set('arrowDown', true);
                        this.set('labelDirection', 'down');
                        break;
                    case 3:
                        this.set('arrowLeft', true);
                        this.set('labelDirection', 'left');
                        break;
                    case 4:
                        this.set('arrowRight', true);
                        this.set('labelDirection', 'right');
                        break;
                }
            },

            fileUploadAction: function fileUploadAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                $('#modalChooseInput').modal('show');
            },

            chosenInputDevice: function chosenInputDevice(thisDevice) {
                this.set('videoDeviceChosen.selected', false);
                var mediaOptions = this.get('mediaOptions');
                if (thisDevice.deviceId === 0) {
                    mediaOptions.video = false;
                } else if (thisDevice.deviceId === 'docupics') {
                    mediaOptions.video = false;
                } else {
                    mediaOptions.video = {
                        deviceId: thisDevice.deviceId
                    };
                }
                this.set('videoDeviceChosen', thisDevice);
                this.set('videoDeviceChosen.selected', true);
            },

            selectThisSource: function selectThisSource() {
                var that = this;
                var videoDeviceChosen = this.get('videoDeviceChosen');
                if (videoDeviceChosen.kind) {
                    if (videoDeviceChosen.deviceId === 0) {
                        this.localFileUpload();
                    } else if (videoDeviceChosen.deviceId === 'docupics') {
                        this.set('selectedPic', null);
                        $('#modalChoosePicture').modal('show');
                    } else {
                        (function () {
                            $('#modalTakePicture').modal('show');
                            var takePictureVideo = document.getElementById('takePictureVideo');
                            var deviceId = videoDeviceChosen.deviceId;
                            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                                navigator.mediaDevices.getUserMedia({
                                    video: {
                                        deviceId: deviceId
                                    }
                                }).then(function (stream) {
                                    takePictureVideo.srcObject = stream;
                                    var track = stream.getVideoTracks()[0];
                                    var imageCapture = new ImageCapture(track);
                                    if (videoDeviceChosen.label === 'camera2 0, facing back') {
                                        that.set('hasTorch', true);
                                    } else {
                                        that.set('hasTorch', false);
                                    }
                                    that.set('activeTrack', track);
                                    that.set('imageCapture', imageCapture);
                                    that.set('localstream', stream);
                                });
                            }
                        })();
                    }
                }
            },

            toggleLight: function toggleLight() {
                this.switchLight("2");
            },

            takePic: function takePic() {
                var _this = this;

                var that = this;
                var imageCapture = this.get('imageCapture');
                imageCapture.takePhoto().then(function (highResBlob) {
                    var fd = new FormData();
                    fd.append('file', highResBlob);
                    var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var newPicture = that.store.createRecord('picture', {
                                    url: data.path,
                                    name: 'whca',
                                    visibleForOthers: false,
                                    room: roomId
                                });
                                newPicture.save().then(function () {
                                    that.send('refreshModel');
                                    that.getPicture(data.path, false);
                                });
                            } else {
                                // Handle errors here
                            }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                    var stream = _this.get('localstream');
                    var takePictureVideo = document.getElementById('takePictureVideo');
                    takePictureVideo.pause();
                    takePictureVideo.src = "";
                    stream.getTracks()[0].stop();
                })['catch'](function (err) {
                    console.error('takePhoto() failed: ', err);
                });
            },

            abortTakePic: function abortTakePic() {
                var stream = this.get('localstream');
                var takePictureVideo = document.getElementById('takePictureVideo');
                takePictureVideo.pause();
                takePictureVideo.src = "";
                stream.getTracks()[0].stop();
                if (this.get('hasTorch')) {
                    this.switchLight("0");
                }
            },

            selectThisPicture: function selectThisPicture(thisPic) {
                var model = this.get('model');
                model.forEach(function (thisPicture) {
                    thisPicture.set('selected', false);
                });
                thisPic.set('selected', true);
                this.set('selectedPic', thisPic);
            },

            chooseThisPic: function chooseThisPic() {
                var thisPic = this.get('selectedPic');
                if (thisPic) {
                    var thisPicUrl = thisPic.get('url');
                    this.getPicture(thisPicUrl, false);
                    thisPic.set('selected', false);
                    this.set('selectedPic', null);
                } else {
                    return;
                }
            },

            putThisPicIn: function putThisPicIn(thisPicture) {
                var thisPicUrl = thisPicture.get('url');
                var pictureName = thisPicture.get('name');
                if (pictureName === 'whss') {
                    this.getPicture(thisPicUrl, 'ws');
                } else {
                    this.getPicture(thisPicUrl, false);
                }
            },

            savePictureAction: function savePictureAction() {
                var that = this;
                var stage = this.get('objStage');
                var dataUrl = stage.toDataURL("image/png");
                var blob = this.dataURItoBlob(dataUrl);
                var fd = new FormData();
                fd.append('file', blob);
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;

                if (blob) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var newPicture = that.store.createRecord('picture', {
                                    url: data.path,
                                    name: 'whss',
                                    visibleForOthers: true,
                                    room: roomId
                                });
                                newPicture.save().then(function () {
                                    that.send('refreshModel');
                                });
                                that.get('conference').send('successMsg', that.get('i18n').t('whiteboardScreenshotAdded'));
                            } else {
                                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                            }
                        },
                        error: function error() {
                            that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                        }
                    });
                }
            },

            insertRectAction: function insertRectAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                var id = this.getNewObjId();
                var topZIndex = this.get('topZIndex') + 1;
                this.set('topZIndex', topZIndex);
                var rectParams = {
                    msgCat: 'newRect',
                    id: id,
                    thisZIndex: topZIndex
                };
                this.cRectInsert(rectParams);
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], rectParams);
                document.getElementById('insertRectButton').blur();
            },

            freehandChooseAction: function freehandChooseAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                var that = this;
                var freehandPaint = this.get('freehandPaint');
                if (freehandPaint) {
                    this.set('freehandPaint', false);
                    this.cStopFreehand();
                    document.getElementById('freehandChoosing').style.display = "none";
                } else {
                    document.getElementById('freehandChoosing').style.display = "block";
                    that.set('freehandPaint', true);
                }
                document.getElementById('freehandButton').blur();
            },

            freehandColor: function freehandColor(colorKey) {
                switch (colorKey) {
                    case 1:
                        this.set('freehandColor', '#ffffff');
                        break;
                    case 2:
                        this.set('freehandColor', '#ffff00');
                        break;
                    case 3:
                        this.set('freehandColor', '#308DFF');
                        break;
                    case 4:
                        this.set('freehandColor', '#00ff00');
                        break;
                    case 5:
                        this.set('freehandColor', '#ff0000');
                        break;
                }
                this.set('freehandColorChosen', colorKey);
            },

            drawFreehand: function drawFreehand() {
                var brushWidth = document.getElementById('freehandBrush').value;
                var freehandColor = this.get('freehandColor');
                this.cPaintFreehand(freehandColor, brushWidth);
                this.set('freehandColor', '#ffffff');
                this.set('freehandColorChosen', 1);
                document.getElementById('freehandChoosing').style.display = "none";
            },

            addLabelAction: function addLabelAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                this.set('labelText', '');
                $('#labelInputDialog').modal('show');
            },

            insertLabelAction: function insertLabelAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                $('#labelInputDialog').modal('hide');
                var id = this.getNewObjId();
                var text = this.get('labelText');
                var color = document.getElementById("colorChoise").value;

                var direction = this.get('labelDirection');
                if (direction === undefined || direction === null) {
                    direction = 'up';
                }
                var thisZIndex = this.get('topZIndex') + 1;
                this.set('topZIndex', thisZIndex);
                var labelParams = {
                    msgCat: 'newLabel',
                    id: id,
                    labelText: text,
                    labelColor: color,
                    labelDirection: direction,
                    thisZIndex: thisZIndex
                };
                this.cLabelInsert(labelParams);
                this.set('labelText', "");
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], labelParams);
                this.set('labelDirection', null);
            },

            startLabelAction: function startLabelAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                document.getElementById("labelNumbers").style.display = "block";
            },

            bringToFrontAction: function bringToFrontAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                var selectedObj = this.get('selectedObj');
                if (selectedObj != null) {
                    if (selectedObj.className === "Image") {
                        selectedObj = selectedObj.parent;
                    }
                    var id = selectedObj.attrs.id;
                    var thisZIndex = this.get('topZIndex') + 1;
                    this.set('topZIndex', thisZIndex);
                    selectedObj.setZIndex(thisZIndex);
                    this.get('objStage').draw();
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                        msgCat: 'bringObjToFront',
                        id: id,
                        thisZIndex: thisZIndex
                    });
                }
                document.getElementById('bringToFrontButton').blur();
            },

            bringToBackAction: function bringToBackAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                var selectedObj = this.get('selectedObj');
                if (selectedObj != null) {
                    if (selectedObj.className === "Image") {
                        selectedObj = selectedObj.parent;
                    }
                    var id = selectedObj.attrs.id;
                    selectedObj.moveToBottom();
                    var newZIndex = selectedObj.getZIndex();
                    this.get('objStage').draw();
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                        msgCat: 'bringObjToBack',
                        id: id,
                        thisZIndex: newZIndex
                    });
                }
                document.getElementById('bringToBackButton').blur();
            },

            cloneImageRegionAction: function cloneImageRegionAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                this.cloneImageRegion();
                document.getElementById('cloneImageRegionButton').blur();
            },

            deleteAction: function deleteAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                var selectedObj = this.get('selectedObj');
                if (selectedObj != null) {
                    if (selectedObj.className === "Image") {
                        selectedObj = selectedObj.parent;
                    }
                    var id = selectedObj.attrs.id;
                    selectedObj.destroy();
                    this.set('selectedObj', null);
                    this.get('objStage').draw();
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                        msgCat: 'deleteObj',
                        id: id
                    });
                }
                document.getElementById('deleteButton').blur();
            },

            deleteAllModal: function deleteAllModal() {
                $('#modalClearCanvas').modal('show');
            },

            deleteAllAction: function deleteAllAction() {
                if (this.get('canvasLocked') === true) {
                    return;
                }
                this.cDeleteAll();
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                    msgCat: 'deleteAll'
                });
                document.getElementById('deleteAllButton').blur();
            },

            runAfterRender: function runAfterRender() {
                var that = this;
                _ember['default'].run.schedule("afterRender", this, function () {
                    this.send("initWalk");
                });
                var videoDevices = this.get('videoDevices');
                videoDevices.clear();
                var mediaOptions = this.get('mediaOptions');
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    var noDevice = {
                        kind: 'videoinput',
                        deviceId: 0,
                        label: that.get('i18n').t('filesystem'),
                        selected: true
                    };
                    that.set('videoDeviceChosen', noDevice);
                    mediaOptions.video = false;
                    videoDevices.addObject(noDevice);
                    var docuPics = {
                        kind: 'videoinput',
                        deviceId: 'docupics',
                        label: that.get('i18n').t('docuPics'),
                        selected: false
                    };
                    videoDevices.addObject(docuPics);
                    for (var i = 0; i !== devices.length; ++i) {
                        var device = devices[i];
                        if (device.kind === 'videoinput') {
                            device.selected = false;
                            videoDevices.addObject(device);
                        }
                    }
                });
            },

            initWalk: function initWalk() {
                var windowInnerHeight = window.innerHeight;
                var windowInnerWidth = window.innerWidth;
                var konvaHeight = 495;
                var konvaWidth = 880;
                if (windowInnerHeight && windowInnerWidth) {
                    var possibleWidth = windowInnerWidth - 190;
                    if (possibleWidth < konvaWidth) {
                        possibleWidth = konvaWidth;
                    }
                    var possibleHeight = windowInnerHeight - 76;
                    if (possibleHeight < konvaHeight) {
                        possibleHeight = konvaHeight;
                    }
                    var nativeRatio = possibleWidth / possibleHeight;
                    var thisRatio = 16 / 9;
                    if (nativeRatio < thisRatio) {
                        konvaWidth = possibleWidth;
                        konvaHeight = possibleWidth / thisRatio;
                    } else {
                        konvaHeight = possibleHeight;
                        konvaWidth = possibleHeight * thisRatio;
                    }
                }
                var scalingFactor = konvaWidth / 880;
                this.set('scalingFactor', scalingFactor);
                document.getElementById("imageThumbnails").style.left = konvaWidth + 25 + "px";
                var that = this;
                var stage = new Konva.Stage({
                    container: 'whiteboard',
                    width: konvaWidth,
                    height: konvaHeight
                });
                var layer;
                if (!this.get('objStage')) {
                    layer = new Konva.Layer();
                    layer.on('mousedown touchstart', function (evt) {
                        // get the shape that was clicked on
                        var shape = evt.target;

                        var selObj = that.get('selectedObj');
                        selObj = that.shapeHelper(selObj, '#8A2BE2');
                        shape = that.shapeHelper(shape, 'red');

                        if (shape.getClassName() === 'Rect' && shape.attrs.resizing) {
                            shape.setDraggable(false);
                            shape = shape.getParent();
                            shape.setDraggable(true);
                            shape.moveToTop();
                        }
                        that.set('selectedObj', shape);
                        that.get('objStage').draw();
                    });
                } else {
                    layer = this.get('objStage').getLayers()[0];
                }

                var imageOffScreenRenderLayer = new Konva.Layer(); // fuer temparaere Operationen

                this.set('objStage', stage);
                this.set('imageOffScreenRenderLayer', imageOffScreenRenderLayer);

                _ember['default'].run.later(this, function () {
                    var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;
                    var canvasPos = $('canvas').offset();
                    this.set('canvasPos', canvasPos);
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'GET',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/rooms/" + roomId + "/whiteboard",
                        data: '',
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var i;

                                (function () {
                                    var allData = data.wampMessages;
                                    var sortData = allData.sortBy('thisZIndex');
                                    i = 0;

                                    var onlyLabels = [];
                                    var allIds = [];
                                    sortData.forEach(function (thisData) {
                                        thisData.thisZIndex = i;
                                        i++;
                                        if (thisData.msgCat === "newLabel") {
                                            onlyLabels.pushObject(thisData);
                                        } else {
                                            that.dispatchEvent(thisData);
                                            allIds.pushObject(thisData.id);
                                        }
                                    });

                                    var onlyParentLabels = [];
                                    onlyLabels.forEach(function (thatData) {
                                        var thatDataParentId = thatData.parentId;
                                        if (!thatDataParentId || thatDataParentId === 'Layer') {
                                            that.dispatchEvent(thatData);
                                        } else {
                                            var isItIn = allIds.includes(thatDataParentId);
                                            if (isItIn) {
                                                onlyParentLabels.pushObject(thatData);
                                            } else {
                                                thatData.parentId = 'Layer';
                                                that.dispatchEvent(thatData);
                                            }
                                        }
                                    });

                                    var onlyParentLabelsLength = onlyParentLabels.length;
                                    var createLabels = setInterval(function () {
                                        onlyParentLabels.forEach(function (thatData) {
                                            var thatDataParentId = thatData.parentId;
                                            layer.getChildren().forEach(function (shape) {
                                                if (shape.attrs.id === thatDataParentId) {
                                                    that.dispatchEvent(thatData);
                                                    onlyParentLabels.removeObject(thatData);
                                                }
                                            });
                                        });
                                        onlyParentLabelsLength = onlyParentLabels.length;
                                        if (onlyParentLabelsLength < 1) {
                                            clearInterval(createLabels);
                                        }
                                    }, 333);
                                })();
                            } else {
                                // Handle errors here
                            }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                }, this, 500);
                stage.add(layer);
                this.set('objStage', stage);
            }
        },

        // --------------------- websocket based operation synchronisation --------
        /*
            Events which should be synchronized between clients
            "newRect"
         "newImage"
         "objMoved"
         "deleteObj"
         */

        insertRectByWAMP: function insertRectByWAMP(event) {
            this.cRectInsert(event);
        },

        insertTextByWAMP: function insertTextByWAMP(event) {
            this.cLabelInsert(event);
        },

        insertImageByWAMP: function insertImageByWAMP(event) {
            var freehandDraw = false;
            var whiteboardScreenshot = false;
            if (event.options === 'fh') {
                freehandDraw = true;
            } else if (event.options === 'ws') {
                whiteboardScreenshot = true;
            }
            var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
            var that = this;
            var image = new Image();
            image.src = _whiteboard2FrontendApp['default'].API_HOST + event.imageInfo + "?jwt=Bearer " + lsat;
            image.onerror = function () {
                // If fail to load the image
            };
            var scalingFactor = this.get('scalingFactor');
            var konvaGroup = new Konva.Group({
                id: event.id,
                x: event.insertPos.x * scalingFactor,
                y: event.insertPos.y * scalingFactor,
                draggable: true
            });

            image.onload = function () {
                var imgScale = 1;
                if (!freehandDraw) {
                    var maxDrawSize = 300;
                    if (whiteboardScreenshot) {
                        maxDrawSize = 880;
                    }
                    var imageMaxSize = image.width;
                    if (image.width < image.height) {
                        imageMaxSize = image.height;
                    }
                    imgScale = maxDrawSize / imageMaxSize;
                }
                var imgId = "img_" + event.id;
                var konvaImage = new Konva.Image({
                    id: imgId,
                    image: image,
                    x: 0,
                    y: 0,
                    width: image.width,
                    height: image.height,
                    strokeEnabled: false
                });
                var thisZIndex = event.thisZIndex;
                var topZIndex = that.get('topZIndex');
                if (thisZIndex > topZIndex) {
                    that.set('topZIndex', thisZIndex);
                }

                konvaImage.setScale({
                    x: imgScale * scalingFactor,
                    y: imgScale * scalingFactor
                });

                konvaGroup.on('mouseover', function () {
                    document.body.style.cursor = 'pointer';
                });
                konvaGroup.on('mouseout', function () {
                    document.body.style.cursor = 'default';
                });
                konvaGroup.on('dragmove', function (event) {
                    var shape = event.target;
                    if (shape !== undefined) {
                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                            msgCat: 'objMoved',
                            id: konvaGroup.attrs.id,
                            x: konvaGroup.attrs.x / scalingFactor,
                            y: konvaGroup.attrs.y / scalingFactor
                        });
                    }
                });

                if (event.freehandDraw) {
                    konvaImage.cache();
                    konvaImage.drawHitFromCache();
                }

                // add the shape to the layer
                var stage = that.get('objStage');
                var layer = stage.getLayers()[0];
                konvaGroup.add(konvaImage);
                layer.add(konvaGroup);
                konvaImage.setZIndex(thisZIndex);
                stage.draw();
                //$('#imageLoadDialog').modal('hide');
                that.set('isImageLoading', false);
            };
        },

        moveObjByWAMP: function moveObjByWAMP(event) {
            var scalingFactor = this.get('scalingFactor');
            var layer = this.get('objStage').getLayers()[0];
            var selShape = null;
            var parentInSpe = null;
            if (event.parentId === 'Layer') {
                parentInSpe = layer;
            }
            layer.getChildren().forEach(function (shape) {
                if (shape.attrs.id === event.id) {
                    selShape = shape;
                }
                if (event.parentId && event.parentId !== 'Layer') {
                    if (shape.attrs.id === event.parentId) {
                        parentInSpe = shape;
                    }
                }
            });
            if (parentInSpe) {
                parentInSpe.add(selShape);
            }
            selShape.setX(event.x * scalingFactor);
            selShape.setY(event.y * scalingFactor);
            layer.draw();
        },

        rectAdjustByWAMP: function rectAdjustByWAMP(id, x, y, width, height) {
            var scalingFactor = this.get('scalingFactor');
            var layer = this.get('objStage').getLayers()[0];
            var selShape = null;
            layer.getChildren().forEach(function (shape) {
                if (shape.attrs.id === id) {
                    selShape = shape;
                }
            });
            selShape.setX(x * scalingFactor);
            selShape.setY(y * scalingFactor);
            selShape.setWidth(width * scalingFactor);
            selShape.setHeight(height * scalingFactor);
            layer.draw();
        },

        deleteObjByWAMP: function deleteObjByWAMP(id) {
            var that = this;
            var layer = this.get('objStage').getLayers()[0];
            layer.getChildren().forEach(function (shape) {
                if (shape.getId() === id) {
                    shape.destroy();
                    that.set('selectedObj', undefined);
                    layer.draw();
                }
            });
        },

        changeZIndexByWAMP: function changeZIndexByWAMP(thisEvent) {
            var id = thisEvent.id;
            var zIndex = thisEvent.thisZIndex;
            var that = this;
            var layer = this.get('objStage').getLayers()[0];
            layer.getChildren().forEach(function (shape) {
                if (shape.getId() === id) {
                    shape.setZIndex(zIndex);
                    that.set('selectedObj', undefined);
                    layer.draw();
                    var topZIndex = that.get('topZIndex');
                    if (zIndex > topZIndex) {
                        that.set('topZIndex', zIndex);
                    }
                }
            });
        },

        refreshModelByWAMP: function refreshModelByWAMP() {
            this.send('refreshModel');
        },

        deleteAllByWAMP: function deleteAllByWAMP() {
            this.cDeleteAll();
        },

        lockCanvasByWAMP: function lockCanvasByWAMP() {
            this.set('canvasLocked', true);
        },

        unlockCanvasByWAMP: function unlockCanvasByWAMP() {
            this.set('canvasLocked', false);
        },

        bringObjToFrontByWAMP: function bringObjToFrontByWAMP(event) {
            var that = this;
            var id = event.id;
            var newZIndex = event.thisZIndex;
            var topZIndex = this.get('topZIndex');
            if (newZIndex > topZIndex) {
                this.set('topZIndex', newZIndex);
            }
            var layer = this.get('objStage').getLayers()[0];
            layer.getChildren().forEach(function (shape) {
                if (shape.getId() === id) {
                    shape.setZIndex(newZIndex);
                    that.set('selectedObj', undefined);
                    layer.draw();
                }
            });
        },

        bringObjToBackByWAMP: function bringObjToBackByWAMP(event) {
            var that = this;
            var id = event.id;
            var layer = this.get('objStage').getLayers()[0];
            layer.getChildren().forEach(function (shape) {
                if (shape.getId() === id) {
                    shape.setZIndex(event.thisZIndex);
                    that.set('selectedObj', undefined);
                    layer.draw();
                }
            });
        },

        //-----------------------------------------------------------------------------

        // A button will call this function
        //

        // c prefix ==> canvas related operation
        cRectInsert: function cRectInsert(insertEvent) {
            var scalingFactor = this.get('scalingFactor');
            var posX = 195 * scalingFactor;
            var posY = 130 * scalingFactor;
            if (insertEvent.insertPos !== undefined) {
                posX = insertEvent.insertPos.x * scalingFactor;
                posY = insertEvent.insertPos.y * scalingFactor;
            }
            var stage = this.get('objStage');
            var that = this;
            var box = new Konva.Rect({
                myController: this,
                x: posX / scalingFactor,
                y: posY / scalingFactor,
                width: 100,
                height: 50,
                stroke: '#8A2BE2',
                strokeWidth: 2,
                strokeScaleEnabled: false,
                opacity: 0.8,
                draggable: true,
                resizing: false
            });
            box.setId(insertEvent.id);
            box.on('dragmove', function (event) {
                var shape = event.target;
                if (shape !== undefined) {
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                        msgCat: 'objMoved',
                        id: box.attrs.id,
                        x: box.attrs.x / scalingFactor,
                        y: box.attrs.y / scalingFactor
                    });
                }
            });
            box.on('dblclick dbltap', function () {
                box.attrs.resizing = !box.attrs.resizing;
                if (box.attrs.resizing) {
                    that.addResizingGroup(box);
                } else {
                    that.removeResizingGroup();
                }
            });
            stage.getLayers()[0].add(box);
            var thisZIndex = insertEvent.thisZIndex;
            var topZIndex = this.get('topZIndex');
            if (thisZIndex > topZIndex) {
                this.set('topZIndex', thisZIndex);
            }
            box.setZIndex(thisZIndex);
            stage.draw();
        },

        cPaintFreehand: function cPaintFreehand(color, brushWidth) {
            var scalingFactor = this.get('scalingFactor');
            var stage = this.get('objStage');
            var isPaint = false;
            var lastPointerPosition = undefined;
            var canvas = document.createElement('canvas');
            canvas.width = stage.width();
            canvas.height = stage.height();
            var context = canvas.getContext('2d');
            context.strokeStyle = color;
            context.lineJoin = "round";
            context.lineWidth = brushWidth;
            var image = new Konva.Image({
                image: canvas,
                x: 0,
                y: 0,
                width: 880 * scalingFactor,
                height: 700 * scalingFactor,
                strokeEnabled: false
            });

            image.on('mousedown touchstart', function () {
                isPaint = true;
                lastPointerPosition = stage.getPointerPosition();
            });

            image.on('mousemove touchmove', function () {
                if (!isPaint) {
                    return;
                }
                context.globalCompositeOperation = 'source-over';
                context.beginPath();
                var localPos = {
                    x: lastPointerPosition.x - image.x(),
                    y: lastPointerPosition.y - image.y()
                };
                context.moveTo(localPos.x, localPos.y);
                var pos = stage.getPointerPosition();
                localPos = {
                    x: pos.x - image.x(),
                    y: pos.y - image.y()
                };
                context.lineTo(localPos.x, localPos.y);
                context.closePath();
                context.stroke();
                lastPointerPosition = pos;
                stage.draw();
            });

            image.on('mouseup touchend', function () {
                isPaint = false;
            });

            stage.getLayers()[0].add(image);
            stage.draw();
            this.set('freehandImage', image);
        },

        cStopFreehand: function cStopFreehand() {
            var scalingFactor = this.get('scalingFactor');
            var that = this;
            var stage = this.get('objStage');
            var image = this.get('freehandImage');
            image.setScale({
                x: 1 / scalingFactor,
                y: 1 / scalingFactor
            });
            if (image) {
                image.toImage({
                    callback: function callback(img) {
                        image.remove();
                        image.destroy();
                        var imgSrc = img.src;
                        var base64ImageContent = imgSrc.replace(/^data:image\/(png|jpg);base64,/, "");
                        var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                        $.ajax({
                            type: 'POST',
                            url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads64",
                            data: base64ImageContent,
                            processData: false,
                            contentType: false,
                            headers: {
                                'Authorization': 'Bearer ' + lsat
                            },
                            success: function success(data) {
                                if (typeof data.error === 'undefined') {
                                    // Success so call function to process the form
                                    that.getPicture(data.path, 'fh');
                                    that.set('selectedObj', null);
                                    that.set('freehandImage', null);
                                    stage.draw();
                                } else {
                                    // Handle errors here
                                }
                            },
                            error: function error() {
                                // Handle errors here
                            }
                        });
                    }
                });
            }
        },

        cLabelInsert: function cLabelInsert(event) {
            var scalingFactor = this.get('scalingFactor');
            var id = event.id;
            var eventParentId = event.parentId;
            var labelColor = event.labelColor;
            var posX = 195;
            var posY = 130;
            var insertPos = event.insertPos;
            if (insertPos !== undefined) {
                posX = insertPos.x;
                posY = insertPos.y;
            }
            var labelLeft = new Konva.Label({
                x: posX * scalingFactor,
                y: posY * scalingFactor,
                draggable: true,
                opacity: 0.75,
                myController: this
            });

            labelLeft.add(new Konva.Tag({
                fill: labelColor,
                pointerDirection: event.labelDirection,
                pointerWidth: 20 * scalingFactor,
                pointerHeight: 28 * scalingFactor,
                lineJoin: 'round',
                stroke: 'white',
                strokeWidth: 2
            }));

            var fillColor = '#000000';
            var lightness = [];
            var luma = [0.3, 0.59, 0.11]; // Rec. 601 luma
            var finalLightness;
            var lightnessLimit = 60;
            if (labelColor.substr(0, 1) === '#') {
                var hex = labelColor.substr(1).split('');
                var i = 0;
                var hexStr;
                while (i < 3) {
                    hexStr = hex[i * 2] + hex[i * 2 + 1];
                    lightness[i] = luma[i] * parseInt(hexStr, 16);
                    i += 1;
                }
                finalLightness = lightness[0] + lightness[1] + lightness[2];
                if (finalLightness < lightnessLimit) {
                    fillColor = '#ffffff';
                }
            }
            labelLeft.add(new Konva.Text({
                text: event.labelText,
                fontSize: 24,
                padding: 5,
                fill: fillColor
            }));

            // add cursor styling
            labelLeft.on('mouseover', function () {
                document.body.style.cursor = 'pointer';
            });
            labelLeft.on('mouseout', function () {
                document.body.style.cursor = 'default';
            });
            labelLeft.on('dragmove', function (dragmoveEvent) {
                dragmoveEvent.target = this;
                var shape = dragmoveEvent.target;
                if (shape !== undefined) {
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                        msgCat: 'objMoved',
                        id: shape.attrs.id,
                        x: shape.attrs.x / scalingFactor,
                        y: shape.attrs.y / scalingFactor
                    });
                }
            });
            var stage = this.get('objStage');
            var layer = this.get('objStage').getLayers()[0];
            labelLeft.on("dragend", function () {
                var labelLeftParentType = labelLeft.parent.nodeType;
                if (labelLeftParentType !== 'Group') {
                    var targetId = labelLeft.attrs.id;
                    var labelLeftPosition = labelLeft.getPosition();
                    var intersectedObj = stage.getIntersection(labelLeftPosition);
                    if (intersectedObj) {
                        if (intersectedObj.className === "Tag") {
                            var testLabelY = labelLeftPosition.y + 2;
                            if (labelLeftPosition.y > 2) {
                                testLabelY = labelLeftPosition.y - 2;
                            }
                            var testLabelLeftPosition = {
                                x: labelLeftPosition.x,
                                y: testLabelY
                            };
                            intersectedObj = stage.getIntersection(testLabelLeftPosition);
                        }
                        if (intersectedObj) {
                            var parentNode = intersectedObj.parent;
                            var parentNodeType = parentNode.nodeType;
                            var intersectedObjClass = intersectedObj.className;
                            if (parentNodeType && parentNodeType === 'Group' && intersectedObjClass === 'Image') {
                                var parentId = parentNode.attrs.id;
                                parentNode.add(labelLeft);
                                var newNodeX = labelLeft.attrs.x - parentNode.attrs.x;
                                var newNodeY = labelLeft.attrs.y - parentNode.attrs.y;
                                labelLeft.setX(newNodeX);
                                labelLeft.setY(newNodeY);
                                layer.draw();
                                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                                    msgCat: 'objMoved',
                                    id: targetId,
                                    x: newNodeX / scalingFactor,
                                    y: newNodeY / scalingFactor,
                                    parentId: parentId
                                });
                            }
                        }
                    }
                } else {
                    var labelLeftX = labelLeft.attrs.x;
                    var labelLeftY = labelLeft.attrs.y;
                    if (labelLeftX < 0 || labelLeftX > 300 * scalingFactor || labelLeftY < 0 || labelLeftY > 225 * scalingFactor) {
                        var targetId = labelLeft.attrs.id;
                        var labelParent = labelLeft.parent;
                        layer.add(labelLeft);
                        var newNodeX = labelParent.attrs.x + labelLeftX;
                        var newNodeY = labelParent.attrs.y + labelLeftY;
                        labelLeft.setX(newNodeX);
                        labelLeft.setY(newNodeY);
                        layer.draw();
                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                            msgCat: 'objMoved',
                            id: targetId,
                            x: newNodeX / scalingFactor,
                            y: newNodeY / scalingFactor,
                            parentId: 'Layer'
                        });
                    }
                }
            });

            labelLeft.setId(id);
            var parentInSpe = layer;
            if (eventParentId && eventParentId !== 'Layer') {
                layer.getChildren().forEach(function (shape) {
                    if (shape.attrs.id === eventParentId) {
                        parentInSpe = shape;
                    }
                });
            }
            parentInSpe.add(labelLeft);
            var thisZIndex = event.thisZIndex;
            labelLeft.setZIndex(thisZIndex);
            var topZIndex = this.get('topZIndex');
            if (thisZIndex > topZIndex) {
                this.set('topZIndex', thisZIndex);
            }
            this.get('objStage').draw();
            return id;
        },

        cDeleteAll: function cDeleteAll() {
            var stage = this.get('objStage');
            var layer = stage.getLayers()[0];
            this.set('selectedObj', null);
            layer.destroyChildren();
            stage.draw();
        },

        // ------------ Konva helper  ----------------

        translateToCanvas: function translateToCanvas(dropPosition) {
            var canvasPos = $('canvas').offset();
            var canvasDropPosition = {
                x: dropPosition.x - canvasPos.left,
                y: dropPosition.y - canvasPos.top
            };
            return canvasDropPosition;
        },

        // ------------- Emulation Version --------------

        getPicture: function getPicture(imageURI, options) {
            var scalingFactor = this.get('scalingFactor');
            var freehandDraw = false;
            var whiteboardScreenshot = false;
            if (options === 'fh') {
                freehandDraw = true;
            } else if (options === 'ws') {
                whiteboardScreenshot = true;
            }
            var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
            var image = new Image();
            image.src = _whiteboard2FrontendApp['default'].API_HOST + imageURI + "?jwt=Bearer " + lsat;

            var defaultDropPosition = {
                'x': 200 * scalingFactor,
                'y': 250 * scalingFactor
            };
            var that = this;
            this.set('isImageLoading', true);
            var topZIndex = this.get('topZIndex') + 1;
            that.set('topZIndex', topZIndex);
            var insertPosX = 0;
            var insertPosY = 0;
            if (!freehandDraw && !whiteboardScreenshot) {
                insertPosX = that.translateToCanvas(defaultDropPosition).x;
                insertPosY = that.translateToCanvas(defaultDropPosition).y;
            }

            var stage = that.get('objStage');
            var layer = stage.getLayers()[0];
            var id = that.getNewObjId();

            var konvaGroup = new Konva.Group({
                x: insertPosX * scalingFactor,
                y: insertPosY * scalingFactor,
                draggable: true
            });

            image.onload = function () {
                var imgScale = 1;
                if (!freehandDraw) {
                    var maxDrawSize = 300;
                    if (whiteboardScreenshot) {
                        maxDrawSize = 880;
                    }
                    var imageMaxSize = 0;
                    if (image.width > image.height) {
                        imageMaxSize = image.width;
                    } else {
                        imageMaxSize = image.height;
                    }
                    imgScale = maxDrawSize / imageMaxSize;
                }
                var konvaImage = new Konva.Image({
                    image: image,
                    x: 0,
                    y: 0,
                    width: image.width * scalingFactor,
                    height: image.height * scalingFactor
                });
                konvaImage.setScale({
                    x: imgScale * scalingFactor,
                    y: imgScale * scalingFactor
                });
                konvaGroup.on('mouseover', function () {
                    document.body.style.cursor = 'pointer';
                });
                konvaGroup.on('mouseout', function () {
                    document.body.style.cursor = 'default';
                });
                konvaGroup.on('dragmove', function (event) {
                    var shape = event.target;
                    if (shape !== undefined) {
                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                            msgCat: 'objMoved',
                            id: konvaGroup.attrs.id,
                            x: konvaGroup.attrs.x * scalingFactor,
                            y: konvaGroup.attrs.y * scalingFactor
                        });
                    }
                });
                //TODO: Bildgröße ändern
                konvaImage.on('dblclick dbltap', function () {
                    konvaImage.attrs.resizing = !konvaImage.attrs.resizing;
                    if (konvaImage.attrs.resizing) {} else {}
                });
                if (freehandDraw) {
                    konvaImage.cache();
                    konvaImage.drawHitFromCache();
                }
                var imgId = "img_" + id;
                konvaImage.setId(imgId);
                konvaGroup.setId(id);
                konvaGroup.add(konvaImage);
                layer.add(konvaGroup);
                konvaImage.setZIndex(topZIndex);
                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, image.width, image.height);

                //$('#imageLoadDialog').modal('hide');
                that.set('isImageLoading', false);

                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                    msgCat: 'newImage',
                    id: id,
                    imageInfo: imageURI,
                    insertPos: {
                        x: insertPosX / scalingFactor,
                        y: insertPosY / scalingFactor
                    },
                    options: options,
                    thisZIndex: topZIndex
                });
                stage.draw();
            };
        },

        // a rectangle should be selected before calling cloneImageRegion
        // the selected rectangle should be located inside a image and described the region
        // of the image as the source for a new image
        cloneImageRegion: function cloneImageRegion() {
            var scalingFactor = this.get('scalingFactor');
            var region = this.get('selectedObj');
            if (region === undefined || region === null) {
                alert(this.get('i18n').t('selectAObject'));
                return;
            }
            if (region.getClassName() !== 'Rect') {
                alert(this.get('i18n').t('notARect'));
                return;
            }
            var interSectionTestPos = region.getPosition();
            interSectionTestPos.x = interSectionTestPos.x - 5;
            interSectionTestPos.y = interSectionTestPos.y - 5;
            var stage = this.get('objStage');
            var intersectedObj = stage.getIntersection(interSectionTestPos);
            if (intersectedObj === null) {
                return;
            }
            if (intersectedObj.getClassName() !== 'Image') {
                alert(this.get('i18n').t('notOverImage'));
                return;
            }
            var objParent = intersectedObj.parent;
            intersectedObj.attrs.strokeScaleEnabled = false;
            var scale = 1.0 / intersectedObj.getScale().x;
            console.log('scale: ', scale);
            console.log('scalingFactor: ', scalingFactor);
            var width = region.attrs.width * scale;
            var height = region.attrs.height * scale;
            var x = (region.getX() - objParent.getX()) * scale;
            var y = (region.getY() - objParent.getY()) * scale;

            intersectedObj.remove();
            intersectedObj.setScale({
                x: 1.0,
                y: 1.0
            });
            intersectedObj.setPosition({
                x: 0.0,
                y: 0.0
            });
            var that = this;
            var offscreen = this.get('imageOffScreenRenderLayer');
            offscreen.add(intersectedObj);

            intersectedObj.toImage({
                x: x,
                y: y,
                width: width,
                height: height,
                callback: function callback(img) {
                    intersectedObj.remove();
                    intersectedObj.setScale({
                        x: 1.0 / scale,
                        y: 1.0 / scale
                    });
                    objParent.add(intersectedObj);
                    var base64ImageContent = img.src.replace(/^data:image\/(png|jpg);base64,/, "");
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads64",
                        data: base64ImageContent,
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                // Success so call function to process the form
                                that.getPicture(data.path, false);
                                var id = region.attrs.id;
                                region.destroy();
                                that.set('selectedObj', null);
                                stage.draw();
                                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                                    msgCat: 'deleteObj',
                                    id: id
                                });
                            } else {
                                // Handle errors here
                            }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                }
            });
        },

        // -----------------------------------------------------------------
        //
        //      Resizing support ==> mainly for rectangle

        moveToTop: function moveToTop() {
            var selObj = this.get('selectedObj');
            if (selObj !== undefined) {
                selObj.moveToTop();
                var layer = this.get('objStage').getLayers()[0];
                layer.draw();
            }
        },

        // private methods for resizing
        addAnchor: function addAnchor(group, x, y, name) {
            function update(activeAnchor) {
                var group = activeAnchor.getParent();

                var topLeft = group.get('.topLeft')[0];
                var topRight = group.get('.topRight')[0];
                var bottomRight = group.get('.bottomRight')[0];
                var bottomLeft = group.get('.bottomLeft')[0];
                var rect = group.find('Rect')[0];

                var anchorX = activeAnchor.getX();
                var anchorY = activeAnchor.getY();

                // update anchor positions
                switch (activeAnchor.getName()) {
                    case 'topLeft':
                        topRight.setY(anchorY);
                        bottomLeft.setX(anchorX);
                        break;
                    case 'topRight':
                        topLeft.setY(anchorY);
                        bottomRight.setX(anchorX);
                        break;
                    case 'bottomRight':
                        bottomLeft.setY(anchorY);
                        topRight.setX(anchorX);
                        break;
                    case 'bottomLeft':
                        bottomRight.setY(anchorY);
                        topLeft.setX(anchorX);
                        break;
                }
                rect.setPosition(topLeft.getPosition());
                var width = topRight.getX() - topLeft.getX();
                var height = bottomLeft.getY() - topLeft.getY();
                if (width && height) {
                    //rect.setSize(width, height);
                    rect.attrs.width = width;
                    rect.attrs.height = height;
                }
            }

            var stage = this.get('objStage');
            var layer = stage.getLayers()[0];
            var anchor = new Konva.Circle({
                x: x,
                y: y,
                stroke: '#666',
                fill: '#ddd',
                strokeWidth: 2,
                radius: 8,
                name: name,
                draggable: true,
                dragOnTop: false
            });

            anchor.on('dragmove', function () {
                update(this);
                layer.draw();
            });
            anchor.on('mousedown touchstart', function () {
                group.setDraggable(false);
                this.moveToTop();
            });
            anchor.on('dragend', function () {
                group.setDraggable(true);
                layer.draw();
            });
            // add hover styling
            anchor.on('mouseover', function () {
                var layer = this.getLayer();
                document.body.style.cursor = 'pointer';
                this.setStrokeWidth(4);
                layer.draw();
            });
            anchor.on('mouseout', function () {
                var layer = this.getLayer();
                document.body.style.cursor = 'default';
                this.setStrokeWidth(2);
                layer.draw();
            });
            group.add(anchor);
        },

        // Resizing helper
        addResizingGroup: function addResizingGroup(graphicObj) {
            var selGroup = new Konva.Group({
                x: graphicObj.getX(),
                y: graphicObj.getY(),
                draggable: true
            });
            // Das Grafikobjekt  darf nicht draggable sein sonst laesst sich die Gruppe nicht bewegen !!!
            graphicObj.attrs.draggable = false;
            var layer = this.get('objStage').getLayers()[0];
            graphicObj.remove();
            graphicObj.setX(0);
            graphicObj.setY(0);
            selGroup.add(graphicObj);
            this.addAnchor(selGroup, 0, 0, 'topLeft');
            this.addAnchor(selGroup, graphicObj.getWidth(), 0, 'topRight');
            this.addAnchor(selGroup, graphicObj.getWidth(), graphicObj.getHeight(), 'bottomRight');
            this.addAnchor(selGroup, 0, graphicObj.getHeight(), 'bottomLeft');
            selGroup.on('dragstart', function () {
                selGroup.moveToTop();
            });
            selGroup.setDraggable(true);
            layer.add(selGroup);
            layer.draw();
        },

        removeResizingGroup: function removeResizingGroup() {
            var that = this;
            var group = this.get('selectedObj');
            if (group !== undefined) {
                var rect = group.find('Rect')[0];
                group.remove();
                var layer = this.get('objStage').getLayers()[0];
                rect.remove();
                // Recteck muss positioniert werden, war in der Gruppe relativ angeordnet !!!
                rect.setX(group.getX() + rect.getX());
                rect.setY(group.getY() + rect.getY());

                rect.setDraggable(true);
                group.destroy();
                layer.add(rect);
                if (rect !== undefined) {
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicWBRoom, [], {
                        msgCat: 'rectAdjust',
                        id: rect.attrs.id,
                        x: rect.attrs.x,
                        y: rect.attrs.y,
                        width: rect.attrs.width,
                        height: rect.attrs.height
                    });
                    that.set('selectedObj', rect);
                }
                layer.draw();
            }
        }, // End Resizing

        shapeHelper: function shapeHelper(shape, color) {
            if (shape != null) {
                var borderWidth = 2;
                if (color === '#8A2BE2') {
                    borderWidth = 0;
                }
                if (shape.getClassName() === 'Tag' || shape.getClassName() === 'Text') {
                    if (shape.getClassName() === 'Tag') {
                        shape.attrs.stroke = color;
                        shape.attrs.strokeWidth = borderWidth;
                    } else {
                        // if Text
                        shape.getParent().getTag().attrs.stroke = color;
                    }
                    shape = shape.getParent();
                } else {
                    if (shape.getClassName() === 'Label') {
                        shape.getTag().attrs.stroke = color;
                        shape.getTag().attrs.strokeWidth = borderWidth;
                    } else {
                        shape.attrs.stroke = color;
                        shape.attrs.strokeWidth = borderWidth;
                    }
                }
            }
            return shape;
        }
    });
});