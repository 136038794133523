define('whiteboard2-frontend/conference/administration/customers/new/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        customers: _ember['default'].inject.controller('conference.administration.customers'),
        i18n: _ember['default'].inject.service(),
        leftRegular: false,

        actions: {
            saveNewCustomer: function saveNewCustomer() {
                var that = this;
                var customer = this.get('model');
                var customerName = customer.get('name');
                var customerLocation = customer.get('location');
                if (!customerName) {
                    this.get('administration').send('newAttention', this.get('i18n').t('customerNameInput'));
                } else if (!customerLocation) {
                    this.get('administration').send('newAttention', this.get('i18n').t('customerLocationInput'));
                } else {
                    customer.save().then(function () {
                        that.set('leftRegular', true);
                        that.get('customers').send('refreshIt');
                        that.get('administration').send('newAttention', that.get('i18n').t('newCustomerCreated'));
                        that.transitionToRoute('conference.administration.customers');
                    }, function (error) {
                        var errorMsg = that.get('i18n').t('newCustomerError') + ': ' + error;
                        that.get('administration').send('newAttention', errorMsg);
                    });
                }
            },

            cancel: function cancel() {
                this.get('administration').send('newAttention', this.get('i18n').t('newCustomerCanceled'));
                this.transitionToRoute('conference.administration.customers');
            }
        }
    });
});