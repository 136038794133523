define("whiteboard2-frontend/helpers/checklist-icon", ["exports", "ember"], function (exports, _ember) {
    exports.checklistIcon = checklistIcon;

    function checklistIcon(params) {
        var checker = params[0];
        var outputString = _ember["default"].String.htmlSafe("<p class='text-warning' style='float:left;'><i class='fa fa-list' aria-hidden='true'></i></p> ");
        if (checker) {
            if (checker === "new") {
                outputString = _ember["default"].String.htmlSafe("<p class='text-success' style='float:left;'><i class='fa fa-plus' aria-hidden='true'></i></p> ");
            } else {
                outputString = _ember["default"].String.htmlSafe("<p class='text-success' style='float:left;'><i class='fa fa-check-square-o' aria-hidden='true'></i></p> ");
            }
        }
        return outputString;
    }

    exports["default"] = _ember["default"].Helper.helper(checklistIcon);
});