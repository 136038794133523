define('whiteboard2-frontend/conference/administration/accounts/new/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        accounts: _ember['default'].inject.controller('conference.administration.accounts'),
        i18n: _ember['default'].inject.service(),
        roles: ['TECHNICIAN', 'ADMIN', 'CUSTOMER'],
        thisClient: false,
        leftRegular: false,

        isEmail: function isEmail(email) {
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(email);
        },

        actions: {
            selectionChangedRole: function selectionChangedRole(value) {
                console.log('selectionChangedRole value:', value);
                this.get('model').set('role', value);
                if (value === 'CUSTOMER') {
                    this.set('thisClient', true);
                } else {
                    this.set('thisClient', false);
                }
            },

            selectionChangedCustomer: function selectionChangedCustomer(value) {
                console.log('selectionChangedCustomer - value: ', value);
            },

            saveNewAccount: function saveNewAccount() {
                var that = this;
                var account = this.get('model');
                var accountMail = account.get('email');
                var validEmail = this.isEmail(accountMail);
                if (validEmail) {
                    account.save().then(function () {
                        that.set('leftRegular', true);
                        that.get('accounts').send('refreshIt');
                        that.get('administration').send('newAttention', that.get('i18n').t('newAccountCreated'));
                        that.transitionToRoute('conference.administration.accounts');
                    }, function (error) {
                        var errorMsg = that.get('i18n').t('newAccountError') + ': ' + error;
                        that.get('administration').send('newAttention', errorMsg);
                    });
                } else {
                    that.get('administration').send('newAttention', that.get('i18n').t('pleasEnterMail'));
                }
            },

            cancel: function cancel() {
                this.get('administration').send('newAttention', this.get('i18n').t('newAccountCanceled'));
                this.transitionToRoute('conference.administration.accounts');
            }
        }
    });
});