define('whiteboard2-frontend/conference/route', ['exports', 'ember', 'whiteboard2-frontend/app', 'whiteboard2-frontend/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _whiteboard2FrontendApp, _whiteboard2FrontendConfigEnvironment, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        activate: function activate() {

            _whiteboard2FrontendApp['default'].topicSystem = 'system';
            _whiteboard2FrontendApp['default'].roomChatSubscription = null;
            _whiteboard2FrontendApp['default'].roomWBSubscription = null;
            _whiteboard2FrontendApp['default'].roomRtcSubscription = null;
            _whiteboard2FrontendApp['default'].roomPdfSubscription = null;

            _whiteboard2FrontendApp['default'].whoAmI = 'ME';
            _whiteboard2FrontendApp['default'].onlinePosition = 'Index';
            _whiteboard2FrontendApp['default'].chosenRoomName = 'General';
            _whiteboard2FrontendApp['default'].iUseAudio = false;
            _whiteboard2FrontendApp['default'].iUseVideo = false;
            _whiteboard2FrontendApp['default'].commIUse = 0;

            var connection = new autobahn.Connection({
                url: 'wss://' + _whiteboard2FrontendConfigEnvironment['default'].home + '/ws',
                realm: 'webware:experts'
            });

            connection.onopen = function (session) {
                session.subscribe(_whiteboard2FrontendApp['default'].topicSystem, _whiteboard2FrontendApp['default'].conference.eventSystem).then(function (subscription) {
                    _whiteboard2FrontendApp['default'].mySessionId = session.id;
                    _whiteboard2FrontendApp['default'].systemSubscription = subscription;
                    _whiteboard2FrontendApp['default'].conference.askOthers();
                }, function (error) {
                    that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + error);
                });
                session.subscribe('General', _whiteboard2FrontendApp['default'].conference.newMessage).then(function (subscription) {
                    _whiteboard2FrontendApp['default'].generalSubscription = subscription;
                }, function (error) {
                    that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + error);
                });
                _whiteboard2FrontendApp['default'].WS_SESSION = session;
            };
            connection.open();

            var that = this;
            var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
            $.ajax({
                type: 'GET',
                url: _whiteboard2FrontendApp['default'].API_HOST + "/api/whoami",
                data: '',
                processData: false,
                contentType: false,
                headers: { 'Authorization': 'Bearer ' + lsat },
                success: function success(data) {
                    if (typeof data.error === 'undefined') {
                        var role = data.role;
                        if (role === 'ADMIN') {
                            that.controllerFor('conference').set('role', role);
                        }
                        var whoIAm = data.firstname + " " + data.lastname;
                        that.controllerFor('conference').set('whoAmI', whoIAm);
                        _whiteboard2FrontendApp['default'].whoAmI = whoIAm;
                        _whiteboard2FrontendApp['default'].myId = data.id;
                    } else {
                        that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                    }
                },
                error: function error(jqXHR, textStatus) {
                    that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + textStatus);
                }
            });
            this.controllerFor('conference').send('firstRunAfterRender');
        },

        model: function model() {
            return this.get('store').findAll('room');
        },

        deactivate: function deactivate() {
            _whiteboard2FrontendApp['default'].conference.askOthers('out');
            if (_whiteboard2FrontendApp['default'].systemSubscription) {
                _whiteboard2FrontendApp['default'].systemSubscription.unsubscribe(_whiteboard2FrontendApp['default'].systemSubscription);
            }
            if (_whiteboard2FrontendApp['default'].generalSubscription) {
                _whiteboard2FrontendApp['default'].generalSubscription.unsubscribe(_whiteboard2FrontendApp['default'].generalSubscription);
            }
            if (_whiteboard2FrontendApp['default'].roomChatSubscription) {
                _whiteboard2FrontendApp['default'].roomChatSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomChatSubscription);
            }
            if (_whiteboard2FrontendApp['default'].roomWBSubscription) {
                _whiteboard2FrontendApp['default'].roomWBSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomWBSubscription);
            }
            if (_whiteboard2FrontendApp['default'].roomRtcSubscription) {
                _whiteboard2FrontendApp['default'].roomRtcSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomRtcSubscription);
            }
            if (_whiteboard2FrontendApp['default'].roomPdfSubscription) {
                _whiteboard2FrontendApp['default'].roomPdfSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomPdfSubscription);
            }
        }
    });
});