define('whiteboard2-frontend/conference/administration/accounts/new/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.accounts.new', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model() {
            return this.get('store').createRecord('account', { role: 'TECHNICIAN' });
        },

        afterModel: function afterModel() {
            var accounts = this.modelFor('conference.administration.accounts');
            accounts.forEach(function (account) {
                account.set('selected', false);
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            // load customers
            this.get('store').findAll('customer').then(function (customers) {
                controller.set('customers', customers);
            });
        },

        activate: function activate() {
            this.controllerFor('conference.administration.customers.new').set('leftRegular', false);
        },

        deactivate: function deactivate() {
            var leftRegular = this.controllerFor('conference.administration.customers.new').get('leftRegular');
            var model = this.controllerFor('conference.administration.customers.new').get('model');
            if (!leftRegular) {
                if (model) {
                    model.deleteRecord();
                }
            }
        }
    });
});