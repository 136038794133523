define('whiteboard2-frontend/conference/videos/guided/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({
        queryParams: {
            page: {
                refreshModel: true
            }
        },

        activate: function activate() {
            _whiteboard2FrontendApp['default'].onlinePosition = 'Guided';
            this.controllerFor('conference/videos/guided').send('runAfterRender');
            _ember['default'].run.later(function () {
                _whiteboard2FrontendApp['default'].conference.askOthers();
            }, 1000);
        },

        model: function model(params) {
            return this.get('store').query('video', params);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('model', model);
            controller.set('meta', model.meta);
            model.forEach(function (thisVideo) {
                thisVideo.set('selected', false);
            });
        },

        renderTemplate: function renderTemplate() {
            this.render('conference/videos/guided', {
                into: 'conference'
            });
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        },

        deactivate: function deactivate() {
            this.controllerFor('conference/videos/guided').send('runOnDeactivate');
        }
    });
});