define('whiteboard2-frontend/conference/administration/rooms/new/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.rooms.new', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        activate: function activate() {
            this.controllerFor('conference.administration.rooms.new').set('leftRegular', false);
        },

        model: function model() {
            return this.get('store').createRecord('room');
        },

        afterModel: function afterModel() {
            var rooms = this.modelFor('conference.administration.rooms');
            rooms.forEach(function (room) {
                room.set('selected', false);
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            var allUser = this.get('store').findAll('user');
            controller.set('users', allUser);
        },

        deactivate: function deactivate() {
            this.controllerFor('conference.administration.rooms.new').set('chosenUser', []);
            var leftRegular = this.controllerFor('conference.administration.rooms.new').get('leftRegular');
            var model = this.controllerFor('conference.administration.rooms.new').get('model');
            if (!leftRegular) {
                if (model) {
                    model.deleteRecord();
                }
            }
        }
    });
});