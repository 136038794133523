define('whiteboard2-frontend/pwreset/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({

        error: null,

        actions: {
            sendUserData: function sendUserData() {
                var that = this;
                var token = window.location.search.substring(1);
                var tokenValue = token.replace('token=', '');
                var firstPassword = this.get('firstPassword');
                var confPassword = this.get('confPassword');
                var formData = new FormData();
                formData.append('token', tokenValue);
                formData.append('password', firstPassword);
                formData.append('confirmPassword', confPassword);
                $.ajax({
                    type: 'POST',
                    url: _whiteboard2FrontendApp['default'].API_HOST + "/api/confirm-password",
                    data: formData,
                    contentType: false,
                    processData: false,
                    success: function success(data) {
                        if (typeof data.error === 'undefined') {
                            that.transitionToRoute('login');
                        } else {
                            console.log('error sent: ', data);
                            that.set('error', data);
                        }
                    },
                    error: function error(_error) {
                        that.set('error', _error);
                    }
                });
            }
        }
    });
});