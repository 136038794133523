define('whiteboard2-frontend/login/route', ['exports', 'ember', 'whiteboard2-frontend/app', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _whiteboard2FrontendApp, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
        routeIfAlreadyAuthenticated: 'conference',

        activate: function activate() {
            var testExp = new RegExp('Android|webOS|iPhone|iPad|' + 'BlackBerry|Windows Phone|' + 'Opera Mini|IEMobile|Mobile', 'i');
            if (testExp.test(navigator.userAgent)) {
                _whiteboard2FrontendApp['default'].userAgent = 'Mobile';
            } else {
                _whiteboard2FrontendApp['default'].userAgent = 'Desktop';
            }
        }
    });
});