define('whiteboard2-frontend/conference/administration/accounts/account/edit/route', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.accounts.account.edit', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model() {
            var account = this.modelFor('conference.administration.accounts.account');
            return _rsvp['default'].hash({
                account: account,
                user: account.get('user')
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            // load customers
            this.get('store').findAll('customer').then(function (customers) {
                controller.set('customers', customers);
            });
        }
    });
});