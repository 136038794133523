define('whiteboard2-frontend/conference/administration/accounts/account/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        i18n: _ember['default'].inject.service(),
        roles: ['TECHNICIAN', 'ADMIN', 'CUSTOMER'],
        gender: ['MALE', 'FEMALE'],

        thisClient: false,

        actions: {
            selectionChangedRole: function selectionChangedRole(value) {
                this.get('model').set('role', value);
                if (value === 'CUSTOMER') {
                    this.set('thisClient', true);
                } else {
                    this.set('thisClient', false);
                }
            },

            selectionChangedCustomer: function selectionChangedCustomer(value) {
                this.get('model').set('role', value);
                this.set('thisClient', false);
            },

            cancel: function cancel() {
                this.get('administration').send('newAttention', this.get('i18n').t('accountEditingCanceled'));
                this.transitionToRoute('conference.administration.accounts');
            },

            saveAcc: function saveAcc() {
                this.get('administration').send('newAttention', this.get('i18n').t('accountEdited'));
                this.get('model.account').save();
                this.get('model.user').save();
                this.transitionToRoute('conference.administration.accounts');
            }
        }
    });
});