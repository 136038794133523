define('whiteboard2-frontend/conference/administration/customers/customer/edit/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.customers.customer.edit', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model() {
            return this.modelFor('conference.administration.customers.customer');
        }
    });
});