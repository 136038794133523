define('whiteboard2-frontend/conference/administration/accounts/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        i18n: _ember['default'].inject.service(),
        queryParams: ['page'],
        page: 1,

        actions: {
            showAccount: function showAccount(accountId) {
                this.get('model').forEach(function (account) {
                    account.set('selected', false);
                });
                this.transitionToRoute('conference.administration.accounts.account', accountId);
            },

            deleteAccModal: function deleteAccModal(account) {
                this.set('account', account);
                $('#modalDeleteAcc').modal('show');
            },

            deleteAcc: function deleteAcc(account) {
                var myId = parseInt(_whiteboard2FrontendApp['default'].myId);
                var accountId = parseInt(account.get('id'));
                account.set('selected', false);
                account.deleteRecord();
                account.save();
                this.get('administration').send('newAttention', this.get('i18n').t('accountDeleted'));
                console.log('myId: ', myId);
                console.log('accountId: ', accountId);
                if (accountId === myId) {
                    console.log('accountId === myId');
                    this.get('session').invalidate();
                } else {
                    console.log('accountId !== myId');
                    this.transitionToRoute('conference.administration.accounts');
                }
            },

            refreshIt: function refreshIt() {
                this.send('refreshModel');
            }
        }
    });
});