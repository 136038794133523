define('whiteboard2-frontend/helpers/byte-mb', ['exports', 'ember'], function (exports, _ember) {
    exports.byteMB = byteMB;

    function byteMB(value) {
        var brokenValue = value;
        var unit = 'B';
        if (value < 1000000) {
            brokenValue = value / 1000;
            unit = 'KB';
        } else {
            brokenValue = value / 1000000;
            unit = 'MB';
        }
        var roundValue = Math.round(brokenValue * 100) / 100;
        return roundValue + ' ' + unit;
    }

    exports['default'] = _ember['default'].Helper.helper(byteMB);
});