define('whiteboard2-frontend/models/user', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
    exports['default'] = _emberData['default'].Model.extend({
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        gender: _emberData['default'].attr('string'),
        phone: _emberData['default'].attr('string'),
        academicTitle: _emberData['default'].attr('string'),
        additionalInfo: _emberData['default'].attr('string'),
        img: _emberData['default'].attr('string'),
        account: _emberData['default'].belongsTo('account'),

        fullName: _ember['default'].computed('firstName', 'lastName', function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        })
    });
});