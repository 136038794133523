define('whiteboard2-frontend/conference/administration/customers/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        i18n: _ember['default'].inject.service(),
        queryParams: ['page'],
        page: 1,

        actions: {
            showCustomer: function showCustomer(customerId) {
                this.get('model').forEach(function (customer) {
                    customer.set('selected', false);
                });
                this.transitionToRoute('conference.administration.customers.customer', customerId);
            },

            deleteCustomerModal: function deleteCustomerModal(customer) {
                $('#modalDeleteCustomer').modal('show');
                this.set('customer', customer);
            },

            deleteCustomer: function deleteCustomer(customer) {
                customer.set('selected', false);
                customer.deleteRecord();
                customer.save();
                this.get('administration').send('newAttention', this.get('i18n').t('customerDeleted'));
                this.transitionToRoute('conference.administration.customers');
            },

            refreshIt: function refreshIt() {
                this.send('refreshModel');
            }
        }
    });
});