define('whiteboard2-frontend/adapters/application', ['exports', 'ember', 'ember-data', 'whiteboard2-frontend/app', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ember, _emberData, _whiteboard2FrontendApp, _emberSimpleAuthMixinsDataAdapterMixin) {
    exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
        namespace: 'api',
        host: _whiteboard2FrontendApp['default'].API_HOST,

        /*
        headers:Ember.computed(function(){
            return {
                'WeSense-Device-Fingerprint':client.getFingerprint()
            };
        }),
        */

        authorizer: 'authorizer:oauth2',
        pathForType: function pathForType(type) {
            var dasherized = _ember['default'].String.dasherize(type);
            return _ember['default'].String.pluralize(dasherized);
        },
        urlForQuery: function urlForQuery(query, modelName) {
            switch (modelName) {
                case 'picture':
                    return _whiteboard2FrontendApp['default'].API_HOST + '/api/rooms/' + _whiteboard2FrontendApp['default'].chosenRoomId + '/pictures';
                case 'video':
                    return _whiteboard2FrontendApp['default'].API_HOST + '/api/rooms/' + _whiteboard2FrontendApp['default'].chosenRoomId + '/videos';
                default:
                    return this._super.apply(this, arguments);
            }
        }
    });
});