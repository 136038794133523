define('whiteboard2-frontend/models/video', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        url: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        created: _emberData['default'].attr('number'),
        filesize: _emberData['default'].attr('number'),
        user: _emberData['default'].belongsTo('user'),
        room: _emberData['default'].attr(),

        jwtUrl: null,
        jwtThumbnailUrl: null,
        selected: false
    });
});