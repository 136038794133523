define('whiteboard2-frontend/conference/administration/rooms/room/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.rooms.room', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        afterModel: function afterModel(model) {
            var rooms = this.modelFor('conference.administration.rooms');
            rooms.forEach(function (room) {
                room.set('selected', false);
            });
            model.set('selected', true);
        },

        deactivate: function deactivate() {
            var rooms = this.modelFor('conference.administration.rooms');
            rooms.forEach(function (room) {
                room.set('selected', false);
            });
        }
    });
});