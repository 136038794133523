define('whiteboard2-frontend/conference/videos/record/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        videoToDelete: null,
        queryParams: ['page'],
        page: 1,
        sliderListening: false,
        thisVideo: null,
        videoRun: false,
        videoInterval: null,
        intervalCount: 0,
        videoSpeed: 100,
        videoMute: false,

        videoDuration: _ember['default'].computed('intervalCount', function () {
            var thisVideo = this.get('thisVideo');
            if (thisVideo) {
                if (thisVideo.paused) {
                    this.set('videoRun', false);
                } else {
                    this.set('videoRun', true);
                }
                return Math.round(thisVideo.duration * 100) / 100;
            }
        }),

        videoCurrentTime: _ember['default'].computed('intervalCount', function () {
            var thisVideo = this.get('thisVideo');
            if (thisVideo) {
                return Math.round(thisVideo.currentTime * 100) / 100;
            }
        }),

        videoSpeedChanged: function videoSpeedChanged() {
            var videoSpeed = parseInt(document.getElementById('videoSpeedRange').value);
            this.set('videoSpeed', videoSpeed);
            var thisVideo = this.get('thisVideo');
            thisVideo.playbackRate = videoSpeed / 100;
        },

        actions: {

            selectThisVideo: function selectThisVideo(video) {
                var that = this;
                var oldVideoInterval = this.get('videoInterval');
                clearInterval(oldVideoInterval);
                var model = this.get('model');
                model.forEach(function (thisVideo) {
                    thisVideo.set('selected', false);
                });

                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                var videoJwtUrl = video.get('url') + "?jwt=Bearer " + lsat;
                video.set('jwtUrl', videoJwtUrl);
                video.set('selected', true);
                this.set('selectedVideo', video);
                _ember['default'].run.later(this, function () {
                    var videoPanelBodyWidth = $('#videoPanelBody').width();
                    $('#videoElement').width(videoPanelBodyWidth);
                    var videoElement = document.getElementById('videoElement');
                    videoElement.load();
                    videoElement.preload = "auto";
                    that.set('thisVideo', videoElement);

                    var sliderListening = this.get('sliderListening');
                    if (!sliderListening) {
                        this.set('sliderListening', true);
                        var valueSlider = document.getElementById('videoSpeedRange');
                        valueSlider.value = 100;
                        this.set('videoSpeed', 100);
                        valueSlider.addEventListener('change', function () {
                            that.videoSpeedChanged();
                        });
                    }
                    var videoMute = videoElement.muted;
                    this.set('videoMute', videoMute);
                }, this, 110);
                this.set('intervalCount', 0);
                var videoInterval = setInterval(function () {
                    var intervalCount = that.get('intervalCount');
                    that.set('intervalCount', intervalCount + 1);
                }, 100);
                this.set('videoInterval', videoInterval);
            },

            downloadThisVideo: function downloadThisVideo(video) {
                var videoName = video.get('name');
                var downloadName = videoName + ".webm";
                var dataURL = video.get('jwtUrl');
                var link = document.createElement("a");
                link.setAttribute("href", dataURL);
                link.setAttribute("download", downloadName);
                link.click();
            },

            deleteThisVideo: function deleteThisVideo(video) {
                this.set('videoToDelete', video);
                $('#modalDeleteVideo').modal('show');
            },

            deleteVideoAction: function deleteVideoAction() {
                var video = this.get('videoToDelete');
                video.destroyRecord();
                this.set('videoToDelete', null);
                this.set('selectedVideo', null);
            },

            prevPage: function prevPage() {
                this.set('actionMode', 4);
                var page = this.get('page');
                this.set('page', page - 1);
            },

            nextPage: function nextPage() {
                this.set('actionMode', 6);
                var page = this.get('page');
                this.set('page', page + 1);
            },

            restartVideo: function restartVideo() {
                document.getElementById('buttonRestartVideo').blur();
                var thisVideo = this.get('thisVideo');
                thisVideo.currentTime = 0;
            },

            playPauseVideo: function playPauseVideo() {
                document.getElementById('buttonPlayPauseVideo').blur();
                var thisVideo = this.get('thisVideo');
                if (thisVideo.paused) {
                    thisVideo.play();
                    this.set('videoRun', true);
                } else {
                    thisVideo.pause();
                    this.set('videoRun', false);
                }
            },

            normalSpeedVideo: function normalSpeedVideo() {
                document.getElementById('buttonNormalSpeedVideo').blur();
                document.getElementById('videoSpeedRange').value = 100;
                this.set('videoSpeed', 100);
                var thisVideo = this.get('thisVideo');
                thisVideo.playbackRate = 1;
            },

            toggleMuteVideo: function toggleMuteVideo() {
                document.getElementById('buttonMuteVideo').blur();
                var videoMute = this.get('videoMute');
                this.set('videoMute', !videoMute);
                var thisVideo = this.get('thisVideo');
                thisVideo.muted = !videoMute;
            }
        }
    });
});