define('whiteboard2-frontend/components/show-image/component', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Component.extend({

        didRender: function didRender() {
            this._super.apply(this, arguments);
            var that = this;
            var imageURL = this.get('imageURL');
            if (imageURL) {
                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                var imageSrc = _whiteboard2FrontendApp['default'].API_HOST + imageURL + "?jwt=Bearer " + lsat;
                that.set('imageSrc', imageSrc);
            }
        }
    });
});