define('whiteboard2-frontend/conference/checklist/new/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicRTCRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        },

        model: function model() {
            var chosenRoomId = _whiteboard2FrontendApp['default'].chosenRoomId;
            return this.get('store').findRecord('room', chosenRoomId);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            this.get('store').findAll('checklist-template').then(function (checklistTemplates) {
                checklistTemplates.forEach(function (thisTemplate) {
                    thisTemplate.set('selected', false);
                });
                if (checklistTemplates) {
                    var firstTemplate = checklistTemplates.get('firstObject');
                    firstTemplate.set('selected', true);
                    controller.set('selectedTemplate', firstTemplate);
                    var firstTemplateEntries = firstTemplate.get('entries');
                    firstTemplateEntries.forEach(function (thisEntry) {
                        thisEntry.set('selected', false);
                    });
                    if (firstTemplateEntries) {
                        var firstTemplateFirstEntry = firstTemplateEntries.get('firstObject');
                        firstTemplateFirstEntry.set('selected', true);
                        controller.set('selectedTemplateEntry', firstTemplateFirstEntry);
                        controller.set('checklistTemplates', checklistTemplates);
                    }
                }
            });
        }
    });
});