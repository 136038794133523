define('whiteboard2-frontend/components/pdf-view/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        pdfViewScale: null,
        pdfNumPages: null,
        pdfActPage: 1,
        pdfPrevPage: 1,
        pageRendering: false,
        pageNumPending: null,
        isError: false,
        thatError: 'Error',
        usedPdf: null,

        pageToLoadChanged: _ember['default'].observer('pageToLoad', function () {
            var pdfActPage = this.get('pdfActPage');
            this.set('pdfPrevPage', pdfActPage);
            var toThatPage = Number(this.get('pageToLoad'));
            this.set('pdfActPage', toThatPage);
            this.queueRenderPage(toThatPage);
            this.set('toThatPage', null);
        }),

        init: function init() {
            this._super.apply(this, arguments);
            // URL of PDF document
            var password = this.get('password');
            this.set('pageRendering', true);
            var url = this.get('pdfUrl');
            this.set('pdfViewScale', 1.5);
            PDFJS.workerSrc = "/assets/pdf.worker.js";
            this.showPdf(url, password);
            /*
            
                    function progressCB (event){
                        let total = event.total;
                        let loaded = event.loaded;
                        let loadedPercent = (100/total)*loaded;
                        that.set('valueNow',loadedPercent);
                        pdfProgressBar.setAttribute("style", "width:"+loadedPercent+"%");
                    }
            
             */
        },

        showPdf: function showPdf(url, password) {
            var that = this;
            var pdfProgressBarContainer = document.getElementById('pdfProgressBarContainer');
            var pdfProgressBar = document.getElementById('pdfProgressBar');
            pdfProgressBarContainer.setAttribute("style", "visibility:visible;height:14px;margin:5px 0 5px 0;");

            function progressCB(event) {
                var total = event.total;
                var loaded = event.loaded;
                var loadedPercent = 100 / total * loaded;
                that.set('valueNow', loadedPercent);
                pdfProgressBar.setAttribute("style", "width:" + loadedPercent + "%");
            }

            PDFJS.getDocument({ url: url, password: password }, false, password, progressCB).then(function (pdf) {
                that.set('usedPdf', pdf);
                that.loadThisPdf();
            })['catch'](function (error) {
                if (error.name === 'PasswordException') {
                    $('#pdfPasswordModal').modal('show');
                }
            });
        },

        loadThisPdf: function loadThisPdf() {
            var that = this;
            var pdf = this.get('usedPdf');
            var pdfProgressBarContainer = document.getElementById('pdfProgressBarContainer');
            var pdfProgressBar = document.getElementById('pdfProgressBar');
            pdfProgressBar.setAttribute("style", "width:0");
            pdfProgressBarContainer.setAttribute("style", "visibility:hidden;height:0;margin-bottom:0;");
            // Get div#container and cache it for later use
            var container = document.getElementById("pdfContainer");
            this.set('pdfNumPages', pdf.numPages);

            var pdfViewScale = this.get('pdfViewScale');
            // Get desired page
            pdf.getPage(1).then(function (page) {

                var viewport = page.getViewport(pdfViewScale);
                var div = document.createElement("div");

                // Set id attribute with page-#{pdf_page_number} format
                div.setAttribute("id", "page-" + (page.pageIndex + 1));

                // This will keep positions of child elements as per our needs
                div.setAttribute("style", "position: relative; margin-bottom:10px;");

                // Append div within div#container
                container.appendChild(div);

                // Create a new Canvas element
                var canvas = document.createElement("canvas");

                // Append Canvas within div#page-#{pdf_page_number}
                div.appendChild(canvas);

                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                // Render PDF page
                page.render(renderContext).then(function () {
                    // Get text-fragments
                    return page.getTextContent();
                }).then(function (textContent) {
                    // Create div which will hold text-fragments
                    var textLayerDiv = document.createElement("div");

                    // Set it's class to textLayer which have required CSS styles
                    textLayerDiv.setAttribute("class", "textLayer");

                    // Append newly created div in `div#page-#{pdf_page_number}`
                    div.appendChild(textLayerDiv);

                    // Create new instance of TextLayerBuilder class
                    var textLayer = new TextLayerBuilder({
                        textLayerDiv: textLayerDiv,
                        pageIndex: page.pageIndex,
                        viewport: viewport
                    });

                    // Set text-fragments
                    textLayer.setTextContent(textContent);

                    // Render text-fragments
                    textLayer.render();

                    that.set('pageRendering', false);
                });
            });
        },

        renderPage: function renderPage(pageNum) {
            var pageToLoad = pageNum;
            if (pageNum === undefined) {
                pageToLoad = this.get('pdfActPage');
            }
            var pdf = this.get('usedPdf');
            var pdfViewScale = this.get('pdfViewScale');
            var that = this;
            if (pageToLoad > pdf.numPages) {
                that.set('thatError', "Error: page doesn't exist");
                that.set('isError', true);
                var pdfPrevPage = that.get('pdfPrevPage');
                that.set('pdfActPage', pdfPrevPage);
                return;
            }
            if (pageToLoad <= 0) {
                that.set('thatError', 'Error: no page 0');
                that.set('isError', true);
                var pdfPrevPage = that.get('pdfPrevPage');
                that.set('pdfActPage', pdfPrevPage);
                return;
            }
            if (isNaN(pageToLoad)) {
                that.set('thatError', 'Error: not a number');
                that.set('isError', true);
                var pdfPrevPage = that.get('pdfPrevPage');
                that.set('pdfActPage', pdfPrevPage);
                return;
            }
            var container = document.getElementById("pdfContainer");
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
            var PdfPageHeight = 100;
            // Get desired page
            pdf.getPage(pageToLoad).then(function (page) {

                var viewport = page.getViewport(pdfViewScale);
                var div = document.createElement("div");
                /*
                 if (i = 1){
                 PdfPageHeight = viewport.height;
                 console.log('PdfPageHeight: ',PdfPageHeight);
                 }
                 */
                // Set id attribute with page-#{pdf_page_number} format
                div.setAttribute("id", "page-" + (page.pageIndex + 1));

                // This will keep positions of child elements as per our needs
                div.setAttribute("style", "position: relative; margin-bottom:10px;");

                // Append div within div#container
                container.appendChild(div);

                // Create a new Canvas element
                var canvas = document.createElement("canvas");

                // Append Canvas within div#page-#{pdf_page_number}
                div.appendChild(canvas);

                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                document.getElementById('main-pdf-container').style.height = PdfPageHeight;
                // Render PDF page
                page.render(renderContext).then(function () {
                    // Get text-fragments
                    return page.getTextContent();
                }).then(function (textContent) {
                    // Create div which will hold text-fragments
                    var textLayerDiv = document.createElement("div");

                    // Set it's class to textLayer which have required CSS styles
                    textLayerDiv.setAttribute("class", "textLayer");

                    // Append newly created div in `div#page-#{pdf_page_number}`
                    div.appendChild(textLayerDiv);

                    // Create new instance of TextLayerBuilder class
                    var textLayer = new TextLayerBuilder({
                        textLayerDiv: textLayerDiv,
                        pageIndex: page.pageIndex,
                        viewport: viewport
                    });

                    // Set text-fragments
                    textLayer.setTextContent(textContent);

                    // Render text-fragments
                    textLayer.render();

                    that.set('pageRendering', false);

                    var pageNumPending = that.get('pageNumPending');
                    if (pageNumPending !== null) {
                        // New page rendering is pending
                        this.renderPage(pageNumPending);
                        that.set('pageNumPending', null);
                    }
                    that.set('isError', false);
                });
            });
        },

        queueRenderPage: function queueRenderPage(num) {
            var pageRendering = this.get('pageRendering');
            if (pageRendering) {
                this.set('pageRendering', num);
            } else {
                this.renderPage(num);
            }
        },

        actions: {
            pushPassword: function pushPassword() {
                var passwordEntered = this.get('passwordEntered');
                this.set('password', passwordEntered);
                var url = this.get('pdfUrl');
                $('#pdfPasswordModal').modal('hide');
                this.showPdf(url, passwordEntered);
            },
            smallerView: function smallerView() {
                var pdfViewScale = this.get('pdfViewScale');
                if (pdfViewScale > 0.25) {
                    this.set('pdfViewScale', pdfViewScale - 0.25);
                    this.renderPage();
                }
                document.getElementById("pdfButtonSmaller").blur();
            },
            biggerView: function biggerView() {
                var pdfViewScale = this.get('pdfViewScale');
                if (pdfViewScale < 5) {
                    this.set('pdfViewScale', pdfViewScale + 0.25);
                    this.renderPage();
                }
                document.getElementById("pdfButtonBigger").blur();
            },
            firstPage: function firstPage() {
                var pdfActPage = this.get('pdfActPage');
                document.getElementById("pdfButtonFirs").blur();
                this.set('pdfPrevPage', pdfActPage);
                this.set('pdfActPage', 1);
                this.queueRenderPage(1);
            },
            prevPage: function prevPage() {
                var pdfActPage = this.get('pdfActPage');
                document.getElementById("pdfButtonPrev").blur();
                if (pdfActPage <= 1) {
                    return;
                }
                this.set('pdfPrevPage', pdfActPage);
                pdfActPage--;
                this.set('pdfActPage', pdfActPage);
                this.queueRenderPage(pdfActPage);
            },
            nextPage: function nextPage() {
                var pdfNumPages = this.get('pdfNumPages');
                var pdfActPage = this.get('pdfActPage');
                document.getElementById("pdfButtonNext").blur();
                if (pdfActPage >= pdfNumPages) {
                    return;
                }
                this.set('pdfPrevPage', pdfActPage);
                pdfActPage++;
                this.set('pdfActPage', pdfActPage);
                this.queueRenderPage(pdfActPage);
            },
            lastPage: function lastPage() {
                var pdfNumPages = this.get('pdfNumPages');
                var pdfActPage = this.get('pdfActPage');
                document.getElementById("pdfButtonLast").blur();
                this.set('pdfPrevPage', pdfActPage);
                this.set('pdfActPage', pdfNumPages);
                this.queueRenderPage(pdfNumPages);
            },
            gotoThatPage: function gotoThatPage() {
                var pdfActPage = this.get('pdfActPage');
                this.set('pdfPrevPage', pdfActPage);
                var toThatPage = Number(this.get('toThatPage'));
                this.set('pdfActPage', toThatPage);
                this.queueRenderPage(toThatPage);
                this.set('toThatPage', null);
                document.getElementById("pdfButtonGoTo").blur();
            }
        }
    });
});