define('whiteboard2-frontend/conference/doclib/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        init: function init() {
            var that = this;
            window.addEventListener('resize', function () {
                that.resizeWindow();
            });
        },

        resizeWindow: function resizeWindow() {
            var w = window.innerHeight - 72;
            var c = document.getElementById('we-iframe-container');
            if (c) {
                c.style.height = w + "px";
            }
        }
    });
});