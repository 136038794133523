define('whiteboard2-frontend/registration/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            sendUserData: function sendUserData() {
                var that = this;
                var token = window.location.search.substring(1);
                var tokenValue = token.replace('token=', '');
                var firstName = this.get('firstName');
                var lastName = this.get('lastName');
                var gender = this.get('gender');
                var phone = this.get('phone');
                var title = this.get('title');
                var addInfo = this.get('addInfo');
                var firstPassword = this.get('firstPassword');
                var confPassword = this.get('confPassword');
                var userData = {
                    'token': tokenValue,
                    'firstName': firstName,
                    'lastName': lastName,
                    'gender': gender,
                    'phone': phone,
                    'academicTitle': title,
                    'additionalInfo': addInfo,
                    'password': firstPassword,
                    'confirmPassword': confPassword
                };
                $.ajax({
                    type: 'POST',
                    url: _whiteboard2FrontendApp['default'].API_HOST + "/api/register",
                    data: JSON.stringify(userData),
                    contentType: 'application/json',
                    processData: false,
                    success: function success(data) {
                        if (typeof data.error === 'undefined') {
                            that.transitionToRoute('login');
                        } else {
                            console.log('error sent: ', data);
                        }
                    },
                    error: function error() {
                        // Handle errors here
                    }
                });
            }
        }
    });
});