define('whiteboard2-frontend/conference/checklist/template/edit/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        i18n: _ember['default'].inject.service(),
        queryParams: ['page'],
        page: 1,
        actionMode: 0,

        selectedTemplate: null,
        selectedEntry: null,
        selectedDetail: null,
        componentToEdit: null,

        actions: {

            selectThisChecklistTemplate: function selectThisChecklistTemplate(checklist) {
                var checklistTemplates = this.get('model');
                checklistTemplates.forEach(function (thisTemplate) {
                    thisTemplate.set('selected', false);
                });
                this.set('selectedTemplate', checklist);
                var oldEntry = this.get('selectedEntry');
                if (oldEntry) {
                    oldEntry.set('selected', false);
                }
                this.set('selectedEntry', null);
                checklist.set('selected', true);
            },

            selectThisEntry: function selectThisEntry(entry) {
                var checklist = this.get('selectedTemplate');
                var thisTemplateEntries = checklist.get('entries');
                thisTemplateEntries.forEach(function (thisEntry) {
                    thisEntry.set('selected', false);
                });
                var oldEntry = this.get('selectedEntry');
                if (oldEntry) {
                    oldEntry.set('selected', false);
                }
                this.set('selectedEntry', entry);
                entry.set('selected', true);
            },

            newChecklistTemplate: function newChecklistTemplate() {
                $('#modalNewChecklistTemplate').modal('show');
            },

            saveNewTemplate: function saveNewTemplate() {
                var that = this;
                var templateName = this.get('templateName');
                if (templateName === '') {
                    this.set('errorText', that.get('i18n').t('checklistTemplateName'));
                    $('#modalNewTemplateError').modal('show');
                    return;
                }
                this.set('actionMode', 2);
                var meta = this.get('meta');
                var metaSize = meta.size;
                var totalItems = meta.totalItems;
                var totalPages = meta.totalPages;
                if (totalItems % metaSize) {
                    this.set('page', totalPages);
                } else {
                    this.set('page', totalPages + 1);
                }

                var chosenRoomId = _whiteboard2FrontendApp['default'].chosenRoomId;

                var selectedTemplate = that.get('selectedTemplate');
                selectedTemplate.set('selected', false);
                var newChecklistTemplate = this.get('store').createRecord('checklist-template', {
                    title: templateName,
                    room: chosenRoomId,
                    selected: true
                });

                newChecklistTemplate.save().then(function (savedNewTemplate) {
                    that.set('selectedTemplate', savedNewTemplate);
                    that.set('selectedEntry', null);
                    that.send('refreshModel');
                });
                this.set('templateName', '');
                $('#modalNewChecklistTemplate').modal('hide');
            },

            editThisTemplateTitle: function editThisTemplateTitle(thisTemplate) {
                this.set('editTemplate', thisTemplate);
                this.set('componentToEdit', 'editTemplate');
                $('#modalEditTemplateTitle').modal('show');
            },

            editThisEntry: function editThisEntry(entry) {
                this.set('editTemplate', entry);
                this.set('componentToEdit', 'editEntry');
                $('#modalEditTemplateEntry').modal('show');
            },

            editThisDetail: function editThisDetail(thisDetail) {
                this.set('editTemplate', thisDetail);
                this.set('componentToEdit', 'editDetail');
                $('#modalEditTemplateDetail').modal('show');
            },

            saveTemplateValue: function saveTemplateValue(param) {
                var editTemplate = this.get('editTemplate');
                var that = this;
                if (param === 'detail') {
                    var lsat;

                    var _ret = (function () {
                        var editDetailText = editTemplate.get('text');
                        var uploadContent = undefined;
                        var fileInput = document.getElementById('edit-file');
                        var files = fileInput.files;
                        var filesTest = files.length;
                        if (filesTest > 0) {
                            uploadContent = files[0];
                            var data = new FormData();
                            data.append('file', uploadContent);
                            if (uploadContent) {
                                lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];

                                $.ajax({
                                    type: 'POST',
                                    url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                                    data: data,
                                    contentType: false,
                                    processData: false,
                                    headers: { 'Authorization': 'Bearer ' + lsat },
                                    success: function success(sessionFile) {
                                        editTemplate.set('imageURL', sessionFile.path);
                                        editTemplate.save();
                                        fileInput.files = null;
                                    },
                                    error: function error() {
                                        that.set('errorText', that.get('i18n').t('anErrorOccured'));
                                        $('#modalNewTemplateError').modal('show');
                                        fileInput.files = null;
                                    }
                                });
                            } else {
                                that.set('errorText', that.get('i18n').t('anErrorOccured'));
                                $('#modalNewTemplateError').modal('show');
                                return {
                                    v: undefined
                                };
                            }
                        } else if (!editDetailText) {
                            that.set('errorText', that.get('i18n').t('enterDetailTextOrPicture'));
                            $('#modalNewTemplateError').modal('show');
                            return {
                                v: undefined
                            };
                        } else {
                            editTemplate.save();
                        }
                    })();

                    if (typeof _ret === 'object') return _ret.v;
                } else if (param === 'entry') {
                    var templateEntryTitle = editTemplate.get('title');
                    var templateEntryDescription = editTemplate.get('description');
                    if (!templateEntryTitle) {
                        this.set('errorText', this.get('i18n').t('enterEntryTitle'));
                        $('#modalNewTemplateError').modal('show');
                        return;
                    } else if (!templateEntryDescription) {
                        this.set('errorText', this.get('i18n').t('enterEntryDescription'));
                        $('#modalNewTemplateError').modal('show');
                        return;
                    } else {
                        editTemplate.save();
                    }
                } else if (param === 'title') {
                    var templateTitle = editTemplate.get('title');
                    if (!templateTitle) {
                        this.set('errorText', this.get('i18n').t('enterTemplateTitle'));
                        $('#modalNewTemplateError').modal('show');
                        return;
                    } else {
                        editTemplate.save();
                    }
                }
                $('#modalEditTemplateDetail').modal('hide');
            },

            newTemplateEntry: function newTemplateEntry() {
                var selectedTemplate = this.get('selectedTemplate');
                if (selectedTemplate) {
                    $('#modalNewTemplateEntry').modal('show');
                } else {
                    this.set('errorText', this.get('i18n').t('createTemplateFirst'));
                    $('#modalNewTemplateError').modal('show');
                }
            },

            saveTemplateEntry: function saveTemplateEntry() {
                var _this = this;

                var that = this;
                var newEntryTitle = this.get('templateEntryTitle');
                var newEntryDescription = this.get('templateEntryDescription');
                if (!newEntryTitle) {
                    this.set('errorText', this.get('i18n').t('enterEntryTitle'));
                    $('#modalNewTemplateError').modal('show');
                    return;
                } else if (!newEntryDescription) {
                    this.set('errorText', this.get('i18n').t('enterEntryDescription'));
                    $('#modalNewTemplateError').modal('show');
                    return;
                } else {
                    (function () {
                        var thisChecklistTemplate = _this.get('selectedTemplate');
                        var highestOrder = 0;
                        var allEntries = thisChecklistTemplate.get('entries');
                        var allEntriesLength = allEntries.get("length");
                        if (allEntriesLength > 0) {
                            allEntries.forEach(function (thisEntry) {
                                var thisEntryOrder = thisEntry.get("orderValue");
                                if (thisEntryOrder >= highestOrder) {
                                    highestOrder = thisEntryOrder + 1;
                                }
                            });
                        }
                        var newChecklistTemplateEntry = _this.get('store').createRecord('checklist-entry-template', {
                            orderValue: highestOrder,
                            title: newEntryTitle,
                            description: newEntryDescription
                        });
                        thisChecklistTemplate.get('entries').pushObject(newChecklistTemplateEntry);
                        newChecklistTemplateEntry.save().then(function () {
                            thisChecklistTemplate.save();
                            that.set('templateEntryTitle', '');
                            that.set('templateEntryDescription', '');
                        });
                    })();
                }
            },

            newTemplateDetail: function newTemplateDetail() {
                var selectedEntry = this.get('selectedEntry');
                if (selectedEntry) {
                    $('#modalNewTemplateDetail').modal('show');
                } else {
                    this.set('errorText', this.get('i18n').t('selectEntry'));
                    $('#modalNewTemplateError').modal('show');
                }
            },

            saveTemplateDetail: function saveTemplateDetail() {
                var that = this;
                var chosenEntry = that.get('selectedEntry');
                var highestOrder = 0;
                var allDetails = chosenEntry.get('details');
                var allDetailsLength = allDetails.get("length");
                if (allDetailsLength > 0) {
                    allDetails.forEach(function (thisDetail) {
                        var thisDetailOrder = thisDetail.get("orderValue");
                        if (thisDetailOrder >= highestOrder) {
                            highestOrder = thisDetailOrder + 1;
                        }
                    });
                }
                var uploadContent;
                var fileInput = document.getElementById('input-file');
                var files = fileInput.files;
                var filesTest = files.length;
                if (filesTest > 0) {
                    uploadContent = files[0];
                }
                var templateDetailText = this.get('templateDetailText');
                var data = new FormData();
                data.append('file', uploadContent);
                if (uploadContent) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: data,
                        contentType: false,
                        processData: false,
                        headers: { 'Authorization': 'Bearer ' + lsat },
                        success: function success(sessionFile) {
                            var newDetail = that.get('store').createRecord('checklist-detail-template', {
                                imageURL: sessionFile.path,
                                orderValue: highestOrder,
                                text: that.get('templateDetailText')
                            });
                            chosenEntry.get('details').pushObject(newDetail);
                            newDetail.save().then(function () {
                                chosenEntry.save();
                                that.set('templateDetailText', '');
                            });
                        },
                        error: function error() {
                            this.set('errorText', this.get('i18n').t('anErrorOccured'));
                            $('#modalNewTemplateError').modal('show');
                        }
                    });
                } else if (!templateDetailText) {
                    this.set('errorText', this.get('i18n').t('enterDetailTextOrPicture'));
                    $('#modalNewTemplateError').modal('show');
                    return;
                } else {
                    var newDetail = that.get('store').createRecord('checklist-detail-template', {
                        orderValue: highestOrder,
                        text: that.get('templateDetailText')
                    });
                    chosenEntry.get('details').pushObject(newDetail);
                    newDetail.save().then(function () {
                        chosenEntry.save();
                        that.set('templateDetailText', '');
                    });
                }
            },

            deleteThisTemplate: function deleteThisTemplate() {
                $('#modalDeleteChecklistTemplate').modal('show');
            },

            reallyDeleteTemplate: function reallyDeleteTemplate() {
                var that = this;
                this.set('actionMode', 0);
                var selectedTemplate = this.get('selectedTemplate');
                this.set('selectedTemplate', null);
                this.set('selectedEntry', null);
                selectedTemplate.deleteRecord();
                selectedTemplate.save().then(function () {
                    that.send('refreshModel');
                });
            },

            deleteThisEntry: function deleteThisEntry() {
                $('#modalDeleteChecklistEntry').modal('show');
            },

            reallyDeleteEntry: function reallyDeleteEntry() {
                var selectedTemplate = this.get('selectedTemplate');
                var selectedEntry = this.get('selectedEntry');
                this.set('selectedEntry', null);
                selectedTemplate.get('entries').removeObject(selectedEntry);
                selectedTemplate.save();
                selectedEntry.destroyRecord();
            },

            deleteThisDetail: function deleteThisDetail(thisDetail) {
                this.set('selectedDetail', thisDetail);
                $('#modalDeleteChecklistDetail').modal('show');
            },

            reallyDeleteDetail: function reallyDeleteDetail() {
                var selectedDetail = this.get('selectedDetail');
                var selectedEntry = this.get('selectedEntry');
                selectedEntry.get('details').removeObject('selectedDetail');
                selectedEntry.save();
                this.set('selectedDetail', null);
                selectedDetail.destroyRecord();
            },

            dismissErrorModal: function dismissErrorModal() {
                this.set('errorText', null);
                $('#modalNewTemplateError').modal('hide');
            },

            prevPage: function prevPage() {
                this.set('actionMode', 4);
                var page = this.get('page');
                this.set('page', page - 1);
            },

            nextPage: function nextPage() {
                this.set('actionMode', 6);
                var page = this.get('page');
                this.set('page', page + 1);
            }
        }
    });
});