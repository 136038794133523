define('whiteboard2-frontend/helpers/comma-percent', ['exports', 'ember'], function (exports, _ember) {
    exports.commaPercent = commaPercent;

    function commaPercent(value) {
        var percentNumber = value * 100;
        var percentSign = '%';
        return percentNumber + ' ' + percentSign;
    }

    exports['default'] = _ember['default'].Helper.helper(commaPercent);
});