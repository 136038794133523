define('whiteboard2-frontend/conference/checklist/open/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicRTCRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        },

        model: function model() {
            return this.get('store').findAll('checklist');
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var chosenRoomId = _whiteboard2FrontendApp['default'].chosenRoomId;
            var thisRoom = this.get('store').findRecord('room', chosenRoomId);
            controller.set('thisRoom', thisRoom);
        },

        activate: function activate() {
            this.controllerFor('conference/checklist/open').send('firstRunAfterRender');
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});