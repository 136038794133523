define('whiteboard2-frontend/conference/picture/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            page: {
                refreshModel: true
            }
        },

        activate: function activate() {
            _whiteboard2FrontendApp['default'].onlinePosition = 'Pictures';
            this.controllerFor('conference/picture').send('runAfterRender');
            _ember['default'].run.later(function () {
                _whiteboard2FrontendApp['default'].conference.askOthers();
            }, 1000);
        },

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicRTCRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        },

        model: function model(params) {
            return this.get('store').query('picture', params);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('model', model);
            controller.set('meta', model.meta);
            var myId = _whiteboard2FrontendApp['default'].myId;
            model.forEach(function (thisPicture) {
                thisPicture.set('selected', false);
                var pictureUserId = parseInt(thisPicture.belongsTo("user").id());
                if (pictureUserId === myId) {
                    thisPicture.set('myPicture', true);
                }
            });
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }
    });
});