define('whiteboard2-frontend/conference/videos/live/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        i18n: _ember['default'].inject.service(),
        conference: _ember['default'].inject.controller('conference'),

        init: function init() {
            this._super();
            _whiteboard2FrontendApp['default'].video = this;
        },

        modalCloseRight: false,

        mobileDevice: false,
        audioDeviceChosen: [],
        audioDevices: [],
        videoDeviceChosen: [],
        videoDevices: [],

        mediaOptions: {
            audio: false,
            video: false
        },
        activeTrack: null,
        connState: null,
        bigVideo: null,
        webrtc: null,
        torchState: false,
        hasTorch: false,

        selfMute: false,

        oncePointer: false,
        twicePointer: false,
        realTimePointer: false,

        twicePointerNumber: 0,

        videoRecording: false,
        recordObject: null,
        tempData: null,
        recordTimeOut: null,
        mediaOfBigVideo: null,

        name: (function () {
            return _whiteboard2FrontendApp['default'].whoAmI;
        }).property('App.whoAmI'),

        localStream: null,

        dispatchEvent: function dispatchEvent(event) {
            switch (event.msgCat) {
                case 'oncePointer':
                    this.insertOncePointerWAMP(event);
                    break;
                case 'twicePointer':
                    this.insertTwicePointerWAMP(event);
                    break;
                case 'oncePointerRemove':
                    this.removeOncePointerWAMP(event);
                    break;
            }
        },

        closePanel: function closePanel(peerID) {
            var panelDivMain = document.getElementById('container_' + peerID);
            if (panelDivMain) {
                panelDivMain.style.opacity = 0;
                _ember['default'].run.later(function () {
                    var panelDivMainParent = panelDivMain.parentElement;
                    panelDivMainParent.removeChild(panelDivMain);
                }, 1500);
            }
        },

        runAfterRender: function runAfterRender() {
            var that = this;
            var audioDevices = this.get('audioDevices');
            var videoDevices = this.get('videoDevices');
            audioDevices.clear();
            videoDevices.clear();
            var mediaOptions = this.get('mediaOptions');
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                var a = 0;
                var noDevice = {
                    kind: 'videoinput',
                    deviceId: 0,
                    label: 'no Video',
                    selected: true
                };
                that.set('oncePointer', false);
                that.set('twicePointer', false);
                that.set('realTimePointer', false);
                that.set('videoDeviceChosen', noDevice);
                mediaOptions.video = false;
                videoDevices.addObject(noDevice);
                for (var i = 0; i !== devices.length; ++i) {
                    var device = devices[i];
                    if (device.kind === 'audioinput') {
                        device.selected = false;
                        audioDevices.addObject(device);
                        if (a === 0) {
                            a = 1;
                            mediaOptions.audio = {
                                deviceId: device.deviceId
                            };
                            device.selected = true;
                            that.set('audioDeviceChosen', device);
                        }
                    } else if (device.kind === 'videoinput') {
                        device.selected = false;
                        videoDevices.addObject(device);
                    }
                }
                var modalChooseDevices = $('#modalChooseDevices');
                modalChooseDevices.modal('show');
                modalChooseDevices.on('hide.bs.modal', function (e) {
                    var modalCloseRight = that.get('modalCloseRight');
                    if (!modalCloseRight) {
                        that.transitionToRoute('conference.helpscreen');
                    }
                });
            });
        },

        dataURItoBlob: function dataURItoBlob(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var bb = new Blob([ab], {
                "type": mimeString
            });
            return bb;
        },

        switchLight: function switchLight(mode) {
            var _this = this;

            var torchState = false;
            if (mode === "1") {
                torchState = true;
            } else if (mode === "2") {
                torchState = !this.get('torchState');
            }
            var activeTrack = this.get('activeTrack');
            if (activeTrack) {
                activeTrack.applyConstraints({
                    advanced: [{ torch: torchState }]
                });
                this.set('torchState', torchState);
            } else if (mode === "0") {
                return;
            } else {
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    for (var i = 0; i !== devices.length; ++i) {
                        var device = devices[i];
                        if (device.label === 'camera2 0, facing back' && device.kind === 'videoinput') {
                            navigator.mediaDevices.getUserMedia({
                                video: {
                                    deviceId: device.deviceId,
                                    facingMode: ['user', 'environment'],
                                    height: { ideal: 1080 },
                                    width: { ideal: 1920 }
                                }
                            }).then(function (stream) {
                                var track = stream.getVideoTracks()[0];
                                var imageCapture = new ImageCapture(track);
                                var photoCapabilities = imageCapture.getPhotoCapabilities().then(function () {
                                    var torchState = !_this.get('torchState');
                                    track.applyConstraints({
                                        advanced: [{ torch: torchState }]
                                    });
                                    _this.set('torchState', torchState);
                                    _this.set('activeTrack', track);
                                });
                            });
                        }
                    }
                });
            }
        },

        actions: {

            sourceChoosen: function sourceChoosen() {
                this.set('modalCloseRight', true);
                $('#modalChooseDevices').modal('hide');
                this.send('comeIn');
            },

            abortSelectSource: function abortSelectSource() {
                this.set('modalCloseRight', false);
                $('#modalChooseDevices').modal('hide');
            },

            rechooseDevices: function rechooseDevices() {
                var webrtc = this.get('webrtc');
                webrtc.stopLocalVideo();
                webrtc.leaveRoom();
                webrtc.connection.disconnect();
                this.set('webrtc', null);
                this.runAfterRender();
            },

            firstRunAfterRender: function firstRunAfterRender() {
                var that = this;
                if (_whiteboard2FrontendApp['default'].userAgent === 'Mobile') {
                    that.set('mobileDevice', true);
                }
                this.runAfterRender();
            },

            changeVideoSource: function changeVideoSource(videoDevice) {
                this.set('videoDeviceChosen.selected', false);
                var mediaOptions = this.get('mediaOptions');
                if (videoDevice.deviceId === 0) {
                    mediaOptions.video = false;
                } else {
                    mediaOptions.video = {
                        deviceId: videoDevice.deviceId,
                        width: 1280,
                        heigth: 720
                    };
                }
                if (videoDevice.label === 'camera2 0, facing back') {
                    this.set('hasTorch', true);
                } else {
                    this.set('hasTorch', false);
                }
                this.set('videoDeviceChosen', videoDevice);
                this.set('videoDeviceChosen.selected', true);
            },
            changeAudioSource: function changeAudioSource(audioDevice) {
                this.set('audioDeviceChosen.selected', false);
                var mediaOptions = this.get('mediaOptions');
                mediaOptions.audio = {
                    deviceId: audioDevice.deviceId
                };
                this.set('audioDeviceChosen', audioDevice);
                this.set('audioDeviceChosen.selected', true);
            },

            comeIn: function comeIn() {
                var that = this;
                var mediaOptions = this.get('mediaOptions');
                var mediaOptionsVideo = mediaOptions.video;
                if (mediaOptionsVideo === false) {
                    var localVidBody = document.getElementById('localVideoPanelBody');
                    var test3 = localVidBody.childNodes[3];
                    if (test3 !== undefined) {
                        localVidBody.removeChild(test3);
                    }
                    var noVideoText = document.createTextNode(this.get('i18n').t('noVideoDevice'));
                    localVidBody.appendChild(noVideoText);
                    var _localVideo = document.getElementById('localVideo');
                    _localVideo.style.height = 10 + 'px';
                }
                var mobileDevice = this.get('mobileDevice');
                var mainVideoFrame = null;
                if (mobileDevice) {
                    mainVideoFrame = $('#localVideoPanelBody');
                } else {
                    mainVideoFrame = $('#mainVideo');
                }
                var mainVideoWidth = mainVideoFrame.width();
                var mainVideoHeight = mainVideoFrame.height();
                var mainVidCanvas = document.getElementById('mainVidCanvas');
                mainVidCanvas.height = mainVideoHeight;
                mainVidCanvas.width = mainVideoWidth;
                var localVideo = document.getElementById('localVideo');
                localVideo.width = mainVideoWidth;

                var webrtc = new SimpleWebRTC({
                    localVideoEl: 'localVideo',
                    remoteVideosEl: '',
                    autoRequestMedia: true,
                    debug: false, //false
                    detectSpeakingEvents: true, //true
                    autoAdjustMic: true,
                    nick: this.get('name'),
                    url: _whiteboard2FrontendApp['default'].API_HOST,
                    media: mediaOptions,
                    socketio: {
                        "force new connection": true
                    },
                    localVideo: {
                        mirror: false
                    }
                });

                this.set('webrtc', webrtc);

                var room = _whiteboard2FrontendApp['default'].topicRTCRoom;
                webrtc.on('readyToCall', function () {
                    if (room) {
                        webrtc.joinRoom(room);
                    }
                });

                // we got access to the camera
                webrtc.on('localStream', function () {
                    var button = document.querySelector('form>button');
                    if (button) {
                        button.removeAttribute('disabled');
                    }
                });
                // we did not get access to the camera
                webrtc.on('localMediaError', function () {
                    alert('Sie müssen für diese Funktion ihre Kamera freigeben');
                });

                // local screen obtained
                webrtc.on('localScreenAdded', function (video) {
                    video.onclick = function () {};
                    document.getElementById('localScreenContainer').appendChild(video);
                    $('#localScreenContainer').show();
                });
                // local screen removed
                webrtc.on('localScreenRemoved', function (video) {
                    document.getElementById('localScreenContainer').removeChild(video);
                    $('#localScreenContainer').hide();
                });

                // a peer video has been added
                webrtc.on('videoAdded', function (video, peer) {
                    var remotes = document.getElementById('remotes');
                    if (remotes) {
                        var connState = document.createElement('div');
                        connState.className = 'connectionstate';
                        // show the ice connection state
                        if (peer && peer.pc) {
                            peer.pc.on('iceConnectionStateChange', function () {
                                switch (peer.pc.iceConnectionState) {
                                    case 'checking':
                                        connState.innerText = peer.nick + that.get('i18n').t('connectingToPeer');
                                        break;
                                    case 'connected':
                                    case 'completed':
                                        // on caller side
                                        connState.innerText = peer.nick + that.get('i18n').t('connectionEstablished');
                                        break;
                                    case 'disconnected':
                                        connState.innerText = peer.nick + that.get('i18n').t('disconnected');
                                        _ember['default'].run.later(function () {
                                            that.closePanel(webrtc.getDomId(peer));
                                        }, 1000);
                                        break;
                                    case 'failed':
                                        _ember['default'].run.later(function () {
                                            that.closePanel(webrtc.getDomId(peer));
                                        }, 1000);
                                        break;
                                    case 'closed':
                                        connState.innerText = peer.nick + that.get('i18n').t('disconnected');
                                        _ember['default'].run.later(function () {
                                            that.closePanel(webrtc.getDomId(peer));
                                        }, 1000);
                                        break;
                                }
                            });
                        }

                        var vol = document.createElement('meter');
                        vol.id = 'volume_' + peer.id;
                        vol.className = 'volume';
                        vol.min = -45;
                        vol.max = -20;
                        vol.low = -40;
                        vol.high = -25;

                        var panelDivMain = document.createElement('div');
                        panelDivMain.className = 'panel panel-primary remoteVideoPanel';
                        panelDivMain.id = 'container_' + webrtc.getDomId(peer);
                        var panelDivHeader = document.createElement('div');
                        panelDivHeader.className = 'panel-heading';
                        panelDivHeader.appendChild(connState);
                        var panelDivBody = document.createElement('div');
                        panelDivBody.className = 'panel-body';
                        panelDivMain.appendChild(panelDivHeader);
                        var container = document.createElement('div');
                        container.className = 'videoContainer';
                        container.id = 'videoContainer_' + peer.id;
                        var localVideoWidth = $('#localVideo').width();
                        container.style.width = localVideoWidth + 'px';
                        container.style.height = localVideoWidth * 0.75 + 'px';
                        container.appendChild(vol);
                        container.appendChild(video);
                        panelDivBody.appendChild(container);
                        panelDivMain.appendChild(panelDivBody);
                        remotes.appendChild(panelDivMain);
                        // suppress contextmenu
                        video.oncontextmenu = function () {
                            return false;
                        };

                        var _mobileDevice = that.get('mobileDevice');
                        if (!_mobileDevice) {
                            // resize the video on click
                            video.onclick = function (thisEvent) {
                                var jMainVideo = $('#mainVideo');
                                var mainVideoChildren = jMainVideo.children();
                                var bigVideoId = that.get('bigVideo');
                                var oncePointer = that.get('oncePointer');
                                var twicePointer = that.get('twicePointer');
                                var realTimePointer = that.get('realTimePointer');
                                var mainVidCanvas = document.getElementById('mainVidCanvas');
                                var thisCtx = mainVidCanvas.getContext('2d');
                                if (mainVideoChildren.length > 0) {
                                    var thatEventLayerX = undefined,
                                        thatEventLayerY = undefined;
                                    if (thisEvent === undefined) {
                                        thatEventLayerX = that.get('thatEventLayerX') - 15;
                                        thatEventLayerY = that.get('thatEventLayerY');
                                    } else {
                                        thatEventLayerX = thisEvent.layerX - 15;
                                        thatEventLayerY = thisEvent.layerY;
                                    }
                                    if (oncePointer) {
                                        thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                                        var greenArrow = new Image();
                                        greenArrow.src = "/assets/images/greenArrowRight.png";
                                        thisCtx.drawImage(greenArrow, thatEventLayerX - 100, thatEventLayerY - 50, 100, 100);

                                        var mainVidCanvasScaleWidth = 100 / mainVidCanvas.width * thatEventLayerX;
                                        var mainVidCanvasScaleHeight = 100 / mainVidCanvas.height * thatEventLayerY;
                                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicRTCRoom, [], {
                                            msgCat: 'oncePointer',
                                            x: mainVidCanvasScaleWidth,
                                            y: mainVidCanvasScaleHeight,
                                            vidId: bigVideoId
                                        });
                                        that.set('twicePointerNumber', 0);
                                    } else if (twicePointer) {
                                        var twicePointerNumber = that.get('twicePointerNumber');
                                        var numberToShow = 0;
                                        switch (twicePointerNumber) {
                                            case 0:
                                                thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                                                numberToShow = 1;
                                                break;
                                            case 1:
                                                numberToShow = 2;
                                                break;
                                            case 2:
                                                numberToShow = 3;
                                                break;
                                            case 3:
                                                thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                                                numberToShow = 0;
                                                break;
                                        }
                                        that.set('twicePointerNumber', numberToShow);
                                        if (numberToShow > 0) {
                                            thisCtx.shadowBlur = 10;
                                            thisCtx.shadowColor = "white";
                                            thisCtx.beginPath();
                                            thisCtx.arc(thatEventLayerX, thatEventLayerY, 20, 0, 2 * Math.PI);
                                            thisCtx.font = "30px Arial";
                                            thisCtx.fillText(numberToShow, thatEventLayerX - 5, thatEventLayerY + 10);
                                            thisCtx.stroke();
                                        }

                                        var mainVidCanvasScaleWidth = 100 / mainVidCanvas.width * thatEventLayerX;
                                        var mainVidCanvasScaleHeight = 100 / mainVidCanvas.height * thatEventLayerY;
                                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicRTCRoom, [], {
                                            msgCat: 'twicePointer',
                                            x: mainVidCanvasScaleWidth,
                                            y: mainVidCanvasScaleHeight,
                                            number: numberToShow,
                                            vidId: bigVideoId
                                        });
                                    } else if (realTimePointer) {
                                        console.log('realTimePointer clicked');
                                    } else {
                                        thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                                        var bigVideoElement = document.getElementById(bigVideoId + '_video_incoming');
                                        var videoPanelBody = document.getElementById('videoContainer_' + bigVideoId);
                                        if (videoPanelBody) {
                                            videoPanelBody.removeChild(videoPanelBody.firstChild);
                                            videoPanelBody.appendChild(bigVideoElement);
                                        }
                                        bigVideoElement.play();
                                        mainVideoChildren.remove();
                                        that.set('twicePointerNumber', 0);
                                        that.set('mediaOfBigVideo', null);
                                    }
                                }
                                if (bigVideoId !== peer.id) {
                                    that.set('mediaOfBigVideo', peer.stream);
                                    var videoRatio = video.videoHeight / video.videoWidth;
                                    var _mainVideoWidth = jMainVideo.width();
                                    var _mainVideoHeight = _mainVideoWidth * videoRatio;
                                    var mainVideo = document.getElementById('mainVideo');
                                    var bigContainer = document.createElement('div');
                                    bigContainer.className = 'bigVideoContainer';
                                    bigContainer.style.width = _mainVideoWidth + 'px';
                                    bigContainer.style.height = _mainVideoHeight + 'px';
                                    bigContainer.appendChild(video);
                                    mainVideo.appendChild(bigContainer);
                                    that.set('bigVideo', peer.id);
                                    var oldVideoPanelBody = document.getElementById('videoContainer_' + peer.id);
                                    if (oldVideoPanelBody) {
                                        oldVideoPanelBody.innerText = that.get('i18n').t('videoMoved');
                                    }
                                    video.play();
                                    that.set('bigVideoElement', video);
                                    mainVidCanvas.height = _mainVideoHeight;
                                    mainVidCanvas.width = _mainVideoWidth;
                                    mainVidCanvas.style.padding = "0 15px";
                                    mainVidCanvas.onclick = function (thatEvent) {
                                        that.set('thatEventLayerX', thatEvent.layerX);
                                        that.set('thatEventLayerY', thatEvent.layerY);
                                        video.onclick();
                                    };
                                } else if (oncePointer || twicePointer || realTimePointer) {
                                    console.log('special active');
                                } else {
                                    that.set('bigVideo', null);
                                }
                            };
                        } else {
                            video.onclick = function (thisEvent) {
                                var mainVideoFrame = $('#localVideoPanelBody');
                                //TODO: Tablet Arrow onTouch
                            };
                        }
                    }
                });

                // local volume has changed
                webrtc.on('volumeChange', function (volume) {
                    showVolume(document.getElementById('localVolume'), volume);
                });

                // remote volume has changed
                webrtc.on('remoteVolumeChange', function (peer, volume) {
                    showVolume(document.getElementById('volume_' + peer.id), volume);
                });

                // a peer was removed
                webrtc.on('videoRemoved', function (video, peer) {
                    var remotes = document.getElementById('remotes');
                    var el = document.getElementById(peer ? 'container_' + webrtc.getDomId(peer) : 'localScreenContainer');
                    if (remotes && el) {
                        remotes.removeChild(el);
                    }
                });

                // local p2p/ice failure
                webrtc.on('iceFailed', function (peer) {
                    var connstate = document.querySelector('#container_' + webrtc.getDomId(peer) + ' .connectionstate');
                    if (connstate) {
                        connstate.innerText = that.get('i18n').t('connectionFailed');
                    }
                });

                // remote p2p/ice failure
                webrtc.on('connectivityError', function (peer) {
                    var connstate = document.querySelector('#container_' + webrtc.getDomId(peer) + ' .connectionstate');
                    if (connstate) {
                        connstate.innerText = that.get('i18n').t('connectionFailed');
                    }
                });

                function showVolume(el, volume) {
                    if (!el) {
                        return;
                    }
                    if (volume < -45) {
                        volume = -45;
                    }
                    if (volume > -20) {
                        volume = -20;
                    }
                    el.value = volume;
                }

                this.set('modalCloseRight', false);
            },

            toggleSelfMute: function toggleSelfMute() {
                var selfMute = this.get('selfMute');
                var webrtc = this.get('webrtc');
                if (selfMute === false) {
                    webrtc.mute();
                    this.set('selfMute', true);
                } else {
                    webrtc.unmute();
                    this.set('selfMute', false);
                }
            },

            runToLeave: function runToLeave() {
                var webrtc = this.get('webrtc');
                if (webrtc) {
                    webrtc.stopLocalVideo();
                    webrtc.leaveRoom();
                    webrtc.connection.disconnect();
                }
                if (this.get('hasTorch')) {
                    this.switchLight("0");
                }
                this.set('webrtc', null);
            },

            buttonForOncePointer: function buttonForOncePointer() {
                var oncePointer = this.get('oncePointer');
                this.set('oncePointer', !oncePointer);
                this.set('twicePointer', false);
                this.set('realTimePointer', false);
                if (oncePointer === true) {
                    var bigVideoId = this.get('bigVideo');
                    var mainVidCanvas = document.getElementById('mainVidCanvas');
                    var thisCtx = mainVidCanvas.getContext('2d');
                    thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicRTCRoom, [], {
                        msgCat: 'oncePointerRemove',
                        vidId: bigVideoId
                    });
                }
            },

            buttonForTwicePointer: function buttonForTwicePointer() {
                var twicePointer = this.get('twicePointer');
                this.set('oncePointer', false);
                this.set('twicePointer', !twicePointer);
                this.set('realTimePointer', false);
            },

            buttonForRealTimePointer: function buttonForRealTimePointer() {
                var realTimePointer = this.get('realTimePointer');
                this.set('oncePointer', false);
                this.set('twicePointer', false);
                this.set('realTimePointer', !realTimePointer);
            },

            takeScreenshot: function takeScreenshot() {
                var videoScreenShotCanvas = document.getElementById('videoScreenShotCanvas');
                var canvasContext = videoScreenShotCanvas.getContext('2d');
                var bigVideo = this.get('bigVideo');
                var mobileDevice = this.get('mobileDevice');
                var mainVideoFrame = null;
                if (mobileDevice) {
                    mainVideoFrame = document.getElementById('localVideo');
                } else {
                    var bigVideoId = bigVideo + '_video_incoming';
                    mainVideoFrame = document.getElementById(bigVideoId);
                }
                var ratio = mainVideoFrame.videoWidth / mainVideoFrame.videoHeight;
                var videoWidth = mainVideoFrame.videoWidth;
                var videoHeight = parseInt(videoWidth / ratio, 10);
                videoScreenShotCanvas.width = videoWidth;
                videoScreenShotCanvas.height = videoHeight;
                canvasContext.fillRect(0, 0, videoWidth, videoHeight);
                canvasContext.drawImage(mainVideoFrame, 0, 0, videoWidth, videoHeight);
                var that = this;
                var dataUrl = videoScreenShotCanvas.toDataURL("image/png");
                var blob = this.dataURItoBlob(dataUrl);
                var fd = new FormData();
                fd.append('file', blob);
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;

                if (blob) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var newPicture = that.store.createRecord('picture', {
                                    url: data.path,
                                    name: 'vid',
                                    visibleForOthers: false,
                                    room: roomId
                                });
                                newPicture.save();
                                that.get('conference').send('successMsg', that.get('i18n').t('videoScreenshotAdded'));
                            } else {
                                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                            }
                        },
                        error: function error() {
                            that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                        }
                    });
                }
                videoScreenShotCanvas.width = 0;
                videoScreenShotCanvas.height = 0;
            },

            toggleLight: function toggleLight() {
                this.switchLight("2");
            },

            switchMobileDesktop: function switchMobileDesktop() {
                var mobileDevice = this.get('mobileDevice');
                this.set('mobileDevice', !mobileDevice);
                var webrtc = this.get('webrtc');
                if (webrtc) {
                    webrtc.stopLocalVideo();
                    webrtc.leaveRoom();
                    webrtc.connection.disconnect();
                }
                this.set('webrtc', null);
                this.send('comeIn');
            },

            startRecordVideo: function startRecordVideo() {
                var that = this;
                var options = {
                    type: 'video',
                    video: {
                        width: 1280,
                        height: 720
                    }
                };
                var recordStream = null;
                var mediaOptions = this.get('mediaOptions');
                var mediaOptionsVideo = mediaOptions.video;
                var mobileDevice = this.get('mobileDevice');
                if (mobileDevice) {
                    if (mediaOptionsVideo === false) {
                        that.get('conference').send('errorMsg', that.get('i18n').t('noVideoDevice'));
                        return;
                    }
                    var webrtc = this.get('webrtc'); // own Cam
                    recordStream = webrtc.webrtc.localStreams[0];
                } else {
                    recordStream = this.get('mediaOfBigVideo'); // big Video
                    if (recordStream === null) {
                        if (mediaOptionsVideo === false) {
                            that.get('conference').send('errorMsg', that.get('i18n').t('noVideoDevice'));
                            return;
                        }
                        var webrtc = this.get('webrtc'); // own Cam
                        recordStream = webrtc.webrtc.localStreams[0];
                    }
                }
                var recordRTC = new RecordRTC(recordStream, options);
                recordRTC.startRecording();
                this.set('recordObject', recordRTC);
                var recordTimeOut = setTimeout(function () {
                    that.send('stopRecordVideo');
                }, 120000);
                this.set('recordTimeOut', recordTimeOut);
                this.set('videoRecording', true);
            },

            stopRecordVideo: function stopRecordVideo() {
                var that = this;
                var recordTimeOut = this.get('recordTimeOut');
                clearTimeout(recordTimeOut);
                this.set('videoRecording', false);
                var recordRTC = this.get('recordObject');
                recordRTC.stopRecording(function () {
                    var recordedBlob = recordRTC.getBlob();
                    var fd = new FormData();
                    fd.append('file', recordedBlob);
                    if (recordedBlob) {
                        var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                        $.ajax({
                            type: 'POST',
                            url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploadvideos",
                            data: fd,
                            processData: false,
                            contentType: false,
                            headers: {
                                'Authorization': 'Bearer ' + lsat
                            },
                            success: function success(data) {
                                if (typeof data.error === 'undefined') {
                                    that.set('tempData', data);
                                    $('#modalVideoTitle').modal('show');
                                } else {
                                    that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                                }
                            },
                            error: function error() {
                                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                            }
                        });
                    }
                });
            },

            saveNewVideo: function saveNewVideo() {
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;
                var data = this.get('tempData');
                var videoName = this.get('videoName');
                var newVideo = this.store.createRecord('video', {
                    url: data.path,
                    name: videoName,
                    room: roomId
                });
                newVideo.save();
                this.set('videoName', null);
                $('#modalVideoTitle').modal('hide');
                this.get('conference').send('successMsg', this.get('i18n').t('videoAdded'));
            }
        },

        // WAMP Actions

        insertOncePointerWAMP: function insertOncePointerWAMP(thisEvent) {
            var mobileDevice = this.get('mobileDevice');
            var videoPanelBody = document.getElementById('videoContainer_' + thisEvent.vidId);
            if (mobileDevice || videoPanelBody) {
                var mainLocalVid = $('#localVideo');
                var mainVidCanvas = document.getElementById('mainVidCanvas');
                var mainVidWidth = mainLocalVid.width();
                var mainVidHeight = mainLocalVid.height();
                var position = mainLocalVid.position();
                var jMainVidCanvas = $("#mainVidCanvas");
                jMainVidCanvas.parent().css({
                    position: 'relative'
                });
                jMainVidCanvas.css({
                    top: position.top,
                    left: position.left + 15,
                    position: 'absolute'
                });
                mainVidCanvas.width = mainVidWidth;
                mainVidCanvas.height = mainVidHeight;
                var thisEventX = thisEvent.x / (100 / mainVidCanvas.width);
                var thisEventY = thisEvent.y / (100 / mainVidCanvas.height);
                var thisCtx = mainVidCanvas.getContext('2d');
                thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                thisCtx.shadowBlur = 10;
                thisCtx.shadowColor = "white";
                thisCtx.beginPath();
                thisCtx.moveTo(thisEventX, thisEventY);
                thisCtx.lineTo(thisEventX + 40, thisEventY);
                thisCtx.lineTo(thisEventX + 30, thisEventY + 10);
                thisCtx.lineTo(thisEventX + 80, thisEventY + 60);
                thisCtx.lineTo(thisEventX + 60, thisEventY + 80);
                thisCtx.lineTo(thisEventX + 10, thisEventY + 30);
                thisCtx.lineTo(thisEventX, thisEventY + 40);
                thisCtx.lineTo(thisEventX, thisEventY);
                thisCtx.closePath();
                thisCtx.stroke();
                thisCtx.fillStyle = "#19D722";
                thisCtx.fill();
            }
        },

        removeOncePointerWAMP: function removeOncePointerWAMP(thisEvent) {
            var mobileDevice = this.get('mobileDevice');
            var videoPanelBody = document.getElementById('videoContainer_' + thisEvent.vidId);
            if (mobileDevice || videoPanelBody) {
                var mainVidCanvas = document.getElementById('mainVidCanvas');
                var thisCtx = mainVidCanvas.getContext('2d');
                thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
            }
        },

        insertTwicePointerWAMP: function insertTwicePointerWAMP(thisEvent) {
            var mobileDevice = this.get('mobileDevice');
            var videoPanelBody = document.getElementById('videoContainer_' + thisEvent.vidId);
            if (mobileDevice || videoPanelBody) {
                var mainVidCanvas = document.getElementById('mainVidCanvas');
                var thisEventX = thisEvent.x / (100 / mainVidCanvas.width);
                var thisEventY = thisEvent.y / (100 / mainVidCanvas.height);
                var thisCtx = mainVidCanvas.getContext('2d');
                var numberToShow = 0;
                switch (thisEvent.number) {
                    case 1:
                        thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                        numberToShow = 1;
                        break;
                    case 2:
                        numberToShow = 2;
                        break;
                    case 3:
                        numberToShow = 3;
                        break;
                    case 0:
                        thisCtx.clearRect(0, 0, mainVidCanvas.width, mainVidCanvas.height);
                        numberToShow = 0;
                        break;
                }
                if (numberToShow > 0) {
                    thisCtx.shadowBlur = 10;
                    thisCtx.shadowColor = "white";
                    thisCtx.beginPath();
                    thisCtx.arc(thisEventX, thisEventY, 20, 0, 2 * Math.PI);
                    thisCtx.font = "30px Arial";
                    thisCtx.fillText(numberToShow, thisEventX - 5, thisEventY + 10);
                    thisCtx.stroke();
                }
            }
        }
    });
});