define('whiteboard2-frontend/conference/documentsharing/pdf/controller', ['exports', 'ember', 'rsvp', 'whiteboard2-frontend/app'], function (exports, _ember, _rsvp, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({

        bookmarkName: "",
        pageToLoad: 1,

        resizeWindow: function resizeWindow() {
            var mH = window.innerHeight - 100;
            var c = document.getElementById('panel-pdf');
            var ct = document.getElementById('main-pdf-container');
            if (c) {
                c.style.maxHeight = mH + "px";
                // show scrollbar if chatpanel is higher than window
                if (c.offsetHeight >= mH) {
                    c.style.overflowY = "scroll";
                } else {
                    c.style.overflowY = "hidden";
                }
                c.scrollTop = ct.scrollHeight;
            }
        },

        notifyUser: function notifyUser(msg) {
            _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicPDFRoom, [], {
                msgCat: msg
            });
        },

        unselectAllBookmarks: (function () {
            var bookmarks = this.get('bookmarks');
            bookmarks.forEach(function (item) {
                item.set('selected', false);
            });
        }).observes('model'),

        actions: {

            saveBookmark: function saveBookmark() {
                /////////////////////////// alte Marker löschen

                var documentID = 'pdfContainer';
                var viewer = document.getElementById(documentID);
                var alleMarkierten = viewer.getElementsByTagName('mark');
                var alleMarkiertenGesamt = alleMarkierten.length;
                for (var il = 0; il < alleMarkiertenGesamt; il++) {
                    var lastMark = alleMarkierten.length - 1;

                    var parentNode = alleMarkierten[lastMark].parentNode;
                    var text = parentNode.textContent;

                    while (parentNode.firstChild) {
                        parentNode.removeChild(parentNode.firstChild);
                    }
                    parentNode.textContent = text;
                }

                /////////////////////////// hole Markierung, hole Title, lade Variablen und Funktionen

                // var savedBookmark = this.get('savedBookmark');
                var sel = window.getSelection();
                var selTitel = "ohne Titel";
                var ranges = [];
                var _selection = {};
                _selection.items = [];
                _selection.title = selTitel;

                function makeXPath(_x, _x2) {
                    var _again = true;

                    _function: while (_again) {
                        var node = _x,
                            currentPath = _x2;
                        _again = false;

                        currentPath = currentPath || '';
                        if (node.id === documentID) {
                            return currentPath;
                        }
                        switch (node.nodeType) {
                            case 3:
                            case 4:
                                _x = node.parentNode;
                                _x2 = 'text()[' + (document.evaluate('preceding-sibling::text()', node, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null).snapshotLength + 1) + ']';
                                _again = true;
                                continue _function;

                            case 1:
                                _x = node.parentNode;
                                _x2 = node.nodeName + '[' + (document.evaluate('preceding-sibling::' + node.nodeName, node, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null).snapshotLength + 1) + ']' + (currentPath ? '/' + currentPath : '');
                                _again = true;
                                continue _function;

                            case 9:
                                return '/' + currentPath;
                            default:
                                return '';
                        }
                    }
                }

                function createMarkNode() {
                    var newNode = document.createElement("mark");
                    var backgroundColor = "background-color: #fff2a8";
                    newNode.setAttribute("style", backgroundColor);
                    return newNode;
                }

                if (sel.type === "None" || sel.type === "Caret") {
                    $('#bookmarkFunctionDialog').modal('show');
                } else {
                    var range = sel.getRangeAt(0);
                    var firstStartOffset = range.startOffset;
                    var firstEndOffset = range.endOffset;
                    if (range.startContainer !== range.endContainer) {
                        firstEndOffset = range.startContainer.length;
                    }

                    var firstContainer = range.startContainer;
                    var lastContainer = range.endContainer;

                    var firstParentContainer = firstContainer.parentNode;
                    var lastParentContainer = lastContainer.parentNode;
                    var nextParent = firstParentContainer.nextSibling;

                    var firstParentParentContainer = firstParentContainer.parentNode;
                    var lastParentParentContainer = lastParentContainer.parentNode;

                    var firstParentParentLastChildContainer = firstParentParentContainer.lastChild;
                    var lastParentParentFirstChildContainer = lastParentParentContainer.firstChild;

                    while (nextParent !== lastParentContainer && range.startContainer !== range.endContainer) {
                        var followContainer = nextParent.firstChild;
                        var followContainerPath = makeXPath(followContainer);
                        var followEndOffset = nextParent.firstChild.length;
                        _selection.items.push({ startOffset: 0, startContainerPath: followContainerPath, endOffset: followEndOffset, endContainerPath: followContainerPath });
                        if (nextParent === firstParentParentLastChildContainer) {
                            nextParent = lastParentParentFirstChildContainer;
                            continue;
                        }
                        var nextContainer = followContainer.parentNode;
                        nextParent = nextContainer.nextSibling;
                    }

                    var firstContainerPath = makeXPath(range.startContainer);
                    var lastContainerPath = makeXPath(range.endContainer);

                    var lastStartOffset = 0;
                    var lastEndOffset = range.endOffset;

                    if (firstStartOffset !== firstEndOffset) {
                        _selection.items.push({ startOffset: firstStartOffset, startContainerPath: firstContainerPath, endOffset: firstEndOffset, endContainerPath: firstContainerPath });
                        if (range.startContainer !== range.endContainer) {
                            _selection.items.push({ startOffset: lastStartOffset, startContainerPath: lastContainerPath, endOffset: lastEndOffset, endContainerPath: lastContainerPath });
                        }
                    }

                    var functionOne = _selection.items.length > 0 ? _selection : undefined;

                    /////////////////////////// in Datenbank speichern

                    var that = this;
                    var promises = [];

                    var pageId = viewer.firstChild.id.substring(5);

                    var newSelection = that.store.createRecord('bookmark', {
                        name: selTitel,
                        attachment: that.get('selectedFile'),
                        startPage: pageId
                    });

                    newSelection.save().then(function (savedSelection) {
                        var bookmarkId = savedSelection.get('id');

                        for (var iS = 0; iS < functionOne.items.length; iS++) {
                            var newSelectionItem = that.store.createRecord('bookmark-line', {
                                endPath: functionOne.items[iS].endContainerPath,
                                endOffset: functionOne.items[iS].endOffset,
                                startPath: functionOne.items[iS].startContainerPath,
                                startOffset: functionOne.items[iS].startOffset,
                                bookmark: bookmarkId
                            });
                            promises.push(newSelectionItem.save());
                        }
                        _rsvp['default'].all(promises).then(function (selectionItems) {
                            savedSelection.set('bookmarkLines', selectionItems);
                            savedSelection.save();

                            that.set('bookmarkSaved', savedSelection);
                        })['catch'](function (reason) {
                            that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + reason);
                        });
                        that.notifyUser('nb');
                    });

                    /////////////////////////// anzeigen der selection

                    for (var i = 0; i < functionOne.items.length; i++) {
                        var singleSelection = functionOne.items[i];
                        var singleRange = document.createRange();
                        singleRange.setStart(document.evaluate(singleSelection.startContainerPath, document.getElementById(documentID), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue, Number(singleSelection.startOffset));
                        singleRange.setEnd(document.evaluate(singleSelection.endContainerPath, document.getElementById(documentID), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue, Number(singleSelection.endOffset));
                        ranges.push(singleRange);
                    }

                    for (var j = 0; j < ranges.length; j++) {
                        try {
                            var selectionNode = createMarkNode();
                            selectionNode.selectionObject = functionOne.items[j];
                            $(selectionNode).click(function () {
                                return false;
                            });
                            ranges[j].surroundContents(selectionNode);
                        } catch (e) {
                            that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured'));
                        }
                    }
                    $('#bookmarkTitleDialog').modal('show');
                }
                if (window.getSelection) {
                    if (window.getSelection().empty) {
                        // Chrome
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) {
                        // Firefox
                        window.getSelection().removeAllRanges();
                    }
                } else if (document.selection) {
                    // IE?
                    document.selection.empty();
                }
            },

            saveBookmarkName: function saveBookmarkName() {
                $('#bookmarkTitleDialog').modal('hide');
                var bookmarkName = this.get('bookmarkName');
                if (bookmarkName === '') {
                    return;
                }
                var bookmarkSaved = this.get('bookmarkSaved');
                bookmarkSaved.set('name', bookmarkName);
                bookmarkSaved.save();
                this.set('bookmarkName', '');
                this.set('bookmarkSaved', '');
            },

            showBookmark: function showBookmark(givenBookmark) {
                var bookmarks = this.get('bookmarks');
                bookmarks.forEach(function (item) {
                    item.set('selected', false);
                });
                givenBookmark.set('selected', true);

                var documentID = 'pdfContainer'; // ID vom PDF.js Fenster
                var pageToLoad = givenBookmark.get('startPage'); // Startseite vom Lesezeichen
                this.set('pageToLoad', pageToLoad);
                var backgroundColor = "background-color: #ff76ff"; // Markierungsfarbe

                function createMarkNode() {
                    var newNode = document.createElement("mark");
                    newNode.setAttribute("style", backgroundColor);
                    return newNode;
                }

                /////////////////////////// alte Marker löschen

                var viewer = document.getElementById(documentID);
                var alleMarkierten = viewer.getElementsByTagName('mark');
                var alleMarkiertenGesamt = alleMarkierten.length;
                for (var il = 0; il < alleMarkiertenGesamt; il++) {
                    var lastMark = alleMarkierten.length - 1;

                    var parentNode = alleMarkierten[lastMark].parentNode;
                    var text = parentNode.textContent;

                    while (parentNode.firstChild) {
                        parentNode.removeChild(parentNode.firstChild);
                    }
                    parentNode.textContent = text;
                }

                /////////////////////////// Details aus Bookmark lesen und im Array "ranges" speichern

                _ember['default'].run.later(this, function () {
                    givenBookmark.get('bookmarkLines').then(function (topitem) {
                        topitem.forEach(function (item) {
                            var promise = [];
                            var startPath = item.get('startPath');
                            var endPath = item.get('endPath');
                            var startOff = item.get('startOffset');
                            var endOff = item.get('endOffset');

                            promise.pushObject(startPath);
                            promise.pushObject(endPath);
                            promise.pushObject(startOff);
                            promise.pushObject(endOff);
                            _rsvp['default'].all(promise).then(function () {
                                var singleRange = document.createRange();
                                singleRange.setStart(document.evaluate(startPath, document.getElementById(documentID), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue, Number(startOff));
                                singleRange.setEnd(document.evaluate(endPath, document.getElementById(documentID), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue, Number(endOff));

                                var selectionNode = createMarkNode();
                                selectionNode.selectionObject = singleRange;
                                singleRange.surroundContents(selectionNode);
                            });
                        });
                    });
                }, 1000);
            },

            deleteBookmark: function deleteBookmark(givenBookmark) {
                // alle Lines expliziet laden, sonst Probleme beim löschen

                var allLines = givenBookmark.get('bookmarkLines');
                allLines.forEach(function (item) {
                    console.log('item: ', item);
                });
                this.set('bookmark', givenBookmark);
                $('#deleteBookmarkModal').modal('show');
            },

            deleteBookmarkReally: function deleteBookmarkReally() {
                var givenBookmark = this.get('bookmark');
                var allLines = givenBookmark.get('bookmarkLines');
                allLines.forEach(function (item) {
                    item.destroyRecord();
                });
                givenBookmark.destroyRecord();
            }
        }
    });
});