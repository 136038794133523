define('whiteboard2-frontend/conference/administration/rooms/room/edit/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.rooms.room.edit', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model() {
            return this.modelFor('conference.administration.rooms.room');
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            var allUser = this.get('store').findAll('user');
            controller.set('users', allUser);
        }
    });
});