define('whiteboard2-frontend/helpers/comm-symbol', ['exports', 'ember'], function (exports, _ember) {
    exports.commSymbol = commSymbol;

    function commSymbol(value) {
        var box = value[0];
        var symbol = 'fa-deaf';
        if (box === 1) {
            symbol = 'fa-volume-up';
        } else if (box === 2) {
            symbol = 'fa-video-camera';
        }
        return symbol;
    }

    exports['default'] = _ember['default'].Helper.helper(commSymbol);
});