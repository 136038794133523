define('whiteboard2-frontend/models/bookmark', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        user: _emberData['default'].belongsTo('user'),
        prepared: _emberData['default'].attr('number'),
        name: _emberData['default'].attr('string'),
        attachment: _emberData['default'].belongsTo('attachment'),
        startPage: _emberData['default'].attr('string'),
        bookmarkLines: _emberData['default'].hasMany('bookmark-line'),
        userDisplayName: _emberData['default'].attr('string'),
        selected: false
    });
});