define('whiteboard2-frontend/conference/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        i18n: _ember['default'].inject.service(),

        chatInvisible: true,
        text: null,
        user: null,
        messageUnread: 0,
        userAgent: _whiteboard2FrontendApp['default'].userAgent,
        onlineUserInvisible: true,
        users: [],
        roomActive: false,
        nextMessageTo: [],
        roomSelectionInvisible: true,
        chosenRoomName: null,
        statusOffline: false,
        olderMessages: false,
        messageMeta: [],
        iUseAudio: false,
        themeBright: false,

        init: function init() {
            _whiteboard2FrontendApp['default'].conference = this;
            var that = this;
            this.set('chatInvisible', true);
            this.set('onlineUserInvisible', true);
            this.set('roomSelectionInvisible', true);
            this.set('olderMessages', false);
            window.addEventListener('resize', function () {
                that.resizeWindow();
            });
            var testExp = new RegExp('Android|webOS|iPhone|iPad|' + 'BlackBerry|Windows Phone|' + 'Opera Mini|IEMobile|Mobile', 'i');
            if (testExp.test(navigator.userAgent)) {
                _whiteboard2FrontendApp['default'].userAgent = 'Mobile';
            } else {
                _whiteboard2FrontendApp['default'].userAgent = 'Desktop';
            }
            var usedTheme = "dark";
            var usedCookie = decodeURIComponent(document.cookie);
            var splittedCookies = usedCookie.split(';');
            for (var ic = 0; ic < splittedCookies.length; ic++) {
                var singleCookie = splittedCookies[ic];
                while (singleCookie.charAt(0) === ' ') {
                    singleCookie = singleCookie.substring(1);
                }
                if (singleCookie.indexOf('theme=') === 0) {
                    usedTheme = singleCookie.substring(6, singleCookie.length);
                }
            }
            if (usedTheme === "dark") {
                this.set('themeBright', false);
                var d = new Date();
                d.setTime(d.getTime() + 604800000);
                var expires = "expires=" + d.toUTCString();
                document.cookie = "theme=dark;" + expires + ";path=/";
            } else {
                this.set('themeBright', true);
                this.changeTheme();
            }
        },

        eventSystem: function eventSystem(args, kwargs) {
            if (kwargs.beOf === 'stts') {
                var users = _whiteboard2FrontendApp['default'].conference.get('users');
                var otherUserNow;
                if (kwargs.qa === "q") {
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicSystem, [], {
                        beOf: 'stts',
                        who: _whiteboard2FrontendApp['default'].whoAmI,
                        myId: _whiteboard2FrontendApp['default'].mySessionId,
                        room: _whiteboard2FrontendApp['default'].chosenRoomName,
                        roomId: _whiteboard2FrontendApp['default'].chosenRoomId,
                        way: _whiteboard2FrontendApp['default'].onlinePosition,
                        qa: 'a',
                        commUsed: _whiteboard2FrontendApp['default'].commIUse
                    });
                    users.clear();
                    var userNow = { name: _whiteboard2FrontendApp['default'].whoAmI, room: _whiteboard2FrontendApp['default'].chosenRoomName, place: _whiteboard2FrontendApp['default'].onlinePosition, me: true, myId: _whiteboard2FrontendApp['default'].mySessionId, commUsed: _whiteboard2FrontendApp['default'].commIUse };
                    users.pushObject(userNow);
                    _whiteboard2FrontendApp['default'].conference.set('users', users);
                }
                if (kwargs.who && kwargs.way !== 'out') {
                    otherUserNow = { name: kwargs.who, room: kwargs.room, place: kwargs.way, me: false, myId: kwargs.myId, commUsed: kwargs.commUsed };
                    users.pushObject(otherUserNow);
                    _whiteboard2FrontendApp['default'].conference.set('users', users);
                }
            } else if (kwargs.beOf === 'nr') {
                this.modelFor('conference').reload();
            }
        },

        switchRoom: function switchRoom(chosenRoom) {
            var that = this;
            this.set('roomActive', true);
            var roomId = chosenRoom.get('id');
            _whiteboard2FrontendApp['default'].chosenRoomId = roomId;
            _whiteboard2FrontendApp['default'].topicChatRoom = roomId + 'chat';
            _whiteboard2FrontendApp['default'].topicWBRoom = roomId + 'wb';
            _whiteboard2FrontendApp['default'].topicRTCRoom = roomId + 'rtc';
            _whiteboard2FrontendApp['default'].topicPDFRoom = roomId + 'pdf';

            if (_whiteboard2FrontendApp['default'].roomChatSubscription) {
                _whiteboard2FrontendApp['default'].roomChatSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomChatSubscription);
            }
            _whiteboard2FrontendApp['default'].WS_SESSION.subscribe(_whiteboard2FrontendApp['default'].topicChatRoom, this.newMessage).then(function (subscription) {
                _whiteboard2FrontendApp['default'].roomChatSubscription = subscription;
                _whiteboard2FrontendApp['default'].conference.getOldMessages(roomId);
            }, function (error) {
                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + error);
            });

            if (_whiteboard2FrontendApp['default'].roomWBSubscription) {
                _whiteboard2FrontendApp['default'].roomWBSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomWBSubscription);
            }
            _whiteboard2FrontendApp['default'].WS_SESSION.subscribe(_whiteboard2FrontendApp['default'].topicWBRoom, this.newWbEvent).then(function (subscription) {
                _whiteboard2FrontendApp['default'].roomWBSubscription = subscription;
            }, function (error) {
                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + error);
            });

            if (_whiteboard2FrontendApp['default'].roomRtcSubscription) {
                _whiteboard2FrontendApp['default'].roomRtcSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomRtcSubscription);
            }
            _whiteboard2FrontendApp['default'].WS_SESSION.subscribe(_whiteboard2FrontendApp['default'].topicRTCRoom, this.newRtcEvent).then(function (subscription) {
                _whiteboard2FrontendApp['default'].roomRtcSubscription = subscription;
            }, function (error) {
                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + error);
            });

            if (_whiteboard2FrontendApp['default'].roomPdfSubscription) {
                _whiteboard2FrontendApp['default'].roomPdfSubscription.unsubscribe(_whiteboard2FrontendApp['default'].roomPdfSubscription);
            }
            _whiteboard2FrontendApp['default'].WS_SESSION.subscribe(_whiteboard2FrontendApp['default'].topicPDFRoom, this.newPdfEvent).then(function (subscription) {
                _whiteboard2FrontendApp['default'].roomPdfSubscription = subscription;
            }, function (error) {
                that.get('conference').send('errorMsg', that.get('i18n').t('anErrorOccured') + error);
            });

            _whiteboard2FrontendApp['default'].conference.askOthers();
            this.transitionToRoute('conference');
        },

        askOthers: function askOthers(out) {
            var users = this.get('users');
            users.clear();
            var userNow = { name: _whiteboard2FrontendApp['default'].whoAmI, room: _whiteboard2FrontendApp['default'].chosenRoomName, place: _whiteboard2FrontendApp['default'].onlinePosition, me: true, myId: _whiteboard2FrontendApp['default'].mySessionId, commUsed: _whiteboard2FrontendApp['default'].commIUse };
            users.pushObject(userNow);
            var theWay;
            if (out) {
                theWay = out;
            } else {
                theWay = _whiteboard2FrontendApp['default'].onlinePosition;
            }
            _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicSystem, [], {
                beOf: 'stts',
                who: _whiteboard2FrontendApp['default'].whoAmI,
                myId: _whiteboard2FrontendApp['default'].mySessionId,
                room: _whiteboard2FrontendApp['default'].chosenRoomName,
                roomId: _whiteboard2FrontendApp['default'].chosenRoomId,
                way: theWay,
                qa: 'q',
                commUsed: _whiteboard2FrontendApp['default'].commIUse
            });
        },

        getOldMessages: function getOldMessages(roomId) {
            var that = this;
            var container = document.getElementById("chatTableBody");
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
            _ember['default'].run.later(this, function () {
                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                $.ajax({
                    type: 'GET',
                    url: _whiteboard2FrontendApp['default'].API_HOST + "/api/rooms/" + roomId + "/chat-messages?sortDirection=desc&sortProperty=timeStamp&size=20",
                    data: '',
                    processData: false,
                    contentType: false,
                    headers: { 'Authorization': 'Bearer ' + lsat },
                    success: function success(data) {
                        if (typeof data.error === 'undefined') {
                            that.set('messageMeta', data.meta);
                            if (data.meta.hasNextPage) {
                                that.set('olderMessages', true);
                            } else {
                                that.set('olderMessages', false);
                            }
                            var datas = data.chatMessages;
                            datas.forEach(function (thisData) {
                                _whiteboard2FrontendApp['default'].conference.showMessageBefore(thisData);
                            });
                            // Success so call function to process the form
                        } else {
                                // Handle errors here
                            }
                    },
                    error: function error() {
                        // Handle errors here
                    }
                });
            }, this, 500);
        },

        newWbEvent: function newWbEvent(args, kwargs) {
            _whiteboard2FrontendApp['default'].whiteboard.dispatchEvent(kwargs);
        },

        newRtcEvent: function newRtcEvent(args, kwargs) {
            _whiteboard2FrontendApp['default'].video.dispatchEvent(kwargs);
        },

        newPdfEvent: function newPdfEvent(args, kwargs) {
            _whiteboard2FrontendApp['default'].docushare.dispatchEvent(kwargs);
        },

        newMessage: function newMessage(args, kwargs) {
            _whiteboard2FrontendApp['default'].conference.notifyMe();
            _whiteboard2FrontendApp['default'].conference.showMessage(kwargs);
        },

        showMessageBefore: function showMessageBefore(kwargs) {
            var roomName = kwargs.room;
            if (roomName === undefined) {
                roomName = _whiteboard2FrontendApp['default'].chosenRoomName;
            }
            // Timestamp
            var d = new Date();
            var minusUTC = 0;
            var secSinceNull = 0;
            var minBox = 0;
            var std = 0;
            if (kwargs.timeStamp > 1000000000000) {
                minusUTC = d.getTimezoneOffset() * 60000;
                secSinceNull = (kwargs.timeStamp - minusUTC) % 86400000;
                minBox = Math.round(secSinceNull / 1000 / 60);
                std = Math.floor(secSinceNull / 1000 / 60 / 60);
            } else {
                minusUTC = d.getTimezoneOffset() * 60;
                secSinceNull = (kwargs.timeStamp - minusUTC) % 86400;
                minBox = Math.round(secSinceNull / 60);
                std = Math.floor(secSinceNull / 60 / 60);
            }
            var min = minBox % 60;
            if (min < 10) {
                min = '0' + min;
            }
            var timestamp = std + ":" + min;
            var tableRow = document.createElement("TR");
            var td1 = document.createElement("TD");
            var text1 = document.createTextNode(kwargs.author);
            td1.appendChild(text1);
            tableRow.appendChild(td1);
            var td2 = document.createElement("TD");
            var text2 = document.createTextNode(kwargs.text);
            td2.appendChild(text2);
            tableRow.appendChild(td2);
            var td3 = document.createElement("TD");
            var text3 = document.createTextNode(timestamp);
            td3.appendChild(text3);
            tableRow.appendChild(td3);

            var chatTableBody = document.getElementById('chatTableBody');
            chatTableBody.insertBefore(tableRow, chatTableBody.childNodes[0]);

            this.resizeWindow();
        },

        showMessage: function showMessage(kwargs) {
            // show unreaded messages if chat is hidden
            var invisible = this.get('chatInvisible');
            if (invisible === true) {
                var unreadMessages = this.get('messageUnread') + 1;
                this.set('messageUnread', unreadMessages);
            }
            var roomName = kwargs.room;
            if (roomName === undefined) {
                roomName = _whiteboard2FrontendApp['default'].chosenRoomName;
            }
            var prv = false;
            if (roomName === 'Privat') {
                prv = true;
            }
            // Timestamp
            var d = new Date();
            var minusUTC = 0;
            var secSinceNull = 0;
            var minBox = 0;
            var std = 0;
            if (kwargs.timeStamp > 1000000000000) {
                minusUTC = d.getTimezoneOffset() * 60000;
                secSinceNull = (kwargs.timeStamp - minusUTC) % 86400000;
                minBox = Math.round(secSinceNull / 1000 / 60);
                std = Math.floor(secSinceNull / 1000 / 60 / 60);
            } else {
                minusUTC = d.getTimezoneOffset() * 60;
                secSinceNull = (kwargs.timeStamp - minusUTC) % 86400;
                minBox = Math.round(secSinceNull / 60);
                std = Math.floor(secSinceNull / 60 / 60);
            }
            var min = minBox % 60;
            if (min < 10) {
                min = '0' + min;
            }
            var timestamp = std + ":" + min;

            //create and fill TR
            var tableRow = document.createElement("TR");
            if (prv === true) {
                tableRow.className += "private";
            }
            var td1 = document.createElement("TD");
            var text1 = document.createTextNode(kwargs.author);
            td1.appendChild(text1);
            tableRow.appendChild(td1);
            var td2 = document.createElement("TD");
            var text2 = document.createTextNode(kwargs.text);
            td2.appendChild(text2);
            tableRow.appendChild(td2);
            var td3 = document.createElement("TD");
            var text3 = document.createTextNode(timestamp);
            td3.appendChild(text3);
            tableRow.appendChild(td3);

            // Use this for last massage at bottom
            document.getElementById('chatTableBody').appendChild(tableRow);

            // Use this for last massage on top
            //var oldTable = document.getElementById('chatTableBody');
            //oldTable.insertBefore(tableRow, oldTable.childNodes[0]);

            this.resizeWindow();
        },

        getNewObjCreated: function getNewObjCreated() {
            return new Date().getTime();
        },

        resizeWindow: function resizeWindow() {
            // limit chatpanelheight on windowheight -100px
            var mH = window.innerHeight - 100;
            var c = document.getElementById('chatboard');
            var ct = document.getElementById('chatTable');
            if (c) {
                c.style.maxHeight = mH + "px";
                // show scrollbar if chatpanel is higher than window
                if (c.offsetHeight >= mH) {
                    c.style.overflowY = "scroll";
                } else {
                    c.style.overflowY = "hidden";
                }
                c.scrollTop = ct.scrollHeight;
            }
            var oa = document.getElementById('onlineanzeiger');
            var ol = document.getElementById('onlineList');
            if (oa) {
                oa.style.maxHeight = mH + "px";
                // show scrollbar if onlinepanel is higher than window
                if (oa.offsetHeight >= mH) {
                    oa.style.overflowY = "scroll";
                } else {
                    oa.style.overflowY = "hidden";
                }
                oa.scrollTop = ol.scrollHeight;
            }
            var ra = document.getElementById('switchRoomanzeiger');
            var rl = document.getElementById('switchRoomList');
            if (ra) {
                ra.style.maxHeight = mH + "px";
                // show scrollbar if onlinepanel is higher than window
                if (ra.offsetHeight >= mH) {
                    ra.style.overflowY = "scroll";
                } else {
                    ra.style.overflowY = "hidden";
                }
                ra.scrollTop = rl.scrollHeight;
            }
        },

        toggleChat: function toggleChat(close) {
            var chatInvisible = this.get('chatInvisible');
            if (chatInvisible === true && !close) {
                this.set('chatInvisible', false);
                this.set('messageUnread', 0);
                this.toggleOnline('c');
                document.getElementById('chatboard').style.right = "0%";
                this.resizeWindow();
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;
                _whiteboard2FrontendApp['default'].conference.getOldMessages(roomId);
            } else {
                this.set('chatInvisible', true);
                document.getElementById('chatboard').style.right = "-100%";
            }
            document.getElementById('chatButton').blur();
        },

        toggleOnline: function toggleOnline(close) {
            var invisible = this.get('onlineUserInvisible');
            if (invisible === true && !close) {
                this.set('onlineUserInvisible', false);
                this.toggleChat('c');
                document.getElementById('onlineanzeiger').style.right = "0%";
                this.resizeWindow();
            } else {
                this.set('onlineUserInvisible', true);
                document.getElementById('onlineanzeiger').style.right = "-30%";
            }
            document.getElementById('onlineButton').blur();
        },

        checkStatusOffline: function checkStatusOffline() {
            var statusOffline = this.get('statusOffline');
            if (_whiteboard2FrontendApp['default'].WS_SESSION === undefined) {
                this.set('statusOffline', true);
            } else {
                this.set('statusOffline', false);
            }
            return statusOffline;
        },

        notifyMe: function notifyMe() {
            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            } else {
                var invisible = this.get('chatInvisible');
                if (invisible === true) {
                    var notification = new Notification('New Chatmassage', {
                        icon: '/assets/images/flaschenpostIcon.gif',
                        body: this.get('i18n').t('receivedNewMessage')
                    });
                    notification.onclick = function () {
                        window.focus();
                    };
                }
            }
        },

        changeTheme: function changeTheme() {
            var themeBright = this.get('themeBright');
            var newMainCss = '/assets/whiteboard2-frontend.css';
            var newTemplateCss = '/assets/we-toolkit-inverse.css';
            var newCookieValue = "dark;";
            if (themeBright) {
                newMainCss = '/assets/app-light.css';
                newTemplateCss = '/assets/toolkit-light.css';
                newCookieValue = "bright;";
            }
            var oldMainCss = document.getElementById('mainCss');
            var oldTemplateCss = document.getElementById('templateCss');
            var newlinkMain = document.createElement("link");
            newlinkMain.setAttribute("rel", "stylesheet");
            newlinkMain.setAttribute("type", "text/css");
            newlinkMain.setAttribute("id", "mainCss");
            newlinkMain.setAttribute("href", newMainCss);
            document.getElementsByTagName("head").item(0).replaceChild(newlinkMain, oldMainCss);
            var newlinkTemplate = document.createElement("link");
            newlinkTemplate.setAttribute("rel", "stylesheet");
            newlinkTemplate.setAttribute("type", "text/css");
            newlinkTemplate.setAttribute("id", "templateCss");
            newlinkTemplate.setAttribute("href", newTemplateCss);
            document.getElementsByTagName("head").item(0).replaceChild(newlinkTemplate, oldTemplateCss);

            var d = new Date();
            d.setTime(d.getTime() + 604800000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = "theme=" + newCookieValue + expires + ";path=/";
        },

        actions: {
            firstRunAfterRender: function firstRunAfterRender() {
                var that = this;
                _ember['default'].run.later(function () {
                    var roomName = "rName=";
                    var roomId = "rId=";
                    var cookieRoomName = null;
                    var cookieRoomId = null;
                    var decodedCookie = decodeURIComponent(document.cookie);
                    var ca = decodedCookie.split(';');
                    for (var i = 0; i < ca.length; i++) {
                        var c = ca[i];
                        while (c.charAt(0) === ' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(roomName) === 0) {
                            cookieRoomName = c.substring(roomName.length, c.length);
                        }
                        if (c.indexOf(roomId) === 0) {
                            cookieRoomId = c.substring(roomId.length, c.length);
                        }
                    }
                    if (cookieRoomName && cookieRoomId) {
                        var thisRoom = that.get('store').peekRecord('room', cookieRoomId);
                        if (thisRoom) {
                            that.set('chosenRoomName', cookieRoomName);
                            _whiteboard2FrontendApp['default'].chosenRoomName = cookieRoomName;
                            that.set('chosenRoomId', cookieRoomId);
                            _whiteboard2FrontendApp['default'].chosenRoomId = cookieRoomId;
                            that.switchRoom(thisRoom);
                            var d = new Date();
                            d.setTime(d.getTime() + 604800000);
                            var expires = "expires=" + d.toUTCString();
                            document.cookie = "rId=" + cookieRoomId + ";" + expires + "; path=/";
                            document.cookie = "rName=" + cookieRoomName + ";" + expires + "; path=/";
                            _ember['default'].run.later(function () {
                                that.transitionToRoute('conference.whiteboard');
                            }, 500);
                        } else {
                            document.cookie = "rId=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                            document.cookie = "rName=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                            $('#modalChooseRoom').modal('show');
                        }
                    } else {
                        $('#modalChooseRoom').modal('show');
                    }
                }, 1500);
            },

            successMsg: function successMsg(msg) {
                var that = this;
                this.set('successMessage', msg);
                _ember['default'].run.later(function () {
                    that.set('successMessage', null);
                }, 5000);
            },

            errorMsg: function errorMsg(msg) {
                var that = this;
                this.set('errorMessage', msg);
                _ember['default'].run.later(function () {
                    that.set('errorMessage', null);
                }, 5000);
            },

            logoutModal: function logoutModal() {
                $('#modalLogout').modal('show');
            },

            logout: function logout() {
                this.get('session').invalidate();
            },

            chatSendAction: function chatSendAction() {
                document.getElementById('chatSendButton').click();
            },

            reloadOlderMessages: function reloadOlderMessages() {
                var that = this;
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;
                var messageMeta = this.get('messageMeta');
                var thisPage = messageMeta.page;
                var nextPage = messageMeta.nextPage;
                if (thisPage !== nextPage) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'GET',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/rooms/" + roomId + "/chat-messages?sortDirection=desc&sortProperty=timeStamp&size=20&page=" + nextPage,
                        data: '',
                        processData: false,
                        contentType: false,
                        headers: { 'Authorization': 'Bearer ' + lsat },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                that.set('messageMeta', data.meta);
                                if (data.meta.hasNextPage) {
                                    that.set('olderMessages', true);
                                } else {
                                    that.set('olderMessages', false);
                                }
                                var datas = data.chatMessages;
                                datas.forEach(function (thisData) {
                                    _whiteboard2FrontendApp['default'].conference.showMessageBefore(thisData);
                                });
                                // Success so call function to process the form
                            } else {
                                    // Handle errors here
                                }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                }
            },

            sendMessage: function sendMessage() {
                var text = this.get('text');
                this.set('text', '');
                var created = this.getNewObjCreated();
                if (this.checkStatusOffline() === false) {
                    if (text !== undefined && text !== '') {
                        var messageToSent = {
                            timeStamp: created,
                            text: text,
                            author: _whiteboard2FrontendApp['default'].whoAmI,
                            room: _whiteboard2FrontendApp['default'].chosenRoomName
                        };
                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicChatRoom, [], messageToSent);
                        this.showMessage(messageToSent);
                    }
                }
            },

            sendPrivateMsgModal: function sendPrivateMsgModal(chosenUser) {
                if (!chosenUser.me) {
                    this.toggleOnline();
                    this.set('nextMessageTo', chosenUser);
                    $('#modalPrivateMsg').modal('show');
                }
            },

            privateSendAction: function privateSendAction() {
                document.getElementById('privateSendButton').click();
            },

            sendPrivateMsg: function sendPrivateMsg() {
                $('#modalPrivateMsg').modal('hide');
                var chosenUser = this.get('nextMessageTo');
                this.set('nextMessageTo', '');
                var messageText = this.get('nextMessageText');
                this.set('nextMessageText', '');
                var created = this.getNewObjCreated();
                var chosenUserSessionId = chosenUser.myId.toString();
                var privateMessageToSent = {
                    timeStamp: created,
                    text: messageText,
                    author: _whiteboard2FrontendApp['default'].whoAmI,
                    room: 'Privat'
                };
                _whiteboard2FrontendApp['default'].WS_SESSION.publish('General', [], privateMessageToSent, {
                    exclude: [],
                    eligible: [chosenUserSessionId]
                });
                this.showMessage(privateMessageToSent);
            },

            // show/hidden chat
            toggleChatAction: function toggleChatAction() {
                this.toggleChat();
            },

            // show/hidden 'who is online'
            toggleOnlineAction: function toggleOnlineAction() {
                this.toggleOnline();
            },

            toggleRoomAction: function toggleRoomAction() {
                //document.getElementById('switchRoomButton').blur();
                $('#modalChooseRoom').modal('show');
            },

            toggleThemeAction: function toggleThemeAction() {
                var themeBright = this.get('themeBright');
                this.set('themeBright', !themeBright);
                this.changeTheme();
            },

            switchRoomAction: function switchRoomAction(room) {
                var that = this;
                var chosenRoomName = room.get('name');
                _whiteboard2FrontendApp['default'].chosenRoomName = chosenRoomName;
                var chosenRoomId = room.get('id');
                _whiteboard2FrontendApp['default'].chosenRoomId = chosenRoomId;
                this.set('chosenRoomName', chosenRoomName);
                this.set('chosenRoomId', chosenRoomId);
                this.switchRoom(room);
                var d = new Date();
                d.setTime(d.getTime() + 604800000);
                var expires = "expires=" + d.toUTCString();
                document.cookie = "rId=" + chosenRoomId + ";" + expires + "; path=/";
                document.cookie = "rName=" + chosenRoomName + ";" + expires + "; path=/";
                _ember['default'].run.later(function () {
                    that.transitionToRoute('conference.whiteboard');
                }, 500);
            },

            toggleAudioChat: function toggleAudioChat() {
                var roomActive = this.get('roomActive');
                if (roomActive) {
                    var webrtc;
                    if (_whiteboard2FrontendApp['default'].commIUse === 1) {
                        webrtc = this.get('webrtc');
                        webrtc.stopLocalVideo();
                        webrtc.leaveRoom();
                        webrtc.connection.disconnect();
                        this.set('webrtc', null);
                        this.set('iUseAudio', 0);
                        _whiteboard2FrontendApp['default'].commIUse = 0;
                    } else if (_whiteboard2FrontendApp['default'].commIUse === 0) {
                        webrtc = new SimpleWebRTC({
                            // the id/element dom element that will hold "our" video
                            localVideoEl: '',
                            // the id/element dom element that will hold remote videos
                            remoteVideosEl: '',
                            // immediately ask for camera access
                            autoRequestMedia: true,
                            debug: false,
                            detectSpeakingEvents: true,
                            autoAdjustMic: false,
                            nick: _whiteboard2FrontendApp['default'].whoAmI,
                            url: _whiteboard2FrontendApp['default'].API_HOST,
                            media: {
                                audio: true,
                                video: false
                            },
                            socketio: {
                                "force new connection": true
                            },
                            receiveMedia: {
                                offerToReceiveAudio: 1,
                                offerToReceiveVideo: 0
                            }
                        });

                        this.set('webrtc', webrtc);
                        var room = _whiteboard2FrontendApp['default'].topicRTCRoom;
                        webrtc.on('readyToCall', function () {
                            if (room) {
                                webrtc.joinRoom(room);
                            }
                        });
                        this.set('iUseAudio', 1);
                        _whiteboard2FrontendApp['default'].commIUse = 1;
                    }
                }
                _whiteboard2FrontendApp['default'].conference.askOthers();
                //document.getElementById('switchAudioButton').blur();
            }
        }
    });
});