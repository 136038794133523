define('whiteboard2-frontend/conference/administration/rooms/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        i18n: _ember['default'].inject.service(),
        queryParams: ['page'],
        page: 1,

        actions: {
            showRoom: function showRoom(roomId) {
                this.get('model').forEach(function (room) {
                    room.set('selected', false);
                });
                this.transitionToRoute('conference.administration.rooms.room', roomId);
            },

            deleteRoomModal: function deleteRoomModal(chosenRoom) {
                this.set('roomToDelete', chosenRoom);
                $('#modalDeleteRoom').modal('show');
            },

            deleteRoom: function deleteRoom(chosenRoom) {
                this.get('administration').send('newAttention', this.get('i18n').t('roomDeleted'));
                chosenRoom.set('selected', false);
                chosenRoom.deleteRecord();
                chosenRoom.save();
                this.transitionToRoute('conference.administration.rooms');
            },

            refreshIt: function refreshIt() {
                this.send('refreshModel');
            }
        }
    });
});