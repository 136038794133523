define('whiteboard2-frontend/conference/administration/customers/customer/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.customers.customer', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model(params) {
            return this.get('store').findRecord('customer', params.customer_id);
        },

        afterModel: function afterModel(model) {
            var customers = this.modelFor('conference.administration.customers');
            customers.forEach(function (customer) {
                customer.set('selected', false);
            });
            model.set('selected', true);
        }
    });
});