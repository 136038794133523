define('whiteboard2-frontend/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTSerializer.extend({
        extractMeta: function extractMeta(store, typeClass, payload) {
            if (payload && payload.hasOwnProperty('meta')) {
                var meta = payload.meta;
                delete payload.meta;
                return meta;
            }
        }
    });
});