define('whiteboard2-frontend/models/attachment', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        url: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        date: _emberData['default'].attr('number'),
        filesize: _emberData['default'].attr('number'),
        bookmarks: _emberData['default'].hasMany('bookmark'),
        room: _emberData['default'].belongsTo('room'),
        selected: false
    });
});