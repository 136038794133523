define('whiteboard2-frontend/conference/administration/customers/new/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.customers.new', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model() {
            return this.get('store').createRecord('customer');
        },

        afterModel: function afterModel() {
            var customers = this.modelFor('conference.administration.customers');
            customers.forEach(function (customer) {
                customer.set('selected', false);
            });
        },

        activate: function activate() {
            this.controllerFor('conference.administration.customers.new').set('leftRegular', false);
        },

        deactivate: function deactivate() {
            var leftRegular = this.controllerFor('conference.administration.customers.new').get('leftRegular');
            var model = this.controllerFor('conference.administration.customers.new').get('model');
            if (!leftRegular) {
                if (model) {
                    model.deleteRecord();
                }
            }
        }
    });
});