define('whiteboard2-frontend/conference/administration/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        adminMessage: false,

        actions: {
            newAttention: function newAttention(msg) {
                var that = this;
                this.set('adminMessage', msg);
                _ember['default'].run.later(function () {
                    that.set('adminMessage', false);
                }, 5000);
            }
        }
    });
});