define('whiteboard2-frontend/conference/videos/live/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        activate: function activate() {
            _whiteboard2FrontendApp['default'].onlinePosition = 'Video';
            if (_whiteboard2FrontendApp['default'].commIUse === 1) {
                this.controllerFor('conference').send('toggleAudioChat');
            }
            _whiteboard2FrontendApp['default'].commIUse = 2;
            this.controllerFor('conference/videos/live').send('firstRunAfterRender');
            _ember['default'].run.later(function () {
                _whiteboard2FrontendApp['default'].conference.askOthers();
            }, 1000);
        },

        deactivate: function deactivate() {
            _whiteboard2FrontendApp['default'].commIUse = 0;
            this.controller.send('runToLeave');
            this.controllerFor('conference').send('toggleAudioChat');
        }
    });
});