define('whiteboard2-frontend/login/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        email: null,
        password: null,
        errors: null,
        actions: {
            login: function login() {
                var that = this;

                var _getProperties = this.getProperties('email', 'password');

                var email = _getProperties.email;
                var password = _getProperties.password;

                this.set('email', null);
                this.set('password', null);
                this.get('session').authenticate('authenticator:oauth2', email, password)['catch'](function () {
                    that.set('errors', true);
                });
                // Fullscreen if browser is mobile
                if (_whiteboard2FrontendApp['default'].userAgent === 'Mobile') {
                    console.log('Mobile');
                    var docEl = window.document.documentElement;
                    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
                    requestFullScreen.call(docEl);
                } else {
                    console.log('Desktop');
                }
            },

            pwResetModal: function pwResetModal() {
                $('#modalPwReset').modal('show');
            },

            sendResetAction: function sendResetAction() {
                var that = this;
                var formData = new FormData();
                var resetEmail = this.get('resetEmail');
                formData.append('email', resetEmail);
                $.ajax({
                    type: 'POST',
                    url: _whiteboard2FrontendApp['default'].API_HOST + "/api/reset",
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function success(data) {
                        if (typeof data.error === 'undefined') {
                            that.set('resetEmail', '');
                        } else {
                            console.log('sendResetAction success with error');
                        }
                    },
                    error: function error() {
                        console.log('sendResetAction error');
                    }
                });
            }
        }
    });
});