define('whiteboard2-frontend/conference/documentsharing/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        activate: function activate() {
            _whiteboard2FrontendApp['default'].onlinePosition = 'Document';
            _ember['default'].run.later(function () {
                _whiteboard2FrontendApp['default'].conference.askOthers();
            }, 1000);
        },

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicPDFRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        },

        model: function model() {
            var chosenRoomId = _whiteboard2FrontendApp['default'].chosenRoomId;
            return this.get('store').findRecord('room', chosenRoomId);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var attachments = model.get('attachments');
            controller.set('attachments', attachments);
        }
    });
});