define('whiteboard2-frontend/conference/administration/accounts/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            page: {
                refreshModel: true
            }
        },

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.accounts', {
                into: 'conference.administration',
                outlet: 'mainColumn'
            });
        },

        model: function model(params) {
            return this.get('store').query('account', params);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            var meta = model.get('meta');
            controller.set('meta', meta);
        },

        actions: {
            error: function error(_error) {
                if (_error && _error.status === 404) {
                    // HTTP Status 404 Handler
                    return this.transitionTo('conference.administration.accounts');
                }
                return true;
            },
            refreshModel: function refreshModel() {
                this.refresh();
            }
        }

    });
});