define('whiteboard2-frontend/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    location: _emberData['default'].attr('string'),
    latitude: _emberData['default'].attr('number'),
    longitude: _emberData['default'].attr('number'),
    prepared: _emberData['default'].attr('number'),
    accounts: _emberData['default'].hasMany('account'),

    selected: false
  });
});