define('whiteboard2-frontend/conference/videos/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicRTCRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        }
    });
});