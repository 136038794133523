define('whiteboard2-frontend/locales/fr/translations', ['exports'], function (exports) {
    exports['default'] = {
        'mainPage': 'weInspect',
        'home': 'Accueil',
        'login': 'Me Connecter',
        'whiteboard': 'Tableau blanc',
        'menu': 'Menu',
        'video': 'Vidéo',
        'sharedDocu': 'Documents partagés',
        'library': 'Bibliothèque',
        'documentation': 'Documentation',
        'checklist': 'Aide-mémoire',
        'admin': 'Administrateur',
        'help': 'Aider',
        'logout': 'Logout',
        'audioIs': 'L audio est',
        'toggleTheme': 'bascule le thème',
        'chat': 'Bavarder',
        'online': 'En ligne',
        'impressum': 'Impressum',
        'contact': 'Contact',
        'copyright': 'Copyright © Webware Experts OHG 2018',

        'firstRooming': "S'il vous plaît choisir une chambre",
        'noRoom': 'pas de chambre',
        'privateMsgTo': 'Message privé à',
        'reallyLogout': 'Voulez-vous vraiment vous déconnecter?',
        'confirmPassword': 'Confirmez le mot de passe',
        'newPassword': 'nouveau mot de passe',
        'mailToPWRecover': "S'il vous plaît entrez votre email, vous recevrez un mail avec de nouvelles instructions.",
        'olderMessagesAvailable': 'Messages plus anciens disponibles',
        'receivedNewMessage': 'nouveau message reçu',
        'reallyDelete': 'vraiment supprimer',
        'noVideoDevice': 'pas de périphérique vidéo',
        'connectingToPeer': ' - de liaison ...',
        'connectionEstablished': ' - Connection établie',
        'disconnected': ' - débranché',
        'connectionClosed': ' - connexion fermée',
        'videoMoved': 'Vidéo déplacée',
        'connectionFailed': 'la connexion a échoué',
        'chooseInputDevice': "choisissez le périphérique d'entrée",
        'chosenVideoDevice': 'périphérique vidéo choisi',
        'chosenAudioDevice': 'périphérique audio choisi',
        'selectAObject': 'veuillez sélectionner un rectangle',
        'notARect': "l'objet sélectionné n'est pas un rectangle",
        'notOverImage': 'Le rectangle devrait être sur une image',
        'newChecklist': 'nouvelle liste de contrôle',
        'chooseChecklist': 'Choisissez une liste de contrôle',
        'checklistTemplates': 'Modèles de liste de contrôle',
        'noActiveChecklist': 'pas de liste de contrôle active',
        'noActiveChecklistEntry': 'aucune entrée de liste de contrôle active',
        'anErrorOccured': "une erreur s'est produite",

        'send': 'Envoyer',
        'no': 'Non',
        'yes': 'Oui',
        'on': 'activé',
        'off': 'désactivé',
        'abort': 'avorter',
        'ok': "D'accord",
        'save': 'enregistrer',
        'ready': 'prêt',
        'success': 'Succès!',
        'back': 'arrière',
        'error': 'Erreur!',
        'attention': 'attention',
        'reset': 'réinitialiser',
        'actions': 'actes',
        'prev': 'précédent',
        'next': 'prochain',
        'delete': 'effacer',
        'editing': 'édition',
        'zoom': 'Zoom',
        'goToPage': 'aller à la page',
        'reload': 'recharger',
        'close': 'Fermer',
        'state': 'Etat',
        'picture': 'Image',
        'choose': 'Choisir',
        'of': 'de',
        'record': 'Record',
        'live': 'Vivre',

        'id': 'ID',
        'name': 'Prénom',
        'msg': 'Message',
        'time': 'Temps',
        'email': 'Email',
        'password': 'Mot de passe',
        'userData': "Données d'utilisateur",
        'firstName': 'Prénom',
        'lastName': 'Nom de famille',
        'gender': 'Le genre',
        'male': 'Mâle',
        'female': 'Femelle',
        'phone': 'Téléphone',
        'title': 'Titre',
        'role': 'Rôle',
        'location': 'Emplacement',
        'additionalInfos': 'information additionnelle',
        'details': 'Détails',
        'description': 'La description',
        'longitude': 'Longitude',
        'latitude': 'Latitude',
        'member': 'Membre',
        'TECHNICIAN': 'Technicien',
        'ADMIN': 'Administrateur',
        'CUSTOMER': 'Client',

        'accounts': 'Comptes',
        'account': 'Compte',
        'createNewAccount': 'créer un nouveau compte',
        'newAccountCreated': 'nouveau compte créé',
        'newAccountError': 'nouveau compte - Erreur',
        'newAccountCanceled': 'nouveau compte - Aborted',
        'accountDeleted': 'Compte supprimé',
        'accountEditingCanceled': 'Modification de compte - Abandonner',
        'accountEdited': 'Compte édité',
        'wantAccountOf': 'veux le compte de',
        'pleasEnterMail': "S'il vous plaît, mettez une adresse email valide",

        'room': 'Chambre',
        'rooms': 'Pièces',
        'createNewRoom': 'créer une nouvelle pièce',
        'newRoomCreated': 'nouvelle pièce créée',
        'newRoomError': 'nouvelle chambre - Erreur',
        'newRoomCanceled': 'nouvelle chambre - Aborted',
        'roomDeleted': 'Chambre supprimée',
        'roomEditingCanceled': 'Modification de la salle - Abort',
        'roomEdited': 'Salle éditée',
        'wantRoom': 'veux la chambre',
        'chooseRoom': 'choisissez une chambre',
        'enterRoomName': 'Veuillez entrer un nom de chambre',
        'enterRoomDescription': 'Veuillez entrer une description de chambre',

        'customer': 'Customer',
        'customers': 'Customer',
        'createNewCustomer': 'create new Customer',
        'newCustomerCreated': 'new Customer created',
        'newCustomerError': 'new Customer - Error',
        'newCustomerCanceled': 'new Customer - Aborted',
        'customerDeleted': 'Customer deleted',
        'customerEditingCanceled': 'Customer editing - Abort',
        'customerEdited': 'Customer edited',
        'wantCustomer': 'want the Customer',
        'customerNameInput': 'please enter the Customer Name',
        'customerLocationInput': 'please enter a Customer Location',

        'bookmark': 'Bookmark',
        'bookmarks': 'Bookmarks',
        'noBookmarks': 'no Bookmarks',
        'createNewBookmark': 'create new Bookmark',
        'newBookmarkCreated': 'new Bookmark created',
        'newBookmarkError': 'new Bookmark - Error',
        'newBookmarkCanceled': 'new Bookmark - Abort',
        'bookmarkDeleted': 'Bookmark deleted',
        'bookmarkEditingCanceled': 'Bookmark editing - Abort',
        'bookmarkEdited': 'Bookmark edited',
        'wantBookmark': 'want the Bookmark',

        'pdf': 'PDF',
        'file': 'File',
        'files': 'Files',
        'upload': 'Upload',
        'wantPdf': 'want the PDF',
        'document': 'Document',

        'pictures': 'Pictures',
        'sharePicture': 'share Picture',
        'reallySharePicture': 'Do you really want to share this Picture with your room',
        'unSharePicture': 'do not share Picture',
        'reallyUnSharePicture': 'Do you really want to not share this Picture with your room',
        'picToPicture': 'take new Picture',
        'noPicture': 'no Picture available',
        'newPicture': 'new Picture',
        'editable': 'editable',
        'highRes': 'high Resolution',
        'whiteboardScreenshotAdded': 'Whiteboard Screenshot added to Pictures',
        'videoScreenshotAdded': 'Video Screenshot added to Pictures',
        'deletePicture': 'delete Picture',
        'reallyDeletePicture': 'Do you really want to delete this Picture',
        'choosePicture': 'choose Picture',
        'whup': 'Whiteboard Upload',
        'whca': 'Whiteboard Camera',
        'whss': 'Whiteboard Screenshot',
        'doca': 'Documentation Camera',
        'doup': 'Documentation Upload',
        'vid': 'Video',
        'videos': 'Videos',
        'newVideoName': 'new Video Title',
        'deleteVideo': 'delete Video',
        'reallyDeleteVideo': 'Do you really want to delete this Video',
        'noVideo': 'no Video available',
        'videoAdded': 'Video added',
        'guided': 'Guided',
        'chooseRecord': 'choose Record',

        'uploadPicture': 'upload Picture',
        'filesystem': 'Filesystem',
        'docuPics': 'Documentation Pictures',
        'saveWhiteboard': 'save Whiteboad',
        'pushUp': 'push up',
        'pushDown': 'pull down',
        'deleteElement': 'delete Element',
        'clearCanvas': 'clear Canvas',
        'insertRect': 'insert Rect',
        'enlargeArea': 'enlarge Area',
        'freehandDraw': 'draw Freehand',
        'brushWidth': 'Brush width',
        'setMark': 'set Mark',
        'createMark': 'create Mark',
        'mark': 'Mark {{count}}',
        'white': 'white',
        'blue': 'blue',
        'green': 'green',
        'yellow': 'yellow',
        'red': 'red',
        'up': 'up',
        'down': 'down',
        'left': 'left',
        'right': 'right',
        'markTextInput': 'Label Textinput',
        'finishedInput': 'finish Input',
        'deleteAll': 'delete All',
        'reallyDeleteAll': 'Do you really want to delete complete Canvas',
        'chooseInput': 'choose Input',
        'picToWhiteboard': 'Picture for Whiteboard',
        'passwordPlease': 'Please enter the Password to open this File',
        'passwordIncorrect': 'Invalid! Please enter the correct password',
        'HowTo': 'HowTo',
        'HowToDetail': 'To save a bookmark, first select the desired passage and then push the button to save it.',

        'newFromTemplate': 'New from Template',
        'openDots': 'Open ...',
        'createTemplate': 'create Template',
        'editTemplates': 'edit Templates',
        'editTemplateTitle': 'edit Template Title',
        'editEntry': 'edit Entry',
        'editDetail': 'edit Detail',
        'newChecklistTemplate': 'Checklist Template Name',
        'checklistTemplateName': 'Please enter a Name for new Checklist Template',
        'newChecklistTemplateEntry': 'new Checklist Template Entry',
        'newChecklistTemplateDetail': 'new Checklist Template Entry Detail',
        'newEntry': 'new Entry',
        'newDetail': 'new Detail',
        'orderValue': 'order value',
        'text': 'Text',
        'image': 'Image',
        'deleteTemplate': 'Delete Template',
        'reallyDeleteTemplate': 'Do you really want to delete this Template?',
        'deleteEntry': 'Delete Entry',
        'reallyDeleteEntry': 'Do you really want to delete this Entry?',
        'deleteDetail': 'Delete Detail',
        'reallyDeleteDetail': 'Do you really want to delete this Detail?',
        'noChecklistTemplate': 'No Templates available',
        'enterDetailTextOrPicture': 'Please enter a Detail Text or choose a Picture',
        'enterEntryTitle': 'Please enter a Entry Title',
        'enterEntryDescription': 'Please enter a Entry Description',
        'enterTemplateTitle': 'Please enter a new Template Title',
        'createTemplateFirst': 'Please create a Template first!',
        'selectEntry': 'Please select a Entry first!',
        'checklistFinished': 'Checklist Finished',
        'checklistFinishedDetail': 'You have processed this Checklist successfully.',
        'finishedTime': 'Completed at ',

        'generalInfoLabel': 'General:',
        'help.title.general.welcome': 'Welcome',
        'help.body.general.welcome': 'Willkommen auf der Hilfeseite der weCoop Application.<br/>Dieser Bereich ist unterteilt nach den verschiedenen Menüpunkten und dient der ersten Erklärung, was wie wo passiert, ausgenommen der Bereich des Administrators. Bei Problemen oder hilfegesuchen, die hier nicht geklärt werden können, wenden Sie sich bitte an <br/>wecoop@webware-experts.de<br/>ihr Anliegen wird schnellst möglich bearbeitet.',
        'help.title.general.firstToKnow': 'First to know',
        'help.body.general.firstToKnow': 'If you don`t see the Navigation Points "Whiteboard, Video, Documents" upside this panel, you have to choose a room first. Any communication in this tool occur between members in the selected rooms.<br/>You can choose a room in all-route-present Button at top right of every pages, without selected room it is named "kein Raum" otherwise its named the room you chosen.',
        'help.title.general.navigation': 'Navigation',
        'help.body.general.navigation': 'If selected a room, you have the possibility to choose one of 4 (5 as admin) options in the top navigation bar:<ul><li>Whiteboard: exchange and discuss pictures</li><li>Video: real time video communication</li><li>Documents: share selected documents</li><li>Administration: where the admin administrate administration</li></ul>',

        'navbarPanelsLabel': 'Navbar Buttons:',
        'help.title.navbar.firstToKnow': 'Generally',
        'help.body.navbar.firstToKnow': 'Die Tasten auf der rechten Seite der Navigationsleiste steuern die folgenden Punkte unabhängig davon, in welchem Menüpunkt man sich gerade befindet.',
        'help.title.navbar.username': 'Username',
        'help.body.navbar.username': 'Diese Taste zeigt an, unter welchem Namen man eingeloggt ist. Dieser Name erscheint bei den anderen unter dem Punkt Online, wenn sie Nachrichten verschicken als Absender, als Titel über ihrem Bild im Menüpunkt Video und beim Anlegen der Lesezeichen unter dem Menüpunkt "geteilte Dokumente".<br/> Das kleine Icon hinter dem Namen zeigt auch an, ob die eigene Stimme gerade übertragen wird: blaues, durchgestrichenes Mikrofon bedeutet das keine Sprachverbindung besteht, ein rotes Mikrofon bedeutet das die eigene Sprache über das angeschlossene Mikrofon übertragen wird. Mit einem Druck auf diesen Button schaltet man zwischen diese 2 Modis hin- und her.Dieses funktioniert aber nur, wenn man sich die Teilnehmer innerhalb eines Raumes befindet.',
        'help.title.navbar.room': 'Room',
        'help.body.navbar.room': 'Mit dieser Taste kann der Nutzer zwischen die verschiedenen Räume innerhalb dieser Application hin-/ und herschalten. Es werden nur die Räume angezeigt, zu der dieser User auch eingeladen ist, mit der Ausnahme der Administratoren, diese können alle Räume sehen',
        'help.title.navbar.online': 'Online',
        'help.body.navbar.online': 'Das ist der Online-Anzeiger. In der kleinen Pille auf dem Button sieht man die Anzahl aktuell angemeldeter Nutzer. Beim Klick öffnet sich die gesamte Liste. Auf dieser Liste erscheinen alle gerade angemeldeten Nutzer und ihr momentaner Standort innerhalb dieser Applikation. Mit einem Klick auf die Namen können private Kurznachrichten verschickt werden.',
        'help.title.navbar.chat': 'Chat',
        'help.body.navbar.chat': 'Dies ist der Button zum Öffnen des Chatfensters. Die Zahl hinter dem Wort "Chat" bedeutet, wie viele ungelesene Nachrichten Sie bereits haben. Im eigentlichen Chatfenster, welches sich durch ein Klick auf den Knopf öffnet, sehen sie alle bisher in diesem Raum geschriebenen Nachrichten sortiert nach Absende Zeitpunkt. Man kann lesen, wann die Nachricht geschickt wurde und von wem. Private Nachrichten erscheinen ebenfalls in diesem Fenster, sind natürlich nur für Sie selbst und dem Absender sichtbar.',
        'help.title.navbar.logout': 'Logout',
        'help.body.navbar.logout': 'Bei dieser Taste wird der Nutzer ausgeloggt. Die geänderten Daten werden in der Regel bei Ausführung einer Aktion sofort gespeichert, sodas kein Datenverlust auftritt. Einen extra "Speichern" Knopf ist daher nicht nötig.',

        'whiteboardLabel': 'Whiteboard:',
        'help.title.whiteboard.firstToKnow': 'Common',
        'help.body.whiteboard.firstToKnow': 'Auf dem Whiteboard haben Sie und ihre Teammitglieder die möglichkeit, Bilder zu teilen (lokal gesicherte oder aus der App fotografierte) und diese mit verschiedene, veränderbare Marker zu beschriften. Man kann vorher ausgewählte Bereiche vergrößern. Auch kann man freihandformen auf dem Whiteboard malen und all dies passiert in Echtzeit bei allen eingeloggten Nutzern, die sich im gleichen Raum befinden.',
        'help.title.whiteboard.fileimport': 'Picture-/ Fileimport',
        'help.body.whiteboard.fileimport': 'Der erste Abschitt auf der linken Seite bezieht sich auf den Bild Import und Export. Der erste Button ist für den Import zuständig, dabei wird erst nachgesehen, ob eine lokale Kamera vorhanden ist und dann darf man auswählen, ob ein Bild aufgenommen werden soll oder ein lokal gespeichertes Bild hochgeladen werden soll. Der 2. Button speichert das aktuelle Whiteboard als *.png file in den regulären, lokalen Download Ordner.',
        'help.title.whiteboard.level': 'Layers & Delete',
        'help.body.whiteboard.level': 'Mit den oberen 2 Knöpfen kann das ausgewählte Objekt um je eine Ebene nach oben und unten gestellt werde. Mit dem 3. Knopf löscht man die aktuell ausgewählte Ebene und mit dem 4. wird das komplette Whiteboard geleert. VORSICHT: Alle darauf gespeicherten Daten sind dann unwiederbringlich weg!',
        'help.title.whiteboard.enlarge': 'Enlarge',
        'help.body.whiteboard.enlarge': 'Mit dem ersten Knopf wird ein rechteckiger Rahmen erzeugt. dieser ist dazu gedacht, Objektausschnitte zu vergrößern. Nach dem erzeugen des Rechtecks muß dieser per Drag&Drop über das gewünschte Ziel gezogen werden. Der 2. Button erzeugt schliesslich die Vergrößerung.',
        'help.title.whiteboard.freehand': 'Freehand drawing',
        'help.body.whiteboard.freehand': 'Über dieses Feld wird die Funktion zum Freihandzeichnen aktiviert. Nach einem Klick öffnet sich ein kleines Fenster, in dem man die gewünschte Farbe und die Strichstärke auswählt. Danach auf "Fertig" drücken. Nun werden bei gedrückter Maustaste die Freihandformen gezeichnet. Diese werden jetzt noch nicht an die anderen Nutzer übertragen. Wenn man fertig ist, wird der Button für die Freihandformen erneut geklickt, erst jetzt wird die Form gespeichert und an die anderen geteilt.',
        'help.title.whiteboard.marker': 'Marker',
        'help.body.whiteboard.marker': 'Das erste Feld öffnet ein Modal, in dem man eigenen Text eingeben kann, die Pfeilfarbe festlegt und auch die Pfeilrichtung. Das zweite Feld führt Sie durch 3 kleine Fenster, in der man nacheinander die Zahl, Farbe und Pfeilrichtung festlegt. Die restlichen Buttons sind Shortcuts für vordefinierte Pfeile.',

        'videoLabel': 'Video:',
        'help.title.video.firstToKnow': 'Universal',
        'help.body.video.firstToKnow': 'Im Bereich "Video" kann man sich mit Hilfe der Kameras und Mikrofone per Videoübertragung unterhalten. Dadurch können auch einfacher detailierte Fehlerbeschreibungen generiert werden, womit sich der Vor-Ort Techniker hilfe vom Remote Techniker holen kann, der irgendwo auf dieser Welt sitzt.',
        'help.title.video.chooseInput': 'choose Input',
        'help.body.video.chooseInput': 'Nach betreten des Menüpunktes "Video" wird als erstes erwartet, das Sie ihre benötigten Kommunikationsgeräte auswählen. Der obere Abschnitt bezieht sich auf die Videoquelle, der untere auf die Audioquelle.',
        'help.title.video.partition': 'Partition',
        'help.body.video.partition': 'Die Aufteilung unterscheidet sich hier, je nach gewähltem Modus oder Bildschirmgröße.<br/>Groß bzw Desktop:<br/>Hier findet sich das eigene Videobild auf der rechten Seite in der Größe 3/12 des Fensters. Im Panel Kopf hiervon sind auch die Buttons für die Optionen. Alle weiteren Nutzer befinden sich inder gleichen Größe direkt dadrunter. Ein ausgewähltes Video wird in 9/12 Größe auf der linken Bildschirmseite angezeigt.<br/>Klein bzw Mobile:<br/>Hier befindet sich das eigene Videobild in voller Fensterbreite ganz oben. Die Options-Knöpfe sind in diesem Panelheader aufgelistet. Die Videobilder der anderen Teilnehmer befinden sich ebenfalls in voller Fensterbreite direkt untereinander.',
        'help.title.video.options': 'Options',
        'help.body.video.options': 'Die Buttons bewirken von links nach rechts:<ul><li>Durch ein Mausklick auf das Videobild des anderen Teilnehmers (das große) wird bei Ihnen UND beim anderen Teilnehmer ein Marker angezeigt.</li><li>Dieser Button macht ein Screenshot vom aktuellem großen Videobild und speichert dieses im Download-Ordner</li><li>Mute-Button: das eigene Mikrofon wird stumm geschaltet</li><li>umschalten zwischen großer und kleiner Ansicht bzw zwischen Desktop und Mobile</li><li>neuauswahl der gewünschten Video- und Audiohardware</li></ul>',

        'documentationLabel': 'Documentation:',
        'help.title.documentation.firstToKnow': 'Allround',
        'help.body.documentation.firstToKnow': 'Die Dokumentation teilt sich in 2 Unterbereiche, die "geteilte Dokumente" und "Bibliothek".<br/>geteilte Dokumente:<br/>Hier werden pro Fall neue Dokumente auf Nachfrage oder spezifische Recherche hochgeladen.<br/>Bibliothek:<br/>Die Bibliothek wird für diesen Maschinentyp angelegt und enthält im besten Fall sämtliche Dokumente, die für diesen Maschinentype relevant sind.',
        'help.title.documentation.shared': 'shared Documents',
        'help.body.documentation.shared': 'Dieser Bereich ist eher für die kurzweilige Kommunikation zwischen dem Remote- und Vor-Ort Techniker gedacht. Es werden bestimmte Dokumente hochgeladen und die benötigten Passagen markiert und gespeichert, für alle Raummitglieder gleichzeitig.',
        'help.title.documentation.shared.partition': 'shared Documents - Partition',
        'help.body.documentation.shared.partition': 'Auch hier befindet sich das bekannte 9/12 | 3/12 Raster. Im rechten Teil befindet sich oben eine Liste aller hochgeladener Dokumente. Im Panel da drunter ist eine Liste aller angelegten Lesezeichen dieses ausgewähltem Dokuments. Auf der linken Seite befindet sich in groß das ausgewählte PDF mit den gängigen Optionen.',
        'help.title.documentation.shared.files': 'shared Documents - Files',
        'help.body.documentation.shared.files': 'In dieser Liste befinden sich alle in diesem Raum hochgeladenen Dokumente mitsamt Größenangabe. Durch einen Klick auf den entsprechendem Eintrag wird dieses zur Großansicht ausgewählt. Mit dem roten X am hinterem Ende kann diese Datei vom Server gelöscht werden. Eine neue Datei lädt man über das grüne + im Panelheader hoch; es öffnet sich ein neues Kontextmenü über das man einen Namen vergibt und die gewünschte Datei vom lokalen Dateisystem auswählt.',
        'help.title.documentation.shared.main': 'shared Documents - Mainwindow',
        'help.body.documentation.shared.main': 'Im großen Hauptfenster wird das aktuell ausgewählte Dokument dargestellt. Es enthält im Kopf auch die üblichen PDF-Funktionen. Ganz links kann man die Darstellungsgröße ändern, in der Mitte sieht man aktuelle/maximale Seitenzahl und "gehe zu Seite", am rechten Rand sind Buttons um zur ersten Seite zu springen, zur vorherigen Seite, zur nächsten Seite und zur letzten Seite.',
        'help.title.documentation.shared.bookmarks': 'shared Documents - Bookmark',
        'help.body.documentation.shared.bookmarks': 'Diese Liste alle Lesezeichen, die in diesem Dokument angelegt wurden. Durch einen Klick auf ein Lesezeichen wird im PDF auf diese Seite gesprungen und die markierte Stelle grün dargestellt. Mit dem roten X am hinterem Ende kann dieses Lesezeichen vom Server gelöscht werden. Ein neues Lesezeichen wird über das grüne + im Panelheader angelegt; erst muß die gewünschte Stelle im Dokument mit der Maus markiert werden, danach ein Klick auf den Erstellen Button, es öffnet sich ein neues Kontextmenü über das man einen Titel vergibt.',
        'help.title.documentation.library': 'Library',
        'help.body.documentation.library': 'Hier befinden sich alle Dokumente, die zu diesem Maschinentyp zusammengetragen wurde. Die Dokumente sind unterteilt nach Tags und Kategorien, damit die suche nach dem passenden Dokument sich deutlich verkürzt.',
        'help.title.documentation.library.partition': 'Library - Partitions & Functions',
        'help.body.documentation.library.partition': 'In der Maschinentyp Bibliothek sieht man auf der linken Seite eine Spalte mit sämtlichen, vergebenen Tags. Ein Klick auf einen Tag lässt sofort alle Ergebnisse nach dem gewünschten Tag filtern. Daneben kommt eine Auflistung aller Dokumente, die den gewählten Filtern entsprechen. Oben drüber ist noch eine kategorische Aufteilung der Dokumente nach Themengebieten, die ebenfalls auf die Filterung Einfluss hat. Wählt man nun ein Dokument aus, öffnet sich unter der Auflistung noch einmal eine Ansicht mit Detailierteren Angaben zum gewähltem Dokument. Dieses ist dann auch der Link, um sich das Dokument vom Server zu laden und auf der rechten Seite anzeigen zu lassen.',

        'checklistLabel': 'Checklist:',
        'help.title.checklist.firstToKnow': 'in Principle',
        'help.body.checklist.firstToKnow': 'Checklisten sind Sammlungen von Text und Bild, die unterstützend zur Durchführung regelmässiger Arbeiten zur Verfügung stehen. Sie werden einmalig als Vorlagen angelegt und gespeichert. Soll ein Mitarbeiter eine Checkliste abarbeiten, wählt er aus den Vorlagen die richtige aus und geht diese dann Punkt für Punkt durch.',
        'help.title.checklist.newFromTemplate': 'new from Template',
        'help.body.checklist.newFromTemplate': 'Hier wird eine neue Checkliste aus allen Vorlagen ausgewählt, die danach abgearbeitet werden kann.',
        'help.title.checklist.open': 'Open',
        'help.body.checklist.open': 'In diesem Bereich werden die ausgewählten Checklisten abgearbeitet. Das Handling ist denkbar einfach:<br/>Aus dem Modal wird eine Checkliste ausgewählt, anschliessend klickt man sich durch die einzelnen Einträge und sieht sich die jeweiligen Details zu jeden Eintrag an. Ist dieser Eintrag abgearbeitet, klickt man auf das gelbe X am Anfang, damit wird dieser Punkt abgehakt. Sind alle Punkte erledigt, wird diese Checkliste als ganzes abgeschlossen.',
        'help.title.checklist.editTemplate': 'edit Template',
        'help.body.checklist.editTemplate': 'Hier werden neue Vorlagen erstellt, genauso wie bestehende Vorlagen bearbeitet werden können. Die Ansicht hier ist 3 Spaltig:<ul><li>Links sind die Vorlagen</li><li>Mittig sind die Einträge zur gewählten Vorlage</li><li>Rechts sind die Details zum gewählten Eintrag</li></ul>Mit dem grünen Button im Panelheader kann man ein neues Objekt erzeugen, im anschliessend sich öffnenden Modal wird man nach den nötigen Informationen gefragt.<br/>Jeder Eintrag kann mit dem Stift Symbol am rechten Rand editiert werden, über dem Mülleimer wir dieser Eintrag gelöscht. Am linkem Rand befindet sich eine Zahl mit kleinen Pfeilen:Die Zahl sagt aus, an welcher Stelle der Reihenfolge dieses Objekt erscheint, mit den kleinen Pfeilen verschiebt man dieses nach oben oder unten.'
    };
});