define('whiteboard2-frontend/helpers/finished-timestamp', ['exports', 'ember'], function (exports, _ember) {
    exports.finishedTimestamp = finishedTimestamp;

    function finishedTimestamp(params) {
        var checker = params[0];
        var outputString = "open";
        if (checker) {
            var reDate = new Date(checker);
            var localDate = reDate.toLocaleDateString();
            var localTime = reDate.toLocaleTimeString();
            outputString = localTime + ' - ' + localDate;
        }
        return outputString;
    }

    exports['default'] = _ember['default'].Helper.helper(finishedTimestamp);
});