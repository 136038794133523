define('whiteboard2-frontend/conference/doclib/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({
        activate: function activate() {
            _whiteboard2FrontendApp['default'].onlinePosition = 'Doclib';
            _ember['default'].run.later(function () {
                _whiteboard2FrontendApp['default'].conference.askOthers();
            }, 1000);
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                this.send('afterRender');
            });
        },
        actions: {
            afterRender: function afterRender() {
                var w = window.innerHeight - 72;
                var c = document.getElementById('we-iframe-container');
                c.style.height = w + "px";
            }
        }
    });
});