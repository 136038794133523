define('whiteboard2-frontend/conference/administration/customers/customer/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        i18n: _ember['default'].inject.service(),

        actions: {
            cancel: function cancel() {
                this.get('administration').send('newAttention', this.get('i18n').t('customerEditingCanceled'));
                this.transitionToRoute('conference.administration.customers');
            },

            saveCustomer: function saveCustomer() {
                this.get('administration').send('newAttention', this.get('i18n').t('customerEdited'));
                this.get('model').save();
                this.transitionToRoute('conference.administration.customers');
            }
        }
    });
});