define('whiteboard2-frontend/conference/whiteboard/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        queryParams: {
            size: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel() {
            var topicKnown = _whiteboard2FrontendApp['default'].topicWBRoom;
            if (topicKnown === undefined) {
                this.transitionTo('conference');
            }
        },

        activate: function activate() {
            _whiteboard2FrontendApp['default'].onlinePosition = 'Whiteboard';
            this.controllerFor('conference/whiteboard').send('runAfterRender');
            _ember['default'].run.later(function () {
                _whiteboard2FrontendApp['default'].conference.askOthers();
            }, 1000);
        },

        model: function model(params) {
            return this.get('store').query('picture', params);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
            model.forEach(function (thisPicture) {
                thisPicture.set('selected', false);
                var pictureUrl = thisPicture.get('url');
                var pictureJwtUrl = pictureUrl + "?jwt=Bearer " + lsat;
                thisPicture.set('jwtUrl', pictureJwtUrl);
                var jwtThumbnailUrl = pictureJwtUrl.replace('/api/uploads/', '/api/uploads/thumbnail_');
                thisPicture.set('jwtThumbnailUrl', jwtThumbnailUrl);
            });
            controller.set('model', model);
            controller.set('meta', model.meta);
            var shortModel = [];
            var sortData = model.sortBy('created');
            var reversedSortData = sortData.reverse();
            var reversedSortDataLength = reversedSortData.length;
            var forCounter = 10;
            if (reversedSortDataLength < 10) {
                forCounter = reversedSortDataLength;
            }
            for (var ic = 0; ic < forCounter; ic++) {
                var thisModel = reversedSortData.objectAt(ic);
                shortModel.pushObject(thisModel);
            }
            controller.set('shortModel', shortModel);
        },

        actions: {
            refreshModel: function refreshModel() {
                this.refresh();
            }
        },

        deactivate: function deactivate() {
            this.controllerFor('conference/whiteboard').cDeleteAll();
        }
    });
});