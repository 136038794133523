define('whiteboard2-frontend/conference/documentsharing/pdf/route', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(param) {
            return this.get('store').findRecord('attachment', param.pdf_id);
        },

        setupController: function setupController(controller, model) {
            controller.set('selectedFile', model);
            var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
            var fileURI = model.get('url');
            var pdfUrl = _whiteboard2FrontendApp['default'].API_HOST + fileURI + "?jwt=Bearer " + lsat;
            controller.set('pdfUrl', pdfUrl);

            controller.set('refresh', false);
            _ember['default'].run.next(function () {
                controller.set('refresh', true);
            });
            var bookmarks = model.get('bookmarks');
            bookmarks.forEach(function (item) {
                item.get('bookmarkLines');
            });
            controller.set('bookmarks', bookmarks);
            controller.set('model', model);
        },

        renderTemplate: function renderTemplate() {
            this.render('bookmark', {
                into: 'conference/documentsharing',
                outlet: 'pdfBookmarks'
            });
            this.render('conference/documentsharing/pdf', {
                into: 'conference/documentsharing',
                outlet: 'documentView'
            });
        },

        activate: function activate() {
            _whiteboard2FrontendApp['default'].docushare.set('pdfLoaded', true);
        },

        deactivate: function deactivate() {
            this.controllerFor('conference/documentsharing').set('pdfLoaded', false);
        }
    });
});