define('whiteboard2-frontend/components/template-entries/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'table',
        classNames: ['table'],
        sortedEntries: _ember['default'].computed.sort('entries', 'sortDefinition'),
        sortDefinition: ['orderValue'],

        actions: {
            selectThisEntry: function selectThisEntry(entry) {
                this.sendAction('selectThisEntry', entry);
            },
            deleteThisEntry: function deleteThisEntry() {
                this.sendAction('deleteThisEntry');
            },
            editThisEntry: function editThisEntry(entry) {
                this.sendAction('editThisEntry', entry);
            },
            pullUpThisEntry: function pullUpThisEntry(entry) {
                var _this = this;

                var oldEntryOrder = entry.get('orderValue');
                if (oldEntryOrder > 0) {
                    (function () {
                        var newEntryOrder = oldEntryOrder - 1;
                        var entries = _this.get('entries');
                        entries.forEach(function (thatEntry) {
                            var thatEntryOrder = thatEntry.get('orderValue');
                            if (newEntryOrder === thatEntryOrder) {
                                thatEntry.set('orderValue', thatEntryOrder + 1);
                                thatEntry.save();
                            }
                        });
                        entry.set('orderValue', newEntryOrder);
                        entry.save();
                    })();
                }
            },
            pullDownThisEntry: function pullDownThisEntry(entry) {
                var oldEntryOrder = entry.get('orderValue');
                var newEntryOrder = oldEntryOrder + 1;
                var entries = this.get('entries');
                entries.forEach(function (thatEntry) {
                    var thatEntryOrder = thatEntry.get('orderValue');
                    if (newEntryOrder === thatEntryOrder) {
                        thatEntry.set('orderValue', thatEntryOrder - 1);
                        thatEntry.save();
                    }
                });
                entry.set('orderValue', newEntryOrder);
                entry.save();
            }
        }
    });
});