define('whiteboard2-frontend/conference/administration/rooms/new/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        rooms: _ember['default'].inject.controller('conference.administration.rooms'),
        i18n: _ember['default'].inject.service(),
        chosenUser: [],
        leftRegular: false,

        actions: {
            saveNewRoom: function saveNewRoom() {
                var room = this.get('model');
                var that = this;
                var chosenUsers = this.get('chosenUser');
                var roomName = room.get('name');
                var roomDescription = room.get('description');
                if (!roomName) {
                    that.get('administration').send('newAttention', that.get('i18n').t('enterRoomName'));
                } else if (!roomDescription) {
                    that.get('administration').send('newAttention', that.get('i18n').t('enterRoomDescription'));
                } else {
                    chosenUsers.forEach(function (thisUser) {
                        var thisLoadedUser = that.get('store').peekRecord('user', thisUser.id);
                        room.get('invitedUsers').pushObject(thisLoadedUser);
                    });
                    room.save().then(function () {
                        _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicSystem, [], {
                            beOf: 'nr'
                        });
                        that.set('leftRegular', true);
                        that.get('rooms').send('refreshIt');
                        that.get('administration').send('newAttention', that.get('i18n').t('newRoomCreated'));
                        that.transitionToRoute('conference.administration.rooms');
                    });
                }
            },

            cancel: function cancel() {
                this.get('chosenUser').clear();
                this.get('administration').send('newAttention', this.get('i18n').t('newRoomCanceled'));
                this.transitionToRoute('conference.administration.rooms');
            },

            selectionChanged: function selectionChanged(user) {
                var users = this.get('users').get('content').get('content');
                var thisUser = users[user];
                this.get('chosenUser').addObject(thisUser);
            },

            removeThisUser: function removeThisUser(chosenUser) {
                this.get('chosenUser').removeObject(chosenUser);
            }
        }

    });
});