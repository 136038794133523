define('whiteboard2-frontend/conference/checklist/open/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        activeChecklist: null,

        actions: {
            firstRunAfterRender: function firstRunAfterRender() {
                _ember['default'].run.later(this, function () {
                    $('#modalNewChecklist').modal('show');
                }, this, 500);
            },

            loadThisChecklist: function loadThisChecklist(checklist) {
                $('#modalNewChecklist').modal('hide');
                this.set('activeChecklist', checklist);
            },

            checkThatEntry: function checkThatEntry(activeChecklistEntry) {
                var thisState = activeChecklistEntry.get('done');
                activeChecklistEntry.set('done', !thisState);
                activeChecklistEntry.save();
                var howMuchOpen = 0;
                var activeChecklist = this.get('activeChecklist');
                var allEntries = activeChecklist.get('entries');
                allEntries.forEach(function (thisEntry) {
                    var alreadyDone = thisEntry.get('done');
                    if (!alreadyDone) {
                        howMuchOpen++;
                    }
                });
                if (howMuchOpen < 1) {
                    _ember['default'].run.later(this, function () {
                        this.send('refreshModel');
                        $('#modalFinishedChecklist').modal('show');
                    }, this, 500);
                }
            },

            selectThatEntry: function selectThatEntry(activeChecklistEntry) {
                var model = this.get('model');
                model.forEach(function (thisChecklist) {
                    thisChecklist.set('selected', false);
                });
                activeChecklistEntry.set('selected', true);
                this.set('activeChecklistEntry', activeChecklistEntry);
            }
        }
    });
});