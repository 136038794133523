define('whiteboard2-frontend/models/room', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        name: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        chatMessages: _emberData['default'].hasMany('message'),
        invitedUsers: _emberData['default'].hasMany('user'),
        attachments: _emberData['default'].hasMany('attachment'),

        selected: false
    });
});