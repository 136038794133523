define('whiteboard2-frontend/conference/picture/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        i18n: _ember['default'].inject.service(),
        queryParams: ['page'],
        page: 1,
        hasCam: false,
        hasTorch: false,
        torchState: false,
        hasZoom: false,

        selectedPicture: null,
        picToDelete: null,
        videoDevices: [],
        videoDeviceChosen: [],
        mediaOptions: {
            audio: false,
            video: false
        },
        photoDone: false,
        drawFreeHand: false,
        objStage: null,
        imageCapture: null,
        activeTrack: null,

        dataURItoBlob: function dataURItoBlob(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);

            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            var bb = new Blob([ab], { "type": mimeString });
            return bb;
        },

        localFileUpload: function localFileUpload() {
            var that = this;
            var uploadContent = "test";
            var input = document.getElementById('cameraCaptureInput');
            input.click();
            input.onchange = function () {
                var files = input.files;
                if (files != null) {
                    uploadContent = files[0];
                }
                var fd = new FormData();
                fd.append('file', uploadContent);
                var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;

                if (uploadContent) {
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: { 'Authorization': 'Bearer ' + lsat },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var newPicture = that.store.createRecord('picture', {
                                    url: data.path,
                                    name: 'doup',
                                    visibleForOthers: false,
                                    room: roomId
                                });
                                newPicture.save().then(function () {
                                    that.send('refreshModel');
                                });
                            } else {
                                // Handle errors here
                            }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                }
            };
            document.getElementById('makePictureButton').blur();
        },

        switchLight: function switchLight(mode) {
            if (this.get('hasTorch')) {
                var torchState = false;
                if (mode === "1") {
                    torchState = true;
                } else if (mode === "2") {
                    torchState = !this.get('torchState');
                }
                var activeTrack = this.get('activeTrack');
                if (activeTrack) {
                    activeTrack.applyConstraints({
                        advanced: [{ torch: torchState }]
                    });
                    this.set('torchState', torchState);
                }
            }
        },

        actions: {

            runAfterRender: function runAfterRender() {
                var that = this;
                var videoDevices = this.get('videoDevices');
                videoDevices.clear();
                var mediaOptions = this.get('mediaOptions');
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    for (var i = 0; i !== devices.length; ++i) {
                        var device = devices[i];
                        if (device.kind === 'videoinput') {
                            device.selected = false;
                            videoDevices.addObject(device);
                        }
                    }
                    if (videoDevices.length > 0) {
                        that.set('hasCam', true);
                        var noDevice = {
                            kind: 'videoinput',
                            deviceId: 0,
                            label: that.get('i18n').t('filesystem'),
                            selected: true
                        };
                        that.set('videoDeviceChosen', noDevice);
                        mediaOptions.video = false;
                        videoDevices.addObject(noDevice);
                    }
                });
            },

            selectThisPicture: function selectThisPicture(picture) {
                var model = this.get('model');
                model.forEach(function (thisPicture) {
                    thisPicture.set('selected', false);
                });
                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                var pictureJwtUrl = picture.get('url') + "?jwt=Bearer " + lsat;
                picture.set('jwtUrl', pictureJwtUrl);
                picture.set('selected', true);
                this.set('selectedPicture', picture);
                _ember['default'].run.later(this, function () {
                    var picturePanelBodyWidth = $('#picturePanelBody').width();
                    $('#pictureImages').width(picturePanelBodyWidth);
                }, this, 50);
            },

            shareThisPicture: function shareThisPicture(thisPicture) {
                var visibleToAll = thisPicture.get('visibleForOthers');
                if (visibleToAll) {
                    $('#modalUnSharePicture').modal('show');
                } else {
                    $('#modalSharePicture').modal('show');
                }
            },

            sharePictureAction: function sharePictureAction() {
                var picture = this.get('selectedPicture');
                var oldValue = picture.get('visibleForOthers');
                picture.set('visibleForOthers', !oldValue);
                picture.save();
            },

            deleteThisPicture: function deleteThisPicture(thisPic) {
                this.set('picToDelete', thisPic);
                $('#modalDeletePicture').modal('show');
            },

            deletePictureAction: function deletePictureAction() {
                var thisPic = this.get('picToDelete');
                thisPic.destroyRecord();
                this.set('selectedPicture', null);
            },

            newPicture: function newPicture() {
                var hasCam = this.get('hasCam');
                if (hasCam) {
                    $('#modalChooseInput').modal('show');
                } else {
                    this.localFileUpload();
                }
            },

            chosenInputDevice: function chosenInputDevice(thisDevice) {
                this.set('videoDeviceChosen.selected', false);
                var mediaOptions = this.get('mediaOptions');
                if (thisDevice.deviceId === 0) {
                    mediaOptions.video = false;
                } else {
                    mediaOptions.video = {
                        deviceId: thisDevice.deviceId
                    };
                }
                this.set('videoDeviceChosen', thisDevice);
                this.set('videoDeviceChosen.selected', true);
            },

            selectThisSource: function selectThisSource() {
                var _this = this;

                var that = this;
                var videoDeviceChosen = this.get('videoDeviceChosen');
                if (videoDeviceChosen.kind) {
                    if (videoDeviceChosen.deviceId === 0) {
                        this.localFileUpload();
                    } else {
                        $('#modalTakePicture').modal('show');
                        var takePictureCanvas = document.getElementById('takePictureCanvas');
                        var context = takePictureCanvas.getContext('2d');
                        context.clearRect(0, 0, takePictureCanvas.width, takePictureCanvas.height);
                        var takePictureVideo = document.getElementById('takePictureVideo');
                        var deviceId = videoDeviceChosen.deviceId;
                        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                            navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId } }).then(function (stream) {
                                takePictureVideo.srcObject = stream;
                                _ember['default'].run.later(_this, function () {
                                    var track = stream.getVideoTracks()[0];
                                    var capabilities = track.getCapabilities();
                                    if (capabilities.zoom) {
                                        (function () {
                                            that.set('hasZoom', true);
                                            var createZoomSlider = setInterval(function () {
                                                var pictureZoomSlider = document.getElementById('pictureZoomSlider');
                                                if (pictureZoomSlider) {
                                                    clearInterval(createZoomSlider);
                                                    pictureZoomSlider.min = capabilities.zoom.min;
                                                    pictureZoomSlider.max = capabilities.zoom.max;
                                                    pictureZoomSlider.step = capabilities.zoom.step;
                                                    pictureZoomSlider.value = track.getSettings().zoom;
                                                    pictureZoomSlider.oninput = function (event) {
                                                        track.applyConstraints({ advanced: [{ zoom: event.target.value }] });
                                                    };
                                                }
                                            }, 100);
                                        })();
                                    } else {
                                        that.set('hasZoom', false);
                                    }
                                    if (capabilities.torch) {
                                        that.set('hasTorch', true);
                                    } else {
                                        that.set('hasTorch', false);
                                    }
                                    var imageCapture = new ImageCapture(track);
                                    that.set('torchState', false);
                                    that.set('activeTrack', track);
                                    that.set('imageCapture', imageCapture);
                                    that.set('localstream', stream);
                                }, _this, 1000);
                            });
                        }
                    }
                }
            },

            takeHighResPic: function takeHighResPic() {
                var _this2 = this;

                var that = this;
                var imageCapture = this.get('imageCapture');
                imageCapture.takePhoto().then(function (highResBlob) {
                    var fd = new FormData();
                    fd.append('file', highResBlob);
                    var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;
                    var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                    $.ajax({
                        type: 'POST',
                        url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: {
                            'Authorization': 'Bearer ' + lsat
                        },
                        success: function success(data) {
                            if (typeof data.error === 'undefined') {
                                var newPicture = that.store.createRecord('picture', {
                                    url: data.path,
                                    name: 'doca',
                                    visibleForOthers: false,
                                    room: roomId
                                });
                                newPicture.save().then(function () {
                                    that.set('selectedObj', null);
                                    that.set('photoDone', false);
                                    $('#modalTakePicture').modal('hide');
                                    that.send('refreshModel');
                                });
                            } else {
                                // Handle errors here
                            }
                        },
                        error: function error() {
                            // Handle errors here
                        }
                    });
                    that.switchLight("0");
                    var stream = _this2.get('localstream');
                    var takePictureVideo = document.getElementById('takePictureVideo');
                    takePictureVideo.pause();
                    takePictureVideo.src = "";
                    stream.getTracks()[0].stop();
                })['catch'](function (err) {
                    console.error('takePhoto() failed: ', err);
                });
            },

            takePic: function takePic() {
                var _this3 = this;

                var photoDone = this.get('photoDone');
                var that = this;
                var takePictureVideo = document.getElementById('takePictureVideo');
                var takePictureCanvas = document.getElementById('takePictureCanvas');
                if (photoDone) {
                    var lsat;

                    (function () {
                        var objStage = _this3.get('objStage');
                        var dataUrl = objStage.toDataURL("image/png");
                        var blob = _this3.dataURItoBlob(dataUrl);
                        var fd = new FormData();
                        fd.append('file', blob);
                        var roomId = _whiteboard2FrontendApp['default'].chosenRoomId;

                        if (blob) {
                            lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];

                            $.ajax({
                                type: 'POST',
                                url: _whiteboard2FrontendApp['default'].API_HOST + "/api/uploads",
                                data: fd,
                                processData: false,
                                contentType: false,
                                headers: { 'Authorization': 'Bearer ' + lsat },
                                success: function success(data) {
                                    if (typeof data.error === 'undefined') {
                                        var newPicture = that.store.createRecord('picture', {
                                            url: data.path,
                                            name: 'doca',
                                            visibleForOthers: false,
                                            room: roomId
                                        });
                                        newPicture.save().then(function () {
                                            takePictureVideo.style.display = 'block';
                                            takePictureCanvas.style.display = 'block';
                                            that.set('selectedObj', null);
                                            objStage.destroy();
                                            $('#modalTakePicture').modal('hide');
                                            that.send('refreshModel');
                                        });
                                    } else {
                                        // Handle errors here
                                    }
                                },
                                error: function error() {
                                    // Handle errors here
                                }
                            });
                        }
                        _this3.set('photoDone', !photoDone);
                    })();
                } else {
                    var canvas;
                    var freeHandContext;
                    var freeHandImage;

                    (function () {
                        var takePictureContext = takePictureCanvas.getContext('2d');
                        takePictureContext.drawImage(takePictureVideo, 0, 0, 640, 480);

                        var tmpDataUrl = takePictureCanvas.toDataURL("image/png");

                        var stream = _this3.get('localstream');
                        takePictureVideo.pause();
                        takePictureVideo.src = "";
                        stream.getTracks()[0].stop();

                        that.set('photoDone', true);

                        var stage = new Konva.Stage({
                            container: 'canvasAfterPicture',
                            width: 640,
                            height: 480
                        });
                        var layer = new Konva.Layer();
                        stage.add(layer);
                        var imageObj = new Image();
                        imageObj.onload = function () {
                            var picTaken = new Konva.Image({
                                x: 0,
                                y: 0,
                                image: imageObj,
                                width: 640,
                                height: 480
                            });
                            layer.add(picTaken);
                            picTaken.moveToBottom();
                            layer.draw();
                        };
                        imageObj.src = tmpDataUrl;

                        takePictureVideo.style.display = 'none';
                        takePictureCanvas.style.display = 'none';

                        var isPaint = false;
                        var lastPointerPosition = undefined;
                        canvas = document.createElement('canvas');

                        canvas.width = 640;
                        canvas.height = 480;
                        freeHandContext = canvas.getContext('2d');

                        freeHandContext.strokeStyle = '#308DFF';
                        freeHandContext.lineJoin = "round";
                        freeHandContext.lineWidth = 3;
                        freeHandImage = new Konva.Image({
                            image: canvas,
                            x: 0,
                            y: 0,
                            width: 640,
                            height: 480,
                            strokeEnabled: false
                        });

                        freeHandImage.on('mousedown touchstart', function () {
                            isPaint = true;
                            lastPointerPosition = stage.getPointerPosition();
                        });

                        freeHandImage.on('mousemove touchmove', function () {
                            if (!isPaint) {
                                return;
                            }
                            freeHandContext.globalCompositeOperation = 'source-over';
                            freeHandContext.beginPath();
                            var localPos = {
                                x: lastPointerPosition.x - freeHandImage.x(),
                                y: lastPointerPosition.y - freeHandImage.y()
                            };
                            freeHandContext.moveTo(localPos.x, localPos.y);
                            var pos = stage.getPointerPosition();
                            localPos = {
                                x: pos.x - freeHandImage.x(),
                                y: pos.y - freeHandImage.y()
                            };
                            freeHandContext.lineTo(localPos.x, localPos.y);
                            freeHandContext.closePath();
                            freeHandContext.stroke();
                            lastPointerPosition = pos;
                            layer.draw();
                        });

                        freeHandImage.on('mouseup touchend', function () {
                            isPaint = false;
                        });
                        layer.add(freeHandImage);

                        stage.draw();
                        _this3.set('objStage', stage);
                        _this3.set('photoDone', !photoDone);
                        _this3.switchLight("0");
                    })();
                }
                document.getElementById('takePhotoButton').blur();
            },

            abortTakePic: function abortTakePic() {
                $('#modalTakePicture').modal('hide');
                var objStage = this.get('objStage');
                this.set('selectedObj', null);
                if (objStage) {
                    objStage.destroy();
                }
                var stream = this.get('localstream');
                var takePictureVideo = document.getElementById('takePictureVideo');
                takePictureVideo.pause();
                takePictureVideo.src = "";
                stream.getTracks()[0].stop();
                if (this.get('hasTorch')) {
                    this.switchLight("0");
                }
            },

            toggleLight: function toggleLight() {
                this.switchLight("2");
            },

            downloadThisImage: function downloadThisImage(thisPicture) {
                var dataURL = thisPicture.get('jwtUrl');
                var link = document.createElement("a");
                link.setAttribute("href", dataURL);
                link.setAttribute("download", "savedWhiteboard.png");
                link.click();
            },

            prevPage: function prevPage() {
                this.set('actionMode', 4);
                var page = this.get('page');
                this.set('page', page - 1);
            },

            nextPage: function nextPage() {
                this.set('actionMode', 6);
                var page = this.get('page');
                this.set('page', page + 1);
            }
        }
    });
});