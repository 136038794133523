define('whiteboard2-frontend/instance-initializers/i18n', ['exports'], function (exports) {
    exports['default'] = {
        name: 'i18n',
        initialize: function initialize(_ref) {
            var container = _ref.container;

            container.lookup('service:i18n').set('locale', calculateLocale());
        }
    };

    function calculateLocale() {
        // whatever you do to pick a locale for the user:
        return navigator.language || navigator.userLanguage || 'en-GB';
    }
});