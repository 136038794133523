define('whiteboard2-frontend/serializers/account', ['exports', 'ember-data/serializers/rest', 'ember-data'], function (exports, _emberDataSerializersRest, _emberData) {
    exports['default'] = _emberDataSerializersRest['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            user: {
                serialize: 'id',
                deserialize: 'id'
            },
            customer: {
                serialize: 'id',
                deserialize: 'id'
            }
        }
    });
});