define('whiteboard2-frontend/router', ['exports', 'ember', 'whiteboard2-frontend/config/environment'], function (exports, _ember, _whiteboard2FrontendConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _whiteboard2FrontendConfigEnvironment['default'].locationType,
        rootURL: _whiteboard2FrontendConfigEnvironment['default'].rootURL
    });

    Router.map(function () {
        this.route('conference', function () {
            this.route('whiteboard');

            this.route('videos', function () {
                this.route('live');
                this.route('record');
                this.route('guided');
            });

            this.route('documentsharing', function () {
                this.route('pdf', { path: 'pdf/:pdf_id' });
                this.route('images', { path: 'image/:image_id' });
            });
            this.route('doclib');
            this.route('picture');

            this.route('checklist', function () {
                this.route('new');
                this.route('template', function () {
                    this.route('edit');
                });
                this.route('open');
            });

            this.route('administration', function () {
                this.route('accounts', function () {
                    this.route('new');
                    this.route('account', { path: "account/:account_id" }, function () {
                        this.route('edit');
                    });
                });
                this.route('customers', function () {
                    this.route('new');
                    this.route('customer', { path: "customer/:customer_id" }, function () {
                        this.route('edit');
                    });
                });
                this.route('rooms', function () {
                    this.route('new');
                    this.route('room', { path: "room/:room_id" }, function () {
                        this.route('edit');
                    });
                });
            });

            this.route('helpscreen');
        });
        this.route('login');
        this.route('pwreset');
        this.route('registration');
    });

    exports['default'] = Router;
});