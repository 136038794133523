define('whiteboard2-frontend/app', ['exports', 'ember', 'whiteboard2-frontend/resolver', 'ember-load-initializers', 'whiteboard2-frontend/config/environment'], function (exports, _ember, _whiteboard2FrontendResolver, _emberLoadInitializers, _whiteboard2FrontendConfigEnvironment) {

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  var APP = _ember['default'].Application.extend({

    modulePrefix: _whiteboard2FrontendConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _whiteboard2FrontendConfigEnvironment['default'].podModulePrefix,
    Resolver: _whiteboard2FrontendResolver['default']
  });

  APP.API_HOST = 'https://' + _whiteboard2FrontendConfigEnvironment['default'].home;

  (0, _emberLoadInitializers['default'])(APP, _whiteboard2FrontendConfigEnvironment['default'].modulePrefix);

  exports['default'] = APP;
});
/*exported APP: true*/