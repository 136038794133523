define('whiteboard2-frontend/conference/videos/guided/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        i18n: _ember['default'].inject.service(),
        conference: _ember['default'].inject.controller('conference'),

        preSelectedRecord: null,
        selectedRecord: null,
        recordAlive: false,
        webrtc: null,
        videoCanvasContext: null,
        canvasRebuildInterval: null,
        stream: null,

        init: function init() {
            this._super();
            _whiteboard2FrontendApp['default'].video = this;
        },

        name: (function () {
            return _whiteboard2FrontendApp['default'].whoAmI;
        }).property('App.whoAmI'),

        dispatchEvent: function dispatchEvent(event) {
            switch (event.msgCat) {
                case 'recordChoosen':
                    this.recordChoosen(event);
                    break;
                case 'qRecordAlive':
                    this.answerAlivedRecord();
                    break;
                case 'aRecordAlive':
                    this.playWithAnswer(event.is);
                    break;
            }
        },

        actions: {

            runAfterRender: function runAfterRender() {
                var that = this;
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicRTCRoom, [], {
                    msgCat: 'qRecordAlive'
                });

                _ember['default'].run.later(function () {
                    that.send('ChooseRecordModal');
                }, 3000);
            },

            ChooseRecordModal: function ChooseRecordModal() {
                var recordAlive = this.get('recordAlive');
                if (!recordAlive) {
                    $('#modalChooseRecord').modal('show');
                } else {
                    this.send('buildCinemaClient');
                }
            },

            changeVideoRecord: function changeVideoRecord(thisRecord) {
                var oldSelected = this.get('preSelectedRecord');
                if (oldSelected) {
                    oldSelected.set('selected', false);
                }
                this.set('preSelectedRecord', thisRecord);
                thisRecord.set('selected', true);
            },

            abortSelectRecord: function abortSelectRecord() {
                console.log('abortSelectRecord');
                $('#modalChooseRecord').modal('hide');
            },

            recordChoosen: function recordChoosen() {
                var choosenRecord = this.get('preSelectedRecord');
                this.set('selectedRecord', choosenRecord);
                this.set('recordAlive', true);
                _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicRTCRoom, [], {
                    msgCat: 'recordChoosen',
                    vidId: choosenRecord.get('name')
                });
                $('#modalChooseRecord').modal('hide');
                this.send('buildCinemaHost');
            },

            buildCinemaHost: function buildCinemaHost() {
                console.log('buildCinemaHost');
                var that = this;
                var choosenRecord = this.get('selectedRecord');
                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                var videoJwtUrl = choosenRecord.get('url') + "?jwt=Bearer " + lsat;
                choosenRecord.set('jwtUrl', videoJwtUrl);
                var guidedVideoElement = document.getElementById('guidedVideo');
                guidedVideoElement.load();
                guidedVideoElement.preload = "auto";
                var stream = null;

                var videoStreamCanvas = document.getElementById('videoStreamCanvas');
                var canvasContext = videoStreamCanvas.getContext('2d');
                this.set('videoCanvasContext', canvasContext);

                var videoLoadedInterval = setInterval(function () {
                    var guidedVideoElementJ = $('#guidedVideo');
                    var videoWidth = guidedVideoElement.videoWidth;
                    var videoHeight = guidedVideoElement.videoHeight;
                    if (videoWidth > 0 && videoHeight > 0) {
                        var videoElementWidth = guidedVideoElementJ.width();
                        var videoElementHeight = guidedVideoElementJ.height();
                        videoStreamCanvas.width = videoElementWidth;
                        videoStreamCanvas.height = videoElementHeight;
                        canvasContext.fillRect(0, 0, videoElementWidth, videoElementHeight);
                        canvasContext.drawImage(guidedVideoElement, 0, 0, videoElementWidth, videoElementHeight);
                        stream = videoStreamCanvas.captureStream(25);
                        that.set('stream', stream);
                        console.log('stream: ', stream);
                        clearInterval(videoLoadedInterval);
                        that.send('startCanvasReDrawTimer');
                        that.send('beginRtcSession');
                    }
                }, 100);
            },

            startCanvasReDrawTimer: function startCanvasReDrawTimer() {
                var canvasContext = this.get('videoCanvasContext');
                var guidedVideoElementPur = document.getElementById('guidedVideo');
                var guidedVideoElementJ = $('#guidedVideo');
                var canvasRebuildInterval = setInterval(function () {
                    var videoWidth = guidedVideoElementJ.width();
                    var videoHeight = guidedVideoElementJ.height();
                    canvasContext.fillRect(0, 0, videoWidth, videoHeight);
                    canvasContext.drawImage(guidedVideoElementPur, 0, 0, videoWidth, videoHeight);
                }, 40);
                this.set('canvasRebuildInterval', canvasRebuildInterval);
            },

            beginRtcSession: function beginRtcSession() {
                var stream = this.get('stream');
                console.log('stream: ', stream);

                var videoTracks = stream.getVideoTracks();
                console.log('videoTracks: ', videoTracks);
                var firstVideoTrack = videoTracks[0];
                var mediaOptions = [];
                mediaOptions.video = {
                    deviceId: firstVideoTrack.id,
                    width: 1280,
                    heigth: 720
                };
                mediaOptions.audio = false;

                var webrtc = new SimpleWebRTC({
                    localVideoEl: 'guidedVideo',
                    remoteVideosEl: '',
                    autoRequestMedia: true,
                    debug: false,
                    detectSpeakingEvents: false,
                    autoAdjustMic: false,
                    nick: this.get('name'),
                    url: _whiteboard2FrontendApp['default'].API_HOST,
                    media: mediaOptions,
                    socketio: {
                        "force new connection": true
                    },
                    localVideo: {
                        mirror: false
                    }
                });

                this.set('webrtc', webrtc);

                var room = _whiteboard2FrontendApp['default'].topicRTCRoom;
                webrtc.on('readyToCall', function () {
                    console.log('Host readyToCall');
                    if (room) {
                        webrtc.joinRoom(room);
                    }
                });
            },

            buildCinemaClient: function buildCinemaClient() {
                console.log('buildCinemaClient');
                var webrtc = new SimpleWebRTC({
                    localVideoEl: '',
                    remoteVideosEl: '',
                    autoRequestMedia: true,
                    debug: false,
                    detectSpeakingEvents: false,
                    autoAdjustMic: false,
                    nick: this.get('name'),
                    url: _whiteboard2FrontendApp['default'].API_HOST,
                    media: {
                        audio: false,
                        video: false
                    },
                    socketio: {
                        "force new connection": true
                    },
                    localVideo: {
                        mirror: false
                    }
                });

                this.set('webrtc', webrtc);

                var room = _whiteboard2FrontendApp['default'].topicRTCRoom;
                webrtc.on('readyToCall', function () {
                    console.log('Client readyToCall');
                    if (room) {
                        webrtc.joinRoom(room);
                    }
                });

                webrtc.on('videoAdded', function (video, peer) {
                    console.log('client video: ', video);
                    console.log('client peer: ', peer);
                    var guidedVideo = document.getElementById('guidedVideo');
                    if (guidedVideo) {
                        guidedVideo.controls = false;
                        guidedVideo.width = 0;
                        guidedVideo.height = 0;
                    }
                    var videoStreamCanvas = document.getElementById('videoStreamCanvas');
                    if (videoStreamCanvas) {
                        videoStreamCanvas.width = 0;
                        videoStreamCanvas.height = 0;
                    }
                    var recordVideoPanelBody = document.getElementById('recordVideoPanelBody');
                    if (recordVideoPanelBody) {
                        video.className = 'col-lg-12';
                        recordVideoPanelBody.appendChild(video);
                        // suppress contextmenu
                        video.oncontextmenu = function () {
                            return false;
                        };
                        video.onclick = function (thisEvent) {
                            console.log('client thisEvent: ', thisEvent);
                        };
                    }
                });
            },

            runOnDeactivate: function runOnDeactivate() {
                var canvasRebuildInterval = this.get('canvasRebuildInterval');
                if (canvasRebuildInterval) {
                    clearInterval(canvasRebuildInterval);
                    this.set('canvasRebuildInterval', null);
                }
                this.set('recordAlive', false);
                this.set('preSelectedRecord', null);
                this.set('selectedRecord', null);
                this.set('webrtc', null);
            }
        },
        // WAMP Actions

        recordChoosen: function recordChoosen(thisEvent) {
            console.log('recordChoosen wamp: ', thisEvent);
        },

        answerAlivedRecord: function answerAlivedRecord() {
            var recordAlive = this.get('recordAlive');
            _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicRTCRoom, [], {
                msgCat: 'aRecordAlive',
                is: recordAlive
            });
        },

        playWithAnswer: function playWithAnswer(answer) {
            this.set('recordAlive', answer);
        }

    });
});