define('whiteboard2-frontend/conference/administration/accounts/account/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('conference.administration.accounts.account', {
                into: 'conference.administration',
                outlet: 'editColumn'
            });
        },

        model: function model(params) {
            return this.get('store').findRecord('account', params.account_id);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            var userData = model.get('user');
            console.log('userData: ', userData);
        },

        afterModel: function afterModel(model) {
            var accounts = this.modelFor('conference.administration.accounts');
            accounts.forEach(function (account) {
                account.set('selected', false);
            });
            model.set('selected', true);
        }
    });
});