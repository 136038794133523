define('whiteboard2-frontend/conference/checklist/new/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        selectedTemplate: null,
        selectedTemplateEntry: null,

        actions: {

            selectThisChecklistTemplate: function selectThisChecklistTemplate(checklist) {
                var checklistTemplates = this.get('checklistTemplates');
                checklistTemplates.forEach(function (thisTemplate) {
                    thisTemplate.set('selected', false);
                });
                this.set('selectedTemplate', checklist);
                this.set('selectedTemplateEntry', null);
                checklist.set('selected', true);
            },

            selectThisChecklistTemplateEntry: function selectThisChecklistTemplateEntry(thisEntry) {
                var oldEntry = this.get('selectedTemplateEntry');
                if (oldEntry) {
                    oldEntry.set('selected', false);
                }
                this.set('selectedTemplateEntry', thisEntry);
                thisEntry.set('selected', true);
            },

            loadThisChecklistTemplate: function loadThisChecklistTemplate() {
                var that = this;
                var checklist = this.get('selectedTemplate');
                var templateId = checklist.id;
                var lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];
                $.ajax({
                    type: 'POST',
                    url: _whiteboard2FrontendApp['default'].API_HOST + "/api/checklist-templates/" + templateId + "/create-checklist",
                    data: '',
                    processData: false,
                    contentType: false,
                    headers: { 'Authorization': 'Bearer ' + lsat },
                    success: function success(data) {
                        if (typeof data.error === 'undefined') {
                            that.transitionToRoute('conference.checklist.open');
                        } else {
                            // Handle errors here
                        }
                    },
                    error: function error() {
                        // Handle errors here
                    }
                });
            }
        }
    });
});