define('whiteboard2-frontend/conference/documentsharing/images/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        init: function init() {
            var that = this;
            window.addEventListener('resize', function () {
                that.resizeWindow();
            });
        },

        resizeWindow: function resizeWindow() {
            var c = document.getElementById('documentImages');
            var ct = $('#main-pdf-container');
            if (c) {
                var ContainerWidth = ct.width();
                c.style.width = ContainerWidth + "px";
            }
        },

        actions: {
            firstRunAfterRender: function firstRunAfterRender() {
                var that = this;
                _ember['default'].run.later(function () {
                    that.resizeWindow();
                }, 1000);
            }
        }
    });
});