define('whiteboard2-frontend/conference/administration/rooms/room/edit/controller', ['exports', 'ember', 'whiteboard2-frontend/app'], function (exports, _ember, _whiteboard2FrontendApp) {
    exports['default'] = _ember['default'].Controller.extend({
        administration: _ember['default'].inject.controller('conference.administration'),
        i18n: _ember['default'].inject.service(),
        chosenUser: [],

        actions: {

            cancel: function cancel() {
                this.get('administration').send('newAttention', this.get('i18n').t('roomEditingCanceled'));
                this.transitionToRoute('conference.administration.rooms');
            },

            saveRoom: function saveRoom() {
                var that = this;
                var chosenUsers = this.get('chosenUser');
                var thisRoom = this.get('model');
                chosenUsers.forEach(function (thisUser) {
                    var thisLoadedUser = that.get('store').peekRecord('user', thisUser.id);
                    thisRoom.get('invitedUsers').pushObject(thisLoadedUser);
                });
                thisRoom.save().then(function () {
                    _whiteboard2FrontendApp['default'].WS_SESSION.publish(_whiteboard2FrontendApp['default'].topicSystem, [], {
                        beOf: 'nr'
                    });
                    that.set('chosenUser', []);
                    that.get('rooms').send('refreshIt');
                });
                this.get('administration').send('newAttention', this.get('i18n').t('roomEdited'));
                this.transitionToRoute('conference.administration.rooms');
            },

            selectionChanged: function selectionChanged(user) {
                var users = this.get('users').get('content').get('content');
                var thisUser = users[user];
                this.get('chosenUser').addObject(thisUser);
            },

            removeThisUser: function removeThisUser(chosenUser) {
                this.get('model.invitedUsers').removeObject(chosenUser);
            }
        }
    });
});