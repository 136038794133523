define('whiteboard2-frontend/locales/de/translations', ['exports'], function (exports) {
    exports['default'] = {
        'mainPage': 'weInspect',
        'home': 'Home',
        'login': 'Login',
        'whiteboard': 'Whiteboard',
        'menu': 'Menü',
        'video': 'Video',
        'sharedDocu': 'Geteilte Dokumente',
        'library': 'Bibliothek',
        'documentation': 'Dokumentation',
        'checklist': 'Checkliste',
        'admin': 'Admin',
        'help': 'Hilfe',
        'logout': 'Logout',
        'audioIs': 'Audio ist',
        'toggleTheme': 'Design umschalten',
        'chat': 'Chat',
        'online': 'Online',
        'impressum': 'Impressum',
        'contact': 'Kontakt',
        'copyright': 'Copyright © Webware Experts OHG 2018',

        'firstRooming': 'Bitte erst einen Raum wählen!',
        'noRoom': 'kein Raum',
        'privateMsgTo': 'Private Nachricht an',
        'reallyLogout': 'Möchten Sie diese Sitzung wirklich verlassen?',
        'confirmPassword': 'bestätige Passwort',
        'newPassword': 'neues Password',
        'mailToPWRecover': 'Geben Sie ihre Email Adresse hier ein, ihnen wird umgehend eine Mail zugesandt.',
        'olderMessagesAvailable': 'ältere Nachrichten verfügbar',
        'receivedNewMessage': 'neue Nachricht eingegangen',
        'reallyDelete': 'wirklich löschen',
        'noVideoDevice': 'kein Video Gerät',
        'connectingToPeer': ' - verbinde...',
        'connectionEstablished': ' - Verbindung hergestellt',
        'disconnected': ' - Verbindung unterbrochen',
        'connectionClosed': ' - Verbindung beendet',
        'videoMoved': 'Video im Großbild',
        'connectionFailed': 'Verbindung fehlgeschlagen',
        'chooseInputDevice': 'Wähle die Eingabegeräte',
        'chosenVideoDevice': 'gewähltes Videogerät',
        'chosenAudioDevice': 'gewähltes Audiogerät',
        'selectAObject': 'Bitte ein Rechteck auswählen',
        'notARect': 'gewähltes Objekt ist kein Rechteck',
        'notOverImage': 'Das Rechteck muß über ein Bild liegen',
        'newChecklist': 'neue Checkliste',
        'chooseChecklist': 'Wähle eine Checkliste aus',
        'checklistTemplates': 'Checklisten Vorlagen',
        'noActiveChecklist': 'keine aktive Checkliste',
        'noActiveChecklistEntry': 'kein aktiver Checklisteneintrag',
        'anErrorOccured': 'an Error Occured',

        'send': 'senden',
        'no': 'Nein',
        'yes': 'Ja',
        'on': 'An',
        'off': 'Aus',
        'abort': 'Abbrechen',
        'ok': 'Ok',
        'save': 'Sichern',
        'ready': 'Fertig',
        'success': 'Erfolgreich!',
        'back': 'zurück',
        'error': 'Fehler!',
        'attention': 'Achtung',
        'reset': 'Reset',
        'actions': 'Aktionen',
        'prev': 'Vorherige',
        'next': 'Nächste',
        'delete': 'Löschen',
        'editing': 'bearbeiten',
        'zoom': 'Zoom',
        'goToPage': 'Gehe zu Seite',
        'reload': 'nachladen',
        'close': 'schliessen',
        'state': 'Status',
        'picture': 'Bild',
        'choose': 'Wählen',
        'of': 'von',
        'record': 'Record',
        'live': 'Live',

        'id': 'ID',
        'name': 'Name',
        'msg': 'Nachricht',
        'time': 'Zeit',
        'email': 'Email',
        'password': 'Passwort',
        'userData': 'Benutzerdaten',
        'firstName': 'Vorname',
        'lastName': 'Nachname',
        'gender': 'Geschlecht',
        'male': 'männlich',
        'female': 'weiblich',
        'phone': 'Telefon',
        'title': 'Titel',
        'role': 'Rolle',
        'location': 'Ort',
        'additionalInfos': 'zusätzliche Infos',
        'details': 'Details',
        'description': 'Beschreibung',
        'longitude': 'Längengrad',
        'latitude': 'Breitengrad',
        'member': 'Bearbeiter',
        'TECHNICIAN': 'Techniker',
        'ADMIN': 'Administrator',
        'CUSTOMER': 'Kunde',

        'accounts': 'Accounts',
        'account': 'Account',
        'createNewAccount': 'neuen Account anlegen',
        'newAccountCreated': 'Neuer Account angelegt',
        'newAccountError': 'Neuer Account - Fehler',
        'newAccountCanceled': 'Neuer Account - Abgebrochen',
        'accountDeleted': 'Account gelöscht',
        'accountEditingCanceled': 'Account editieren - Abgebrochen',
        'accountEdited': 'Account editiert',
        'wantAccountOf': 'Möchten Sie den Account von',
        'pleasEnterMail': 'Bitte eine gültige Email Adresse eingeben',

        'room': 'Raum',
        'rooms': 'Räume',
        'createNewRoom': 'neuen Raum anlegen',
        'newRoomCreated': 'Neuer Raum angelegt',
        'newRoomError': 'Neuer Raum - Fehler',
        'newRoomCanceled': 'Neuer Raum - Abgebrochen',
        'roomDeleted': 'Raum gelöscht',
        'roomEditingCanceled': 'Raum editieren - Abgebrochen',
        'roomEdited': 'Raum editiert',
        'wantRoom': 'Möchten Sie den Raum',
        'chooseRoom': 'Raum wählen',
        'enterRoomName': 'Bitte einen Raum Namen eingeben',
        'enterRoomDescription': 'Bitte eine Raum Beschreibung eingeben',

        'customer': 'Kunde',
        'customers': 'Kunden',
        'createNewCustomer': 'neuen Kunden anlegen',
        'newCustomerCreated': 'Neuer Kunde angelegt',
        'newCustomerError': 'Neuer Kunde - Fehler',
        'newCustomerCanceled': 'Neuer Kunde - Abgebrochen',
        'customerDeleted': 'Kunde gelöscht',
        'customerEditingCanceled': 'Kunden editieren - Abgebrochen',
        'customerEdited': 'Kunde editiert',
        'wantCustomer': 'Möchten Sie den Kunde',
        'customerNameInput': 'Bitte Kundenname eingeben',
        'customerLocationInput': 'Bitte Kunden Standort eingeben',

        'bookmark': 'Lesezeichen',
        'bookmarks': 'Lesezeichen',
        'noBookmarks': 'keine Lesezeichen',
        'createNewBookmark': 'neues Lesezeichen anlegen',
        'newBookmarkCreated': 'Neues Lesezeichen angelegt',
        'newBookmarkError': 'Neues Lesezeichen - Fehler',
        'newBookmarkCanceled': 'Neues Lesezeichen - Abgebrochen',
        'bookmarkDeleted': 'Lesezeichen gelöscht',
        'bookmarkEditingCanceled': 'Lesezeichen editieren - Abgebrochen',
        'bookmarkEdited': 'Lesezeichen editiert',
        'wantBookmark': 'Möchten Sie das Lesezeichen',

        'pdf': 'PDF',
        'file': 'Datei',
        'files': 'Dateien',
        'upload': 'Upload',
        'wantPdf': 'Möchten Sie das PDF',
        'document': 'Dokument',

        'pictures': 'Bilder',
        'sharePicture': 'Bild teilen',
        'reallySharePicture': 'Möchten Sie das ausgewählte Bild wirklich mit ihrem Raum teilen',
        'unSharePicture': 'Bild nicht mehr teilen',
        'reallyUnSharePicture': 'Möchten Sie das ausgewählte Bild wirklich nicht mehr mit ihrem Raum teilen',
        'picToPicture': 'neues Bild aufnehmen',
        'noPicture': 'kein Bild vorhanden',
        'newPicture': 'neues Bild',
        'editable': 'editierbar',
        'highRes': 'hohe Auflösung',
        'whiteboardScreenshotAdded': 'Whiteboard Abbild den Bildern hinzugefügt',
        'videoScreenshotAdded': 'Video Abbild den Bildern hinzugefügt',
        'deletePicture': 'Bild löschen',
        'reallyDeletePicture': 'Möchten Sie das ausgewählte Bild wirklich löschen',
        'choosePicture': 'Bild auswählen',
        'whup': 'Whiteboard Upload',
        'whca': 'Whiteboard Kamera',
        'whss': 'Whiteboard Abbild',
        'doca': 'Dokumentation Kamera',
        'doup': 'Dokumentation Upload',
        'vid': 'Video',
        'videos': 'Videos',
        'newVideoName': 'neuer Video Titel',
        'deleteVideo': 'Video löschen',
        'reallyDeleteVideo': 'Möchten Sie das ausgewählte Video wirklich löschen',
        'noVideo': 'kein Video vorhanden',
        'videoAdded': 'Video hinzugefügt',
        'guided': 'Geführt',
        'chooseRecord': 'Aufzeichnung auswählen',

        'uploadPicture': 'Bild hochladen',
        'filesystem': 'Dateisystem',
        'docuPics': 'Dokumentation Bilder',
        'saveWhiteboard': 'Whiteboad speichern',
        'pushUp': 'nach Oben',
        'pushDown': 'nach Unten',
        'deleteElement': 'Element löschen',
        'clearCanvas': 'Leinwand leeren',
        'insertRect': 'Rechteck einfügen',
        'enlargeArea': 'Bereich vergrößern',
        'freehandDraw': 'Freihand zeichnen',
        'brushWidth': 'Pinselstärke',
        'setMark': 'Textmarke setzen',
        'createMark': 'Textmarke erstellen',
        'mark': 'Marker {{count}}',
        'white': 'Weiß',
        'blue': 'Blau',
        'green': 'Grün',
        'yellow': 'Gelb',
        'red': 'Rot',
        'up': 'Hoch',
        'down': 'Runter',
        'left': 'Links',
        'right': 'Rechts',
        'markTextInput': 'Label Texteingabe',
        'finishedInput': 'Eingabe abschließen',
        'deleteAll': 'Alles Löschen',
        'reallyDeleteAll': 'Möchten Sie das komplette Gemälde wirklich löschen',
        'chooseInput': 'Quelle wählen',
        'picToWhiteboard': 'Bild für das Whiteboard',
        'passwordPlease': 'Bitte das Passwort für diese Datei eingeben',
        'passwordIncorrect': 'Fehler! Bitte das korrekte Passwort eingeben',
        'HowTo': 'Anleitung',
        'HowToDetail': 'Um ein Lesezeichen zu speichern, erst die gewünschte Passage im Dokument markieren und anschliessend den Knopf zum anlegen drücken.',

        'newFromTemplate': 'Neu aus Vorlage',
        'openDots': 'Öffnen ...',
        'createTemplate': 'Vorlage erstellen',
        'editTemplates': 'Vorlagen bearbeiten',
        'editTemplateTitle': 'Vorlagentitel bearbeiten',
        'editEntry': 'Eintrag bearbeiten',
        'editDetail': 'Detail bearbeiten',
        'newChecklistTemplate': 'Checklisten Vorlage Name',
        'checklistTemplateName': 'Bitte einen Name für das neue Template eingeben',
        'newChecklistTemplateEntry': 'neuer Checklisten Vorlagen Eintrag',
        'newChecklistTemplateDetail': 'neues Checklisten Vorlagen Eintrag Detail',
        'newEntry': 'neuer Eintrag',
        'newDetail': 'neues Detail',
        'orderValue': 'Reihenfolge',
        'text': 'Text',
        'image': 'Bild',
        'deleteTemplate': 'Vorlage löschen',
        'reallyDeleteTemplate': 'Möchten Sie diese Vorlage wirklich löschen?',
        'deleteEntry': 'Eintrag löschen',
        'reallyDeleteEntry': 'Möchten Sie diesen Eintrag wirklich löschen?',
        'deleteDetail': 'Detail löschen',
        'reallyDeleteDetail': 'Möchten Sie dieses Detail wirklich löschen?',
        'noChecklistTemplate': 'keine Templates verfügbar',
        'enterDetailTextOrPicture': 'Bitte einen Detail Text eingeben oder Bild hochladen',
        'enterEntryTitle': 'Bitte einen Titel für diesen Eintrag eingeben',
        'enterEntryDescription': 'Bitte eine Beschreibung für diesen Eintrag eingeben',
        'enterTemplateTitle': 'Bitte einen Titel für diese Vorlage eingeben',
        'createTemplateFirst': 'Bitte erst eine Vorlage erzeugen',
        'selectEntry': 'Bitte erst einen Eintrag auswählen',
        'checklistFinished': 'Checkliste Erledigt',
        'checklistFinishedDetail': 'Sie haben die ausgewählte Checkliste erfolgreich abgearbeitet.',
        'finishedTime': 'Abgeschlossen um ',

        'generalInfoLabel': 'Allgemein:',
        'help.title.general.welcome': 'Willkommen',
        'help.body.general.welcome': 'Willkommen auf der Hilfeseite der weCoop Application.<br/>Dieser Bereich ist unterteilt nach den verschiedenen Menüpunkten und dient der ersten Erklärung, was wie wo passiert, ausgenommen der Bereich des Administrators. Bei Problemen oder hilfegesuchen, die hier nicht geklärt werden können, wenden Sie sich bitte an <br/>wecoop@webware-experts.de<br/>ihr Anliegen wird schnellst möglich bearbeitet.',
        'help.title.general.firstToKnow': 'Erste Hinweise',
        'help.body.general.firstToKnow': 'Oben links sehen Sie das Hauptmenü, durch das Sie durch die einzelnen Abteilungen innerhalb dieser Applikation navigieren können. Sollten nur die Hilfe (und als Administrator der Bereich "Admin") zu sehen sein, wählen Sie bitte erst einen Raum aus, da sich sämtliche Kommunikation hier immer innerhalb eines Raumes abspielt (ausgenommen sind private Nachrichten).',
        'help.title.general.navigation': 'Navigation',
        'help.body.general.navigation': 'Wenn ein Raum ausgewählt ist, haben Sie die Möglichkeit, zwischen den 4 (5 als Administrator) Menüpunkten zu wählen:<ul><li>Whiteboard: Bilder austauschen und bearbeiten</li><li>Video: Echtzeit Video Kommunikation</li><li>Dokumentation: Dokumente zu Maschine, Baugruppen, Beuteile etc austauschen bzw bestimmte Passagen hierzu</li><li>Checkliste: Benutzerdefinierte Checklisten anlegen und abarbeiten</li><li>Administration: Benutzer-, Kunden- und Raumverwaltung</li></ul>',

        'navbarPanelsLabel': 'Navbar Tasten:',
        'help.title.navbar.firstToKnow': 'Generell',
        'help.body.navbar.firstToKnow': 'Die Tasten auf der rechten Seite der Navigationsleiste steuern die folgenden Punkte unabhängig davon, in welchem Menüpunkt man sich gerade befindet.',
        'help.title.navbar.username': 'Nutzername',
        'help.body.navbar.username': 'Diese Taste zeigt an, unter welchem Namen man eingeloggt ist. Dieser Name erscheint bei den anderen unter dem Punkt Online, wenn sie Nachrichten verschicken als Absender, als Titel über ihrem Bild im Menüpunkt Video und beim Anlegen der Lesezeichen unter dem Menüpunkt "geteilte Dokumente".<br/> Das kleine Icon hinter dem Namen zeigt auch an, ob die eigene Stimme gerade übertragen wird: blaues, durchgestrichenes Mikrofon bedeutet das keine Sprachverbindung besteht, ein rotes Mikrofon bedeutet das die eigene Sprache über das angeschlossene Mikrofon übertragen wird. Mit einem Druck auf diesen Button schaltet man zwischen diese 2 Modis hin- und her.Dieses funktioniert aber nur, wenn man sich die Teilnehmer innerhalb eines Raumes befindet.',
        'help.title.navbar.room': 'Raum',
        'help.body.navbar.room': 'Mit dieser Taste kann der Nutzer zwischen die verschiedenen Räume innerhalb dieser Application hin-/ und herschalten. Es werden nur die Räume angezeigt, zu der dieser User auch eingeladen ist, mit der Ausnahme der Administratoren, diese können alle Räume sehen',
        'help.title.navbar.online': 'Online',
        'help.body.navbar.online': 'Das ist der Online-Anzeiger. In der kleinen Pille auf dem Button sieht man die Anzahl aktuell angemeldeter Nutzer. Beim Klick öffnet sich die gesamte Liste. Auf dieser Liste erscheinen alle gerade angemeldeten Nutzer und ihr momentaner Standort innerhalb dieser Applikation. Mit einem Klick auf die Namen können private Kurznachrichten verschickt werden.',
        'help.title.navbar.chat': 'Chat',
        'help.body.navbar.chat': 'Dies ist der Button zum Öffnen des Chatfensters. Die Zahl hinter dem Wort "Chat" bedeutet, wie viele ungelesene Nachrichten Sie bereits haben. Im eigentlichen Chatfenster, welches sich durch ein Klick auf den Knopf öffnet, sehen sie alle bisher in diesem Raum geschriebenen Nachrichten sortiert nach Absende Zeitpunkt. Man kann lesen, wann die Nachricht geschickt wurde und von wem. Private Nachrichten erscheinen ebenfalls in diesem Fenster, sind natürlich nur für Sie selbst und dem Absender sichtbar.',
        'help.title.navbar.logout': 'Logout',
        'help.body.navbar.logout': 'Bei dieser Taste wird der Nutzer ausgeloggt. Die geänderten Daten werden in der Regel bei Ausführung einer Aktion sofort gespeichert, sodas kein Datenverlust auftritt. Einen extra "Speichern" Knopf ist daher nicht nötig.',

        'whiteboardLabel': 'Whiteboard:',
        'help.title.whiteboard.firstToKnow': 'Allgemein',
        'help.body.whiteboard.firstToKnow': 'Auf dem Whiteboard haben Sie und ihre Teammitglieder die möglichkeit, Bilder zu teilen (lokal gesicherte oder aus der App fotografierte) und diese mit verschiedene, veränderbare Marker zu beschriften. Man kann vorher ausgewählte Bereiche vergrößern. Auch kann man freihandformen auf dem Whiteboard malen und all dies passiert in Echtzeit bei allen eingeloggten Nutzern, die sich im gleichen Raum befinden.',
        'help.title.whiteboard.fileimport': 'Foto-/ Dateiimport',
        'help.body.whiteboard.fileimport': 'Der erste Abschitt auf der linken Seite bezieht sich auf den Bild Import und Export. Der erste Button ist für den Import zuständig, dabei wird erst nachgesehen, ob eine lokale Kamera vorhanden ist und dann darf man auswählen, ob ein Bild aufgenommen werden soll oder ein lokal gespeichertes Bild hochgeladen werden soll. Der 2. Button speichert das aktuelle Whiteboard als *.png file in den regulären, lokalen Download Ordner.',
        'help.title.whiteboard.level': 'Ebenen & Löschen',
        'help.body.whiteboard.level': 'Mit den oberen 2 Knöpfen kann das ausgewählte Objekt um je eine Ebene nach oben und unten gestellt werde. Mit dem 3. Knopf löscht man die aktuell ausgewählte Ebene und mit dem 4. wird das komplette Whiteboard geleert. VORSICHT: Alle darauf gespeicherten Daten sind dann unwiederbringlich weg!',
        'help.title.whiteboard.enlarge': 'Vergrößern',
        'help.body.whiteboard.enlarge': 'Mit dem ersten Knopf wird ein rechteckiger Rahmen erzeugt. dieser ist dazu gedacht, Objektausschnitte zu vergrößern. Nach dem erzeugen des Rechtecks muß dieser per Drag&Drop über das gewünschte Ziel gezogen werden. Der 2. Button erzeugt schliesslich die Vergrößerung.',
        'help.title.whiteboard.freehand': 'Freihand zeichnen',
        'help.body.whiteboard.freehand': 'Über dieses Feld wird die Funktion zum Freihandzeichnen aktiviert. Nach einem Klick öffnet sich ein kleines Fenster, in dem man die gewünschte Farbe und die Strichstärke auswählt. Danach auf "Fertig" drücken. Nun werden bei gedrückter Maustaste die Freihandformen gezeichnet. Diese werden jetzt noch nicht an die anderen Nutzer übertragen. Wenn man fertig ist, wird der Button für die Freihandformen erneut geklickt, erst jetzt wird die Form gespeichert und an die anderen geteilt.',
        'help.title.whiteboard.marker': 'Marker',
        'help.body.whiteboard.marker': 'Das erste Feld öffnet ein Modal, in dem man eigenen Text eingeben kann, die Pfeilfarbe festlegt und auch die Pfeilrichtung. Das zweite Feld führt Sie durch 3 kleine Fenster, in der man nacheinander die Zahl, Farbe und Pfeilrichtung festlegt. Die restlichen Buttons sind Shortcuts für vordefinierte Pfeile.',

        'videoLabel': 'Video:',
        'help.title.video.firstToKnow': 'Durchgehend',
        'help.body.video.firstToKnow': 'Im Bereich "Video" kann man sich mit Hilfe der Kameras und Mikrofone per Videoübertragung unterhalten. Dadurch können auch einfacher detailierte Fehlerbeschreibungen generiert werden, womit sich der Vor-Ort Techniker hilfe vom Remote Techniker holen kann, der irgendwo auf dieser Welt sitzt.',
        'help.title.video.chooseInput': 'Wahl der Eingabe',
        'help.body.video.chooseInput': 'Nach betreten des Menüpunktes "Video" wird als erstes erwartet, das Sie ihre benötigten Kommunikationsgeräte auswählen. Der obere Abschnitt bezieht sich auf die Videoquelle, der untere auf die Audioquelle.',
        'help.title.video.partition': 'Aufteilung',
        'help.body.video.partition': 'Die Aufteilung unterscheidet sich hier, je nach gewähltem Modus oder Bildschirmgröße.<br/>Groß bzw Desktop:<br/>Hier findet sich das eigene Videobild auf der rechten Seite in der Größe 3/12 des Fensters. Im Panel Kopf hiervon sind auch die Buttons für die Optionen. Alle weiteren Nutzer befinden sich inder gleichen Größe direkt dadrunter. Ein ausgewähltes Video wird in 9/12 Größe auf der linken Bildschirmseite angezeigt.<br/>Klein bzw Mobile:<br/>Hier befindet sich das eigene Videobild in voller Fensterbreite ganz oben. Die Options-Knöpfe sind in diesem Panelheader aufgelistet. Die Videobilder der anderen Teilnehmer befinden sich ebenfalls in voller Fensterbreite direkt untereinander.',
        'help.title.video.options': 'Optionen',
        'help.body.video.options': 'Die Buttons bewirken von links nach rechts:<ul><li>Durch ein Mausklick auf das Videobild des anderen Teilnehmers (das große) wird bei Ihnen UND beim anderen Teilnehmer ein Marker angezeigt.</li><li>Dieser Button macht ein Screenshot vom aktuellem großen Videobild und speichert dieses im Download-Ordner</li><li>Mute-Button: das eigene Mikrofon wird stumm geschaltet</li><li>umschalten zwischen großer und kleiner Ansicht bzw zwischen Desktop und Mobile</li><li>neuauswahl der gewünschten Video- und Audiohardware</li></ul>',

        'documentationLabel': 'Dokumentation:',
        'help.title.documentation.firstToKnow': 'Allseitig',
        'help.body.documentation.firstToKnow': 'Die Dokumentation teilt sich in 2 Unterbereiche, die "geteilte Dokumente" und "Bibliothek".<br/>geteilte Dokumente:<br/>Hier werden pro Fall neue Dokumente auf Nachfrage oder spezifische Recherche hochgeladen.<br/>Bibliothek:<br/>Die Bibliothek wird für diesen Maschinentyp angelegt und enthält im besten Fall sämtliche Dokumente, die für diesen Maschinentype relevant sind.',
        'help.title.documentation.shared': 'Geteilte Dokumente',
        'help.body.documentation.shared': 'Dieser Bereich ist eher für die kurzweilige Kommunikation zwischen dem Remote- und Vor-Ort Techniker gedacht. Es werden bestimmte Dokumente hochgeladen und die benötigten Passagen markiert und gespeichert, für alle Raummitglieder gleichzeitig.',
        'help.title.documentation.shared.partition': 'Geteilte Dokumente - Aufteilung',
        'help.body.documentation.shared.partition': 'Auch hier befindet sich das bekannte 9/12 | 3/12 Raster. Im rechten Teil befindet sich oben eine Liste aller hochgeladener Dokumente. Im Panel da drunter ist eine Liste aller angelegten Lesezeichen dieses ausgewähltem Dokuments. Auf der linken Seite befindet sich in groß das ausgewählte PDF mit den gängigen Optionen.',
        'help.title.documentation.shared.files': 'Geteilte Dokumente - Dateien',
        'help.body.documentation.shared.files': 'In dieser Liste befinden sich alle in diesem Raum hochgeladenen Dokumente mitsamt Größenangabe. Durch einen Klick auf den entsprechendem Eintrag wird dieses zur Großansicht ausgewählt. Mit dem roten X am hinterem Ende kann diese Datei vom Server gelöscht werden. Eine neue Datei lädt man über das grüne + im Panelheader hoch; es öffnet sich ein neues Kontextmenü über das man einen Namen vergibt und die gewünschte Datei vom lokalen Dateisystem auswählt.',
        'help.title.documentation.shared.main': 'Geteilte Dokumente - Hauptfenster',
        'help.body.documentation.shared.main': 'Im großen Hauptfenster wird das aktuell ausgewählte Dokument dargestellt. Es enthält im Kopf auch die üblichen PDF-Funktionen. Ganz links kann man die Darstellungsgröße ändern, in der Mitte sieht man aktuelle/maximale Seitenzahl und "gehe zu Seite", am rechten Rand sind Buttons um zur ersten Seite zu springen, zur vorherigen Seite, zur nächsten Seite und zur letzten Seite.',
        'help.title.documentation.shared.bookmarks': 'Geteilte Dokumente - Lesezeichen',
        'help.body.documentation.shared.bookmarks': 'Diese Liste alle Lesezeichen, die in diesem Dokument angelegt wurden. Durch einen Klick auf ein Lesezeichen wird im PDF auf diese Seite gesprungen und die markierte Stelle grün dargestellt. Mit dem roten X am hinterem Ende kann dieses Lesezeichen vom Server gelöscht werden. Ein neues Lesezeichen wird über das grüne + im Panelheader angelegt; erst muß die gewünschte Stelle im Dokument mit der Maus markiert werden, danach ein Klick auf den Erstellen Button, es öffnet sich ein neues Kontextmenü über das man einen Titel vergibt.',
        'help.title.documentation.library': 'Bibliothek',
        'help.body.documentation.library': 'Hier befinden sich alle Dokumente, die zu diesem Maschinentyp zusammengetragen wurde. Die Dokumente sind unterteilt nach Tags und Kategorien, damit die suche nach dem passenden Dokument sich deutlich verkürzt.',
        'help.title.documentation.library.partition': 'Bibliothek - Aufteilung & Funktion',
        'help.body.documentation.library.partition': 'In der Maschinentyp Bibliothek sieht man auf der linken Seite eine Spalte mit sämtlichen, vergebenen Tags. Ein Klick auf einen Tag lässt sofort alle Ergebnisse nach dem gewünschten Tag filtern. Daneben kommt eine Auflistung aller Dokumente, die den gewählten Filtern entsprechen. Oben drüber ist noch eine kategorische Aufteilung der Dokumente nach Themengebieten, die ebenfalls auf die Filterung Einfluss hat. Wählt man nun ein Dokument aus, öffnet sich unter der Auflistung noch einmal eine Ansicht mit Detailierteren Angaben zum gewähltem Dokument. Dieses ist dann auch der Link, um sich das Dokument vom Server zu laden und auf der rechten Seite anzeigen zu lassen.',

        'checklistLabel': 'Checkliste:',
        'help.title.checklist.firstToKnow': 'Grundsätzlich',
        'help.body.checklist.firstToKnow': 'Checklisten sind Sammlungen von Text und Bild, die unterstützend zur Durchführung regelmässiger Arbeiten zur Verfügung stehen. Sie werden einmalig als Vorlagen angelegt und gespeichert. Soll ein Mitarbeiter eine Checkliste abarbeiten, wählt er aus den Vorlagen die richtige aus und geht diese dann Punkt für Punkt durch.',
        'help.title.checklist.newFromTemplate': 'Neu aus Vorlage',
        'help.body.checklist.newFromTemplate': 'Hier wird eine neue Checkliste aus allen Vorlagen ausgewählt, die danach abgearbeitet werden kann.',
        'help.title.checklist.open': 'Öffnen',
        'help.body.checklist.open': 'In diesem Bereich werden die ausgewählten Checklisten abgearbeitet. Das Handling ist denkbar einfach:<br/>Aus dem Modal wird eine Checkliste ausgewählt, anschliessend klickt man sich durch die einzelnen Einträge und sieht sich die jeweiligen Details zu jeden Eintrag an. Ist dieser Eintrag abgearbeitet, klickt man auf das gelbe X am Anfang, damit wird dieser Punkt abgehakt. Sind alle Punkte erledigt, wird diese Checkliste als ganzes abgeschlossen.',
        'help.title.checklist.editTemplate': 'Vorlagen bearbeiten',
        'help.body.checklist.editTemplate': 'Hier werden neue Vorlagen erstellt, genauso wie bestehende Vorlagen bearbeitet werden können. Die Ansicht hier ist 3 Spaltig:<ul><li>Links sind die Vorlagen</li><li>Mittig sind die Einträge zur gewählten Vorlage</li><li>Rechts sind die Details zum gewählten Eintrag</li></ul>Mit dem grünen Button im Panelheader kann man ein neues Objekt erzeugen, im anschliessend sich öffnenden Modal wird man nach den nötigen Informationen gefragt.<br/>Jeder Eintrag kann mit dem Stift Symbol am rechten Rand editiert werden, über dem Mülleimer wir dieser Eintrag gelöscht. Am linkem Rand befindet sich eine Zahl mit kleinen Pfeilen:Die Zahl sagt aus, an welcher Stelle der Reihenfolge dieses Objekt erscheint, mit den kleinen Pfeilen verschiebt man dieses nach oben oder unten.'
    };
});