define('whiteboard2-frontend/components/template-details/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['panel-body'],
        sortedDetails: _ember['default'].computed.sort('details', 'sortDefinition'),
        sortDefinition: ['orderValue'],

        actions: {
            deleteThisDetail: function deleteThisDetail(detail) {
                this.sendAction('deleteThisDetail', detail);
            },
            editThisDetail: function editThisDetail(detail) {
                this.sendAction('editThisDetail', detail);
            },
            pullUpThisDetail: function pullUpThisDetail(detail) {
                var _this = this;

                var oldDetailOrder = detail.get('orderValue');
                if (oldDetailOrder > 0) {
                    (function () {
                        var newDetailOrder = oldDetailOrder - 1;
                        var details = _this.get('details');
                        details.forEach(function (thatDetail) {
                            var thatDetailOrder = thatDetail.get('orderValue');
                            if (newDetailOrder === thatDetailOrder) {
                                thatDetail.set('orderValue', thatDetailOrder + 1);
                                thatDetail.save();
                            }
                        });
                        detail.set('orderValue', newDetailOrder);
                        detail.save();
                    })();
                }
            },
            pullDownThisDetail: function pullDownThisDetail(detail) {
                var oldDetailOrder = detail.get('orderValue');
                var newDetailOrder = oldDetailOrder + 1;
                var details = this.get('details');
                details.forEach(function (thatDetail) {
                    var thatDetailOrder = thatDetail.get('orderValue');
                    if (newDetailOrder === thatDetailOrder) {
                        thatDetail.set('orderValue', thatDetailOrder - 1);
                        thatDetail.save();
                    }
                });
                detail.set('orderValue', newDetailOrder);
                detail.save();
            }
        }
    });
});